import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import arrow from "../images/icones/arrow.png";

const BackArrow = ({ onClick }) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    const currentLang = i18n.language;
    navigate(`/${currentLang}/Homepage`);
  };

  return (
    <img
      src={arrow}
      alt="retour"
      className="w-12 h-12 extraLarge:w-16 extraLarge:h-16 cursor-pointer"
      onClick={handleClick}
    />
  );
};

export default BackArrow; 