import React, { useState , useEffect, useContext} from 'react';
import Header from '../HomePage/Components/Header';
import RegistrationView from './Components/RegistrationView';
import WelcomeCoopt from '../Coopt-Sponsorize-CreateDO/Welcome';
import useStore  from '../../API/store';
import { TasksContext } from "../../API/Executions.js";
import { Link } from "react-router-dom";


function Welcome() {

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const Name = localStorage.getItem("userName");
  const firstName =localStorage.getItem("firstName");
  const email =localStorage.getItem("email");
  const userId = localStorage.getItem("userId")
  const do_id = localStorage.getItem("do_ids")

  const [Email, setEmail] = useState(`${email}`);
  const [FirstName, setFirstName] = useState(`${firstName}`);
  const [LastName, setLastName] = useState(`${Name}`);
  const [Password, setPassword] = useState("");
  const [newPassword, setnewPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [date, setdate] = useState("");
  const [country, setcountry] = useState("");
  const [idDO, setIdDO] = useState("");
  const [Docreator, setDocreator] = useState("");
  const [sponsored, setSponsored] = useState("");


const UserName = localStorage.getItem("userName");
const CeoDoId = localStorage.getItem("userId")


console.log(FirstName, "<=========== FirstName")
console.log(LastName, "<=========== LastName")
console.log(UserName, "<=========== UserName")

const [descriptionDO, setDescriptionDO] = useState("");
const [nameDO, setNameDO] = useState("");
const [typeActivityDO, setTypeActivityDO] = useState("");
const [linkDO,setLinkDO] = useState("");
const [keywordsDO,setKeywordsDO] = useState("");
const [makeitbetterDO,setMakeitbetterDO] = useState("");

const [idDOcreated, setIdDOcreated] = useState("");

  const { 
    isSplitWelcomeView,
    error
  } = useStore();
  const { selectDioId } = useContext(TasksContext);
  const handleDIOClick = (dioId) => {
    selectDioId(dioId);
  };
  const handleconfirmeCoopt = async () => {
    const first_connection = '0';
    const userData = {
      userId: localStorage.getItem("userId"),
      FirstName: firstName,
      LastName: Name,
      Email: email,
      newPassword: newPassword,
      first_connection: first_connection,
      date: date || null,
      country: country || null
    };

    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/cooptTalent/api/updatecoopt", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        const errorData = await response.text();
        console.error("Server error:", errorData);
        throw new Error("Failed to update user data");
      }

      const data = await response.json();
      console.log("Update successful:", data);

      // Mettre à jour le localStorage et rediriger
      localStorage.setItem("DOId", idDO);
      handleDIOClick(do_id);
      window.location.href = '/homepage';

    } catch (error) {
      console.error("Error during update:", error);
      alert("Une erreur est survenue lors de la mise à jour");
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="flex flex-col medium:flex-row min-h-screen">
        {/* Left Section */}
        <div className="w-full medium:w-1/2 bg-aqua3 p-8 medium:p-12">
          <Header />
          {error && <p className="text-center text-red-500">{error}</p>}
          
          <section className="mt-8 medium:mt-20">
            <div className="max-w-xl mx-auto">
              <div className="mb-8">
                <span className="text-2xl">👋👋👋</span>
                <h1 className="text-3xl medium:text-5xl font-bold mt-4 font-silka-mono">
                  HELLO<br />
                  {FirstName} {LastName},<br />
                  WELCOME TO<br />
                  THANKWARD
                </h1>
              </div>

              <div className="mt-8 medium:mt-12">
                <p className="text-base medium:text-lg font-silka-mono">
                  Get your team on the same boat,
                  and they'll be as motivated and
                  engaged as you are
                </p>
              </div>
            </div>
          </section>
        </div>

        {/* Right Section - Registration */}
        <div className="w-full medium:w-1/2 bg-white">
          <RegistrationView 
            handleconfirmeCoopt={handleconfirmeCoopt} 
            confirmPassword={confirmPassword} 
            newPassword={newPassword} 
            setnewPassword={setnewPassword} 
            setconfirmPassword={setconfirmPassword} 
          />
        </div>
      </div>
    </div>
  );
}

export default Welcome;