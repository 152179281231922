import React from "react";
import { useTranslation } from 'react-i18next';

const OrganizationType = ({ selected, onSelect }) => {
  const { t } = useTranslation();

  const types = [
    { id: "FOR PROFIT", translation: t('newTalentHub.organizationType.types.forProfit') },
    { id: "NON PROFIT", translation: t('newTalentHub.organizationType.types.nonProfit') },
    { id: "ACADEMIC", translation: t('newTalentHub.organizationType.types.academic') },
    { id: "LIFESTYLE", translation: t('newTalentHub.organizationType.types.lifestyle') }
  ];

  return (
    <div>
      <p className="mb-4 font-silka-mono">
        {t('newTalentHub.organizationType.title')}
      </p>
      <div className="flex flex-wrap gap-4 font-silka-mono">
        {types.map((type) => (
          <button
            key={type.id}
            type="button"
            className={`px-6 py-3 rounded-lg border-2 border-black ${
              selected === type.id ? "bg-black text-white" : "bg-transparent"
            }`}
            onClick={() => onSelect(type.id)}
          >
            {type.translation}
          </button>
        ))}
      </div>
    </div>
  );
};

export default OrganizationType;
