import React, { useState, useEffect } from 'react';
import ProjectCard from './ProjectCard';
import FilterButton from './FilterButton';
import BackArrow from "../../../../Components/BackArrow";
import useStore from "../../../../API/store";
import { useTranslation } from 'react-i18next';

function FilterView({handleGoBack, handleCardClick, selectedStatus, dioData = []}) {
  const { t } = useTranslation();
  const {
    onGoingCount,
    openCount,
    inReviewCount,
    achievedCount,
    selectedCollabSpace,
    setSelectedCollabSpace,
    combinedTasksAndIdeas
  } = useStore();

  const [filteredDioData, setFilteredDioData] = useState([]);

  // Calculer le nombre de cartes par collab space
  const calculateCollabSpaceCount = (collabSpaceId) => {
    return combinedTasksAndIdeas.filter(card => card.id_do === collabSpaceId).length;
  };

  useEffect(() => {
    setFilteredDioData(dioData);
  }, [dioData]);

  const handleCollabSpaceSelect = (collabSpaceId) => {
    setSelectedCollabSpace(collabSpaceId === selectedCollabSpace ? null : collabSpaceId);
  };

  return (
    <div className="w-full bg-rightGreyBg p-8 medium:p-32 pb-32 overflow-y-auto min-h-screen">
      <div className="max-w-6xl twoXl:max-w-4xl mx-auto space-y-12 mb-20">
        <BackArrow onClick={handleGoBack} />
        <section>
          <h2 className="font-mono text-2xl mb-10 mt-10">{t('filterView.byCollabSpace')}</h2>
          <div className="grid grid-cols-1 medium:grid-cols-2 extraLarge:grid-cols-3 twoXl:grid-cols-4 gap-4 mt-4">
            {filteredDioData.map((collabSpace, index) => (
              <ProjectCard 
                key={index}
                collabSpace={collabSpace}
                isSelected={selectedCollabSpace === collabSpace.id}
                onSelect={() => handleCollabSpaceSelect(collabSpace.id)}
                cardCount={calculateCollabSpaceCount(collabSpace.id)}
              />
            ))}
          </div>
        </section>

        <section>
          <h2 className="font-mono text-2xl mb-4 mt-10">{t('filterView.byStatus')}</h2>
          <div className="flex flex-wrap gap-3">
            <FilterButton 
              active={selectedStatus === 'On going'} 
              onClick={() => handleCardClick('On going')} 
              count={onGoingCount}
            >
              {t('filterView.onGoing')}
            </FilterButton>
            <FilterButton 
              active={selectedStatus === 'Open'} 
              onClick={() => handleCardClick('Open')} 
              count={openCount}
            >
              {t('filterView.open')}
            </FilterButton>
            <FilterButton 
              active={selectedStatus === 'In review'} 
              onClick={() => handleCardClick('In review')} 
              count={inReviewCount}
            >
              {t('filterView.feedback')}
            </FilterButton>
            <FilterButton 
              active={selectedStatus === 'Achieved'} 
              onClick={() => handleCardClick('Achieved')} 
              count={achievedCount}
            >
              {t('filterView.achieved')}
            </FilterButton>
          </div>
        </section>

        <button
          onClick={handleGoBack}
          className="w-[14em] px-8 py-4 bg-black text-white rounded-[50px] font-silka-mono uppercase large:hidden"
        >
          Filter
        </button>
      </div>
    </div>
  );
}

export default FilterView;