import React, { useEffect, useState } from "react";
import Card from "./Card";
import personna from "../../../images/icones/personna.png";
import useCountdown from "../../../pages/ExecutionBoard/useCountdown";
import useStore from "../../../API/store";
import { useTranslation } from "react-i18next";

const CardsList = React.memo(({
  dioData,
  isSplitView,
  selectedCardIndex,
  onCardClick,
  selectedStatus,
}) => {

  const { dioTasks,loading, combinedTasksAndIdeas, selectedCollabSpace } = useStore();
  const { t } = useTranslation();

  const { hours, minutes, seconds } = useCountdown(dioTasks.remainingTime);

  const translationClasses = [
    "-translate-y-16",
    "translate-y-32",
    "translate-y-16",


  ];

  const mdTranslationClasses = ["translate-y-12", "translate-y-24"];

  const getTransformClass = (index, cols) => {
    if (cols === 1) {
      return "";
    } else if (cols === 2) {
      return mdTranslationClasses[index % mdTranslationClasses.length];
    } else {
      return translationClasses[index % translationClasses.length];
    }
  };

  const [columns, setColumns] = useState(1);

  useEffect(() => {
    const updateColumns = () => {
      const width = window.innerWidth;
      if (width >= 1305) {
        setColumns(3);
      } else if (width >= 820) {
        setColumns(2);
      } else {
        setColumns(1);
      }
    };

    updateColumns();

    window.addEventListener("resize", updateColumns);
    return () => window.removeEventListener("resize", updateColumns);
  }, []);

  const filteredByDraft = combinedTasksAndIdeas.filter((execution) => {
    const userId = parseInt(localStorage.getItem("userId"));

    if (execution.status_ === "Draft idea") {
      if (execution.id_talent_creator === userId) {
        execution.actionText = "CONTINUE";
        return true;
      }
      return false;
    }

    if (execution.status_ === "On going idea") {
      if (execution.id_talent_creator && userId && execution.id_talent_creator === userId) {
        execution.displayStatus = "Achieved idea";
        return true;
      } else {
        const now = new Date();
        const creation = new Date(execution.creation_date);
        const deadline = new Date(creation.getTime() + (3 * 24 * 60 * 60 * 1000));
        
        if (now > deadline) {
          execution.displayStatus = "Achieved idea";
        } else {
          execution.displayStatus = "On going idea";
        }
        
        const hasUserFeedback = execution.feedbacks?.some(
          feedback => feedback.contributor?.id === userId
        );

        return !hasUserFeedback;
      }
    }

    return true;
  });

  const filterByStatus = (card, status) => {
    const statusToCheck = card.displayStatus || card.status_;
    
    switch (status) {
      case "On going":
        return statusToCheck === "On going" || statusToCheck === "Draft idea";
      
      case "Open":
        return statusToCheck === status;
      
      case "In review":
        return statusToCheck === "In review" || statusToCheck === "On going idea";
      
      case "Achieved":
        return statusToCheck === "Achieved" || statusToCheck === "Achieved idea";
      
      default:
        return true;
    }
  };
  
  const filterByCollabSpace = (card) => {
    if (!selectedCollabSpace) return true;
    return card.id_do === selectedCollabSpace;
  };

  const filteredCardsData = filteredByDraft
    .filter(card => {
      const matchesStatus = !selectedStatus || selectedStatus === 'all' || filterByStatus(card, selectedStatus);
      const matchesCollabSpace = filterByCollabSpace(card);
      return matchesStatus && matchesCollabSpace;
    });

  const sortedCardsData = filteredCardsData.sort((a, b) => {
    const dateA = new Date(a.creation_date);
    const dateB = new Date(b.creation_date);
    return dateB - dateA;
  });

  

  const getBgColor = (selectedStatus) => {
    switch (selectedStatus) {
      case "On going":
        return "bg-orange3";
      case "In review":
      case "On going idea":
        return "bg-aqua3";
      case "Open":
        return "bg-gray-100";
      case "Achieved":
        return "bg-gains3";
      case "In review idea":
        return "bg-aqua3";
      case "Open idea":
        return "bg-gray-100";
      case "Achieved idea":
        return "bg-gains3";
      case "Draft idea":
        return "bg-orange3";
      default:
        return "bg-black";
    }
  };
  if (loading) {
    return           <div className="flex items-center justify-center h-full">
    <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-black-500 border-opacity-75"/>
  </div>
  }
  return (
    <div
      className={`
        grid grid-cols-1 
        medium:grid-cols-2 
        large:grid-cols-3 
        max-w-[1920px] 
        mx-auto
        ${isSplitView 
          ? "gap-[1.5rem]" 
          : "gap-[2rem] extraLarge:gap-[3.5rem]"
        }
      `}
    >
      {sortedCardsData.map((execution, index) => {
        const uniqueKey = `${execution.id}-${execution.creation_date}-${index}`;
        const getDisplayThanks = () => {
          const userId = parseInt(localStorage.getItem("userId"));
          
          if (execution?.feedbacks) {
            if (execution?.id_talent_creator === userId) {
              return execution.thanks || t('card.soon');
            }
            const userFeedback = execution.feedbacks.find(
              feedback => feedback.id_contributor === userId
            );
            if (userFeedback) {
              return userFeedback.thanks_voter || t('card.soon');
            }
          }
          return execution?.score_thanks || t('card.soon');
        };
        
        return (
          <div
            key={uniqueKey}
            className={`transform ${getTransformClass(index, columns)} 
              ${isSplitView 
                ? "w-[200px] extraLarge:w-[240px]" 
                : "w-[300px] extraLarge:w-[340px]"
              }
              mx-auto relative
            `}
            onClick={() => onCardClick(execution.id, execution.actionText)}
          >
            <div
              className={`
                ${selectedCardIndex === execution.id 
                  ? `absolute inset-0 border-4 border-black ${
                      isSplitView
                        ? "rounded-[2.5rem] extraLarge:rounded-[3rem]"
                        : "rounded-[3rem] extraLarge:rounded-[3.5rem]"
                    } z-10`
                  : ""
                }
              `}
            />
            <Card
              date={execution?.Expected_date
                ? `${execution?.Expected_date.slice(8, 10)}\n${execution?.Expected_date.slice(5, 7)}`
                : ""
              }
              title={execution.exec_description || ""}
              users={execution.membre_first_name 
                ? `${execution.membre_first_name} ${execution.performor_name}` 
                : execution.performor_name}
              thanks={getDisplayThanks()}
              actionText={execution.actionText}
              organisation={
                dioData.find(dio => dio.id === execution.id_do)?.name || 
                execution.do_name || 
                ""
              }
              bgColor={getBgColor(execution.displayStatus || execution.status_)}
              isSplitView={isSplitView}
              profilePics={execution.profilePics ? execution.profilePics : [personna]}
              id={execution.id}
              status={execution.displayStatus || execution.status_}
              creation_date={execution.creation_date}
            />
          </div>
        );
      })}
    </div>
  );
});

export default CardsList;
