import { Navigate, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import useStore from '../API/store';

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');
  const navigate = useNavigate();
  const { isSplitView, setIsSplitView } = useStore();

  useEffect(() => {
    // Vérifier si le token est valide
    if (token) {
      try {
        const tokenData = JSON.parse(atob(token.split('.')[1]));
        const expirationTime = tokenData.exp * 1000; // Convertir en millisecondes
        
        if (Date.now() >= expirationTime) {
          // Token expiré
          console.log("Token expiré, redirection vers login");
          localStorage.clear();
          navigate('/');
        }
      } catch (error) {
        console.error("Erreur de vérification du token:", error);
        localStorage.clear();
        navigate('/');
      }
    }
  }, [token, navigate]);

  useEffect(() => {
    // Remplacer l'entrée actuelle dans l'historique par Homepage
    if (token && userId) {
      window.history.replaceState(null, '', '/Homepage');
    }

    // Empêcher la navigation arrière si authentifié
    const handlePopState = (event) => {
      if (token && userId) {
        if (isSplitView) {
          // Si on est en vue splittée, on ferme la vue
          event.preventDefault();
          setIsSplitView(false);
        } else {
          // Sinon comportement normal : rester sur Homepage
          window.history.pushState(null, '', '/Homepage');
          event.preventDefault();
        }
      }
    };

    window.addEventListener('popstate', handlePopState);
    // Ajouter une entrée dans l'historique pour Homepage
    window.history.pushState(null, '', '/Homepage');

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [token, userId, isSplitView, setIsSplitView]);

  if (!token || !userId) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default PrivateRoute; 