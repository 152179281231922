import React, { useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { ViewContainer, CommonStyles } from './shared/ViewContainer';

import TextAreaWithLabel from "../../../Components/TextAreaWithLabel";

import useRadioQuestion from "../../../Components/Hooks/useRadioQuestion";
import RadioQuestion from "../../../Components/RadioQuestion";

import BackArrow from "../../../Components/BackArrow";
import folder from "../../../images/icones/Group 8795.png";
import useStore from "../../../API/store";

import profil from "../../../images/icones/personna.png";

const FeedbackView = ({ handleGoBack, selectedCardDetails, userName, setSelectedStatus }) => {
  const { t } = useTranslation();
  const userId = localStorage.getItem("userId");
  
  const hasUserFeedback = selectedCardDetails.peer_review_ids?.split(',').some(
    id => Number(id) === Number(userId)
  );

  const teamValueOptions = [
    { value: t('feedback.ratingTeamValue.low'), description: t('feedback.teamValue.descriptions.low'), score: 1 },
    { value: t('feedback.ratingTeamValue.medium'), description: t('feedback.teamValue.descriptions.medium'), score: 2 },
    { value: t('feedback.ratingTeamValue.high'), description: t('feedback.teamValue.descriptions.high'), score: 3 },
    { value: t('feedback.ratingTeamValue.exceptional'), description: t('feedback.teamValue.descriptions.exceptional'), score: 4 },
  ];

  const resultQualityOptions = [
    { value: t('feedback.ratingQuality.low'), description: t('feedback.quality.descriptions.low'), score: 1 },
    { value: t('feedback.ratingQuality.medium'), description: t('feedback.quality.descriptions.medium'), score: 2 },
    { value: t('feedback.ratingQuality.high'), description: t('feedback.quality.descriptions.high'), score: 3 },
    { value: t('feedback.ratingQuality.veryhigh'), description: t('feedback.quality.descriptions.veryhigh'), score: 4 },
    { value: t('feedback.ratingQuality.exceptional'), description: t('feedback.quality.descriptions.exceptional'), score: 5 }
  ];

  const reactivityOptions = [
    { value: t('feedback.ratingReactivity.low'), description: t('feedback.reactivity.descriptions.low'), score: 1 },
    { value: t('feedback.ratingReactivity.medium'), description: t('feedback.reactivity.descriptions.medium'), score: 2 },
    { value: t('feedback.ratingReactivity.high'), description: t('feedback.reactivity.descriptions.high'), score: 3 },
    { value: t('feedback.ratingReactivity.veryhigh'), description: t('feedback.reactivity.descriptions.veryhigh'), score: 4 },
    { value: t('feedback.ratingReactivity.exceptional'), description: t('feedback.reactivity.descriptions.exceptional'), score: 5 }
  ];

  const validateForm = () => {
    return (
      positiveAspect.trim() !== "" && // Vérifie que le champ positiveAspect n'est pas vide
      improvementSuggestion.trim() !== "" && // Vérifie que le champ improvementSuggestion n'est pas vide
      teamValue.selectedScore !== null && // Vérifie qu'une option a été sélectionnée pour teamValue
      resultQuality.selectedScore !== null && // Vérifie qu'une option a été sélectionnée pour resultQuality
      reactivity.selectedScore !== null // Vérifie qu'une option a été sélectionnée pour reactivity
    );
  };
  
  const {
    setPositiveAspect,
    positiveAspect,
    improvementSuggestion,
    setImprovementSuggestion,
    submitFeedback,
    setLoading,
    fetchExecutions,
    setError,
    dioData
  } = useStore();
  
  const usersList = [selectedCardDetails.talent_name, selectedCardDetails.performor_name];
  const profilePics = [profil, profil];
  const executionId = selectedCardDetails.id

  const onSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      alert("Please fill all required fields.");
      return;
    }
    const feedback = {
      positiveAspect,
      improvementSuggestion,
      teamValueScore: teamValue.selectedScore,
      resultQualityScore: resultQuality.selectedScore,
      reactivityScore: reactivity.selectedScore,
      executionId, 
    }; 
    setLoading(true)
    submitFeedback(feedback)
    .then(() => {
      console.log("Achievement successfully submitted!");
      fetchExecutions(dioData).then(() => {
        console.log("Achievement successfully submitted!");
        setLoading(false);
      });
      setPositiveAspect("");
      setImprovementSuggestion("");
      handleGoBack();
      setSelectedStatus("all");
    })
    .catch((error) => {
      setError(error, "<======Error adding achievement.");
      setLoading(false); // Stop loading in case of error
    });
};

  const teamValue = useRadioQuestion(teamValueOptions)
  const resultQuality = useRadioQuestion(resultQualityOptions)
  const reactivity = useRadioQuestion(reactivityOptions)


  const day = selectedCardDetails.creation_date.slice(8, 10);
  const month = selectedCardDetails.creation_date.slice(5, 7);

  const feddbackViewRef = useRef();

  useEffect(() => {
    if (feddbackViewRef.current) {
      feddbackViewRef.current.scrollIntoView({
        block: "start",
        behavior: "instant",
      });
    }
  }, []);


  return (
    <ViewContainer bgColor="bg-aqua3">
      <div className="flex justify-between mb-20">
        <BackArrow onClick={handleGoBack} />
        {selectedCardDetails.Expected_date && (
          <div className=" text-xs w-[32px] h-[50px] p-1 pl-3 pr-3 mb-6 flex items-center justify-center text-white bg-stone-950 rounded-lg font-silka-mono">
            {day} <br />
            {month}
          </div>
        )}
      </div>
      <p className="font-silka-mono mt-10">Thankward by MKIF</p>
      <p className="font-silka-mono font-semibold text-3xl mt-6">
        {selectedCardDetails?.exec_description}
      </p>

      <div className="flex items-center space-x-2 mt-10">
        {usersList.map((user, index) => (
          <React.Fragment key={index}>
            {profilePics[index] && (
              <img
                src={profilePics[index]}
                alt={user}
                className="w-8 h-8 rounded-full"
              />
            )}
            <p className="text-sm font-silka-mono">{user}</p>
            {index < usersList.length - 1 && <span> &gt; </span>}
          </React.Fragment>
        ))}
      </div>

      {selectedCardDetails.Instructions && (
        <div className="flex justify-center items-center w-[100px] h-12 border-2 border-black mt-20 rounded-2xl transform -translate-x-4">
          <p className="font-silka-mono font-bold uppercase text-center p-2">
            brief
          </p>
        </div>
      )}
      <p className="font-silka-mono mt-10">{selectedCardDetails.Instructions}</p>
      {selectedCardDetails.input_link && (
        <a
          href={selectedCardDetails.input_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="cursor-pointer mt-10 bg-aqua3 -translate-x-8"
            src={folder}
            alt="folder"
          />
        </a>
      )}

      {selectedCardDetails.review_comments && (
        <div className="flex justify-center items-center w-[170px] h-12 border-2 border-black mt-20 rounded-2xl transform -translate-x-4">
          <p className="font-silka-mono font-bold uppercase text-center p-2">
            Liverables
          </p>
        </div>
      )}
      <p className="font-silka-mono mt-10">{selectedCardDetails.review_comments}</p>

      {selectedCardDetails.documentation_link && (
        <a
          href={selectedCardDetails.documentation_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="cursor-pointer mt-10 bg-aqua3 -translate-x-8"
            src={folder}
            alt="folder"
          />
        </a>
      )}

      {selectedCardDetails?.status_ === "In review" &&
        selectedCardDetails?.talent_name !== userName &&
      <>
      <p className="font-silka-mono font-semibold text-4xl mt-14 whitespace-pre-line">
        {t('feedback.form.brief')}
      </p>

      {!hasUserFeedback && Number(selectedCardDetails.id_talent_creator) !== Number(userId) ? (
        <form onSubmit={onSubmit} className="mt-10">
          <TextAreaWithLabel
            label={t('feedback.form.highlight.label')}
            value={positiveAspect}
            placeholder={t('feedback.form.highlight.placeholder')}
            onChange={(e) => setPositiveAspect(e.target.value)}
            bg="bg-aqua3"
          />

          <TextAreaWithLabel
            label={t('feedback.form.improvement.label')}
            value={improvementSuggestion}
            placeholder={t('feedback.form.improvement.placeholder')}
            onChange={(e) => setImprovementSuggestion(e.target.value)}
            bg="bg-aqua3"
          />

          <RadioQuestion
            label={t('feedback.form.teamValue.label')}
            options={teamValueOptions}
            selectedIndex={teamValue.selectedIndex}
            selectedValue={teamValue.selectedValue}
            selectedScore={teamValue.selectedScore}
            onChange={teamValue.handleChange}
          />

          <RadioQuestion
            label={t('feedback.form.quality.label')}
            options={resultQualityOptions}
            selectedIndex={resultQuality.selectedIndex}
            selectedValue={resultQuality.selectedValue}
            selectedScore={resultQuality.selectedScore}
            onChange={resultQuality.handleChange}
          />

          <RadioQuestion
            label={t('feedback.form.reactivity.label')}
            options={reactivityOptions}
            selectedIndex={reactivity.selectedIndex}
            selectedValue={reactivity.selectedValue}
            selectedScore={reactivity.selectedScore}
            onChange={reactivity.handleChange}
          />

          <div className="flex flex-col space-y-2">
            <div className="flex flex-row items-center space-x-4 mt-10 mb-20">
              <button
                type="submit"
                className="w-[14em] px-8 py-4 bg-black text-white rounded-[50px] font-silka-mono uppercase"
              >
                {t('feedback.form.submit')}
              </button>
            </div>
          </div>
        </form>
      ) : (
        <div className="mt-10 bg-orange3 opacity-50 h-15 w-auto mb-20">
          <p className="font-silka-mono text-lg">
            {t('feedback.labels.alreadyFeedback')}
          </p>
        </div>
      )}
      </>}
    </ViewContainer>
  );
};

export default FeedbackView;
