import { useState, useEffect } from 'react';
import arrow from '../../../images/icones/arrow.png';

function RegistrationView({confirmPassword, newPassword, setnewPassword, setconfirmPassword, handleconfirmeCoopt}) {
  const [formData, setFormData] = useState({
    firstName: localStorage.getItem("firstName") || '',
    lastName: localStorage.getItem("userName") || '',
    email: localStorage.getItem("email") || '',    
    password: '',
    confirmPassword: '',    
    acceptTerms: false
  });

  // Vérifier si tous les champs sont remplis
  const isFormValid = () => {
    return (
      newPassword.trim() !== '' &&
      confirmPassword.trim() !== '' &&
      formData.acceptTerms === true
    );
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmitWithValidation = (e) => {
    e.preventDefault();
    
    if (!isFormValid()) {
      return; // Ne rien faire si le formulaire n'est pas valide
    }

    if (newPassword !== confirmPassword) {
      alert('Passwords do not match');
      return;
    }

    handleconfirmeCoopt();
  };

  const handleDeleteData = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  return (
    <div className="w-full bg-white p-8 medium:p-24 medium:min-h-screen flex flex-col justify-center items-center">
      <div className="max-w-md mx-auto pb-20">
        <div className="mb-8 medium:mb-12">
          <h2 className="text-sm uppercase tracking-wide mb-2 font-silka-mono">Registration</h2>
          <h1 className="text-2xl medium:text-4xl font-bold leading-tight font-silka-mono">
            Confirm your identity<br />
            and chose a password<br />
            to create your profile
          </h1>
        </div>

        <form onSubmit={handleSubmitWithValidation} className="space-y-6 medium:space-y-8 pb-20">
          {/* User Info */}
          <div className="space-y-2">
            <p className="text-sm medium:text-base">First Name: {formData.firstName}</p>
            <p className="text-sm medium:text-base">Last Name: {formData.lastName}</p>
            <p className="text-sm medium:text-base">Email: {formData.email}</p>
          </div>

          {/* Password Fields */}
          <div className="space-y-4">
            <input
              type="password"
              name="password"
              onChange={(e) => setnewPassword(e.target.value)}
              value={newPassword}
              placeholder="CHOOSE YOUR NEW PASSWORD"
              className="w-full px-4 py-3 border border-black text-sm uppercase rounded-lg font-silka-mono"
            />
            <input
              type="password"
              name="confirmPassword"
              onChange={(e) => setconfirmPassword(e.target.value)}
              value={confirmPassword}
              placeholder="CONFIRM YOUR NEW PASSWORD"
              className="w-full px-4 py-3 border border-black text-sm uppercase rounded-lg font-silka-mono"
            />
          </div>

          {/* Terms Checkbox */}
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              name="acceptTerms"
              checked={formData.acceptTerms}
              onChange={handleChange}
              className="h-5 w-5 border-black rounded-none"
            />
              <span className="text-sm underline font-silka-mono">
              I accept the Terms and Conditions of Use
            </span>
          </label>

          {/* Action Button */}
          <button
            type="submit"
            disabled={!isFormValid()}
            className={`w-full medium:w-auto px-8 py-3 text-sm uppercase rounded-full transition-colors ${
              isFormValid()
                ? 'bg-black text-white hover:bg-gray-800'
                : 'bg-gray-400 text-gray-200 cursor-not-allowed'
            }`}
          >
            Come in
          </button>
        </form>
      </div>
    </div>
  );
}

export default RegistrationView;