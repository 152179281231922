import React from 'react';

export const ViewContainer = ({ children, bgColor = "bg-white" }) => (
  <div className={`
    w-full 
    ${bgColor}
    p-view-padding 
    medium:p-view-padding-md 
    overflow-y-auto 
    min-h-screen
    overflow-x-hidden
  `}>
    {children}
  </div>
);

export const IconContainer = ({ children }) => (
  <div className={`
    w-icon-container
    h-icon-container
    extraLarge:w-icon-container-xl
    extraLarge:h-icon-container-xl
    p-2 mb-6
    flex items-center justify-center 
    text-white 
    bg-stone-950 
    rounded-icon-container 
    font-silka-mono
  `}>
    {children}
  </div>
); 

export const CommonStyles = {
  labelSection: "flex justify-center items-center border-2 border-black rounded-2xl transform -translate-x-4",
  labelPitch: "w-[100px] h-12 mt-20",
  labelFeedback: "w-[140px] h-12 mt-20 flex justify-center items-center border-2 border-black rounded-2xl transform -translate-x-4",
  feedbackBubble: "relative font-silka-mono rounded-3xl max-w-xs border-2 border-black mt-10",
  feedbackArrow: "absolute -bottom-2 left-10 w-0 h-0 border-t-8 border-t-black border-r-8 border-r-transparent border-l-8 border-l-transparent",
  profileContainer: "flex items-center space-x-2 mt-10",
  profileImage: "w-8 h-8 rounded-full",
  profileText: "text-sm font-silka-mono",
  labelText: "font-silka-mono font-bold uppercase text-center p-2",
  btnPrimary: "w-[15em] px-8 py-4 bg-black text-white rounded-[50px] font-silka-mono uppercase",
  btnSecondary: "w-[15em] px-8 py-4 bg-transparent border-2 border-black text-black rounded-[50px] font-silka-mono uppercase",
}; 