import React from "react";
import Confetti from "./Confetti";

export const StatsPanel = ({
  animatedThanks,
  showConfetti,
  celebrate,
  heart,
  coin,
  action,
}) => (
  <div className="flex flex-col items-center bg-black rounded-[2.5rem] transform medium:translate-x-[-80px] large:translate-x-[30px] translate-x-[-30px] pt-4 shadow-lg p-4 w-20 medium:w-20 extraLarge:w-[100px] extraLarge:p-5 extraLarge:pt-8 extraLarge:rounded-[2.6rem]">
    <div className="flex flex-col items-center mb-2 mt-1">
      <img className="medium:w-10 medium:h-10 extraLarge:w-12 extraLarge:h-12 select-none" alt="Thanks" src={heart} />
      <p className="font-silka-mono text-white text-sm font-semibold medium:text-sm extraLarge:text-sm text-center select-none mt-1">
        {animatedThanks} <br />
        <span className="text-slate-400 font-thin text-[12px] medium:text-[12px] extraLarge:text-[12px]">THANKS</span>
      </p>
      {showConfetti && celebrate && <Confetti isActive={showConfetti} />}
    </div>

    {/* <div className="flex flex-col items-center mb-2">
      <img className="w-10 h-10 select-none" alt="Cash" src={coin} />
      <p className="font-silka-mono text-white text-[10px] text-center select-none mt-1 uppercase">
        Not yet <br />
        <span className="text-slate-400 font-thin text-[10px]">CASH</span>
      </p>
    </div>

    <div className="flex flex-col items-center">
      <img className="w-10 h-10 select-none" alt="BCT" src={action} />
      <p className="font-silka-mono text-white text-[10px] text-center select-none mt-1 uppercase">
        Not yet <br />
        <span className="text-slate-400 font-thin text-[10px]">BCT</span>
      </p>
    </div> */}
  </div>
);
