import React from "react";
import { useTranslation } from 'react-i18next';
import useLogout from "../../Profile-Help-Login/useLogout";
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
import logo from "../../../images/logo.png";
import useStore from "../../../API/store";

const Header = () => {
  const { t, i18n } = useTranslation();
  const {
    isSplitView
  } = useStore();
  const location = useLocation();
  const isHomePage = location.pathname.toLowerCase().includes('homepage');
  const { logout } = useLogout();
  
  const handleLogoClick = () => {
    const currentLang = i18n.language;
    return `/${currentLang}/Homepage`;
  };
  
  const textSizeClass = i18n.language === 'fr' 
    ? "text-[9px] extraLarge:text-[13px]"
    : "text-[11px] extraLarge:text-[14px]";
  
  return (
    <header className={`w-full ${isHomePage ? 'bg-white' : 'bg-aqua3'}`}>
      <div className={`${isSplitView ? "extraLarge:min-w-[700px] extraLarge:max-w-[1200px] ml-[20px]" : "extraLarge:min-w-[1320px] extraLarge:max-w-[1920px]" }  flex items-end justify-between h-[80px] pl-12 pr-2 extraLarge:h-[100px]  extraLarge:pl-0 mx-auto}`}>
        <div className="flex items-center">
          <Link to={handleLogoClick()}>
            <img src={logo} alt="Thankward Logo" className={` ${isHomePage ? "h-10 w-auto extraLarge:h-12" : "transform -translate-x-10 extraLarge:translate-x-20"}`} />
          </Link>
        </div>

        {isHomePage && (
          <div className="flex space-x-4">
            <div 
              onClick={logout} 
              className="h-14 w-14 extraLarge:h-[70px] extraLarge:w-[70px] rounded-2xl cursor-pointer bg-black transform translate-y-2 flex items-center justify-center"
            >
              <p className={`text-aqua3 ${textSizeClass} font-silka-mono uppercase font-semibold`}>
                {t('header.logout')}
              </p>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
