import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import useStore, { useStoreWithTranslation } from "../../../API/store";
import ResourceLink from "../Components/ResourceLink";
import TextAreaWithLabel from "../../../Components/TextAreaWithLabel";
import RadioQuestion from "../../../Components/RadioQuestion";
import useRadioQuestion from "../../../Components/Hooks/useRadioQuestion";
import BackArrow from "../../../Components/BackArrow";
import profile from "../../../images/icones/personna.png";

const NewAchievementsView = ({ handleGoBack, dioData, setSelectedStatus }) => {
  const { t } = useTranslation();
  const {
    setTitle,
    setPositiveAspect,
    setSelectedTalent,
    setLink,
    link,
    title,
    setIsInputVisible,
    setIsEditingTitle,
    setIsCalendarOpen,
    isInputVisible,
    positiveAspect,
    date,
    setLoading,
    addAchievement,
    setError,
    isCalendarOpen,
    isEditingTitle,
    setDate,
    selectedOrganizationId,
    menuOpenTalent,
    menuOpenHub,
    setSelectedOrganizationId,
    setMenuOpenTalent,
    setMenuOpenHub,
    fetchExecutions,
  } = useStore();
  
  const { selectedOrganization, setSelectedOrganization } = useStoreWithTranslation();
  
  const [isDateValid, setIsDateValid] = useState(false); // État pour la validité de la date
  useEffect(() => {
    // Recalculer si la date est valide à chaque modification de `date`
    if (date) {
      setIsDateValid(
        new Date(date).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)
      );
    }
  }, [date]); // Recalculer chaque fois que `date` change

  const teamValueOptions = [
    { 
      value: t('newAchievementsView.teamValue.minimal'), 
      description: t('newAchievementsView.teamValue.descriptions.minimal'), 
      score: 1 
    },
    { 
      value: t('newAchievementsView.teamValue.moderate'), 
      description: t('newAchievementsView.teamValue.descriptions.moderate'), 
      score: 2 
    },
    { 
      value: t('newAchievementsView.teamValue.significant'), 
      description: t('newAchievementsView.teamValue.descriptions.significant'), 
      score: 3 
    },
    { 
      value: t('newAchievementsView.teamValue.exceptional'), 
      description: t('newAchievementsView.teamValue.descriptions.exceptional'), 
      score: 4 
    },
  ];

  const complexityOptions = [
    { 
      value: t('newAchievementsView.complexity.simple'), 
      description: t('newAchievementsView.complexity.descriptions.simple'), 
      score: 1 
    },
    { 
      value: t('newAchievementsView.complexity.moderate'), 
      description: t('newAchievementsView.complexity.descriptions.moderate'), 
      score: 2 
    },
    { 
      value: t('newAchievementsView.complexity.complex'), 
      description: t('newAchievementsView.complexity.descriptions.complex'), 
      score: 3 
    },
    { 
      value: t('newAchievementsView.complexity.highlyComplex'), 
      description: t('newAchievementsView.complexity.descriptions.highlyComplex'), 
      score: 4 
    },
  ];

  const frequencyOptions = [
    { 
      value: t('newAchievementsView.frequency.routine'), 
      description: t('newAchievementsView.frequency.descriptions.routine'), 
      score: 1 
    },
    { 
      value: t('newAchievementsView.frequency.occasional'), 
      description: t('newAchievementsView.frequency.descriptions.occasional'), 
      score: 2 
    },
    { 
      value: t('newAchievementsView.frequency.rare'), 
      description: t('newAchievementsView.frequency.descriptions.rare'), 
      score: 3 
    },
    { 
      value: t('newAchievementsView.frequency.novel'), 
      description: t('newAchievementsView.frequency.descriptions.novel'), 
      score: 4 
    },
  ];

  const validateForm = () => {
    if (!(
      title.trim() !== "" &&
      positiveAspect.trim() !== "" &&
      date &&
      isDateValid &&
      teamValue.selectedScore !== null &&
      complexity.selectedScore !== null &&
      frequency.selectedScore !== null &&
      selectedOrganizationId !== null &&
      selectedOrganization !== t('newAchievementsView.chooseCollabSpace')
    )) {
      alert(t('newAchievementsView.fillFields'));
      return false;
    }
    return true;
  };
  const teamValue = useRadioQuestion(teamValueOptions);
  const complexity = useRadioQuestion(complexityOptions);
  const frequency = useRadioQuestion(frequencyOptions);

  const handleTitleChange = (e) => setTitle(e.target.value);
  const handleCalendar = () => setIsCalendarOpen(true);
  const handleDateChange = (e) => setDate(e.target.value);
  const handleOrganizationClick = (organization, id) => {
    setSelectedOrganization(organization);
    setSelectedOrganizationId(id);
    setMenuOpenHub(false);
  };
  const handleTalentClick = (selectedTalent) => {
    setSelectedTalent(selectedTalent);
    setMenuOpenTalent(false);
  };

  const handleLinkClick = () => setIsInputVisible(!isInputVisible);
  const handleInputChange = (event) => setLink(event.target.value);

  const newAchiementViewRef = useRef();
  useEffect(() => {
    if (newAchiementViewRef.current) {
      newAchiementViewRef.current.scrollIntoView({
        block: "start",
        behavior: "instant",
      });
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      alert("Please fill out all required fields before submitting.");
      return;
    }
    const achievement = {
      title,
      positiveAspect,
      teamValue: teamValue.selectedScore,
      complexity: complexity.selectedScore,
      frequency: frequency.selectedScore,
      date,
      link,
      selectedTalent: dioData[0]?.member.first_name,
      selectedOrganizationId,
    };

    console.log()

    setLoading(true);
    addAchievement(achievement)
      .then(() => {
        console.log("Achievement successfully submitted!");
        fetchExecutions(dioData).then(() => {
          console.log("Achievement successfully submitted!");
          setLoading(false);
        });
        setTitle("");
        setPositiveAspect("");
        setDate("");
        setLink("");
        setIsDateValid(false);
        setSelectedOrganizationId("");
        setSelectedOrganization(t('newIdeaView.chooseCollabSpace'));
        setIsInputVisible(false);
        handleGoBack();
        setSelectedStatus("all");
      })
      .catch((error) => {
        setError(error, "<======Error adding achievement.");
        setLoading(false);
      });
  };


  return (
    <div
      ref={newAchiementViewRef}
      className="w-full bg-rightGreyBg p-8 medium:p-32 overflow-y-auto max-h-[calc(110vh-64px)] overflow-x-hidden"
    >
      <div className="flex justify-between mb-20">
        <BackArrow onClick={handleGoBack} />
        {!isCalendarOpen && (
          <div
            onClick={handleCalendar}
            className="text-xs w-[92px] h-[150px] p-4 pl-3 pr-3 mb-6 flex flex-col items-center justify-center text-white bg-stone-950 rounded-lg font-silka-mono cursor-pointer"
          >
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 mb-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 3v1.5m7.5-1.5v1.5M3.75 9h16.5M4.5 5.25h15a2.25 2.25 0 012.25 2.25v11.25a2.25 2.25 0 01-2.25 2.25H4.5a2.25 2.25 0 01-2.25-2.25V7.5a2.25 2.25 0 012.25-2.25z"
                />
              </svg>
              {t('newAchievementsView.whenToLife')}
            </p>
          </div>
        )}
        {isCalendarOpen && (
          <input
            type="date"
            className="text-xs w-[132px] h-[60px] p-4 pl-3 pr-3 mb-6 flex items-center justify-center text-white bg-stone-950 rounded-lg font-silka-mono cursor-pointer"
            onChange={handleDateChange}
            value={date}
            max={new Date().toISOString().split('T')[0]} // Date actuelle comme limite maximale
          />
        )}
      </div>
      <div className="flex space-x-4">
        <div className="flex justify-center items-center w-[240px] h-8 border border-1 border-black rounded-xl relative">
          <button
            type="button"
            className="flex justify-center items-center w-full h-full text-xs font-silka-mono uppercase p-2"
            onClick={() => setMenuOpenHub(!menuOpenHub)}
          >
             {selectedOrganization}
            <svg
              className="ml-auto h-5 w-5 text-gray-900"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {menuOpenHub && (
            <div className="absolute right-0 z-10 mt-[300px] w-[240px] origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {dioData.map((item, index) => (
                  <button
                    key={index}
                    className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                    onClick={() => handleOrganizationClick(item.name, item.id)}
                  >
                    {item.name}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
        {/* <div className="flex justify-center items-center w-[160px] h-8 border border-1 border-black rounded-xl">
                    <p className="font-silka-mono uppercase text-xs text-center p-2">
                        add to a project
                    </p>
                </div> */}
      </div>

      {!isEditingTitle ? (
        <textarea
          value={title}
          onChange={handleTitleChange}
          onBlur={() => setIsEditingTitle(false)}
          placeholder={t('newAchievementsView.title')}
          autoFocus
          maxLength={40}
          className="font-silka-mono font-semibold text-2xl medium:text-4xl extraLarge:text-5xl underline italic mt-6 bg-transparent border-none outline-none resize-none overflow-hidden h-18 placeholder-black"
          rows={2}
        />
      ) : (
        <div className="font-silka-mono font-semibold text-4xl mt-6 underline italic cursor-pointer">
          {title.split("\n").map((line, index) => (
            <span key={index}>
              {line}
              {index < title.split("\n").length - 1 && <br />}
            </span>
          ))}
        </div>
      )}
      {/* Talent & Organization Selection */}
      <div className="flex space-x-4 mt-10 mb-10">
        <div className="flex justify-start items-center w-[120px] h-8 rounded-xl">
          <img className="w-8 h-8 rounded-full" alt="profil" src={profile} />
          <p className="font-silka-mono text-xs text-center p-2">
            {dioData[0]?.member.first_name}
          </p>
        {/* </div>
        <div className="flex justify-start items-center w-[200px] h-8 rounded-xl border border-1 border-black p-2">
          <img className="w-5 h-5 rounded-full" alt="profil" src={profile} />
          <div className="relative inline-block text-left">
            <div>
              <button
                type="button"
                className="flex justify-start items-center w-[160px] h-8 rounded-xl border-black p-2"
                id="menu-button"
                aria-expanded="true"
                aria-haspopup="true"
                onClick={() => setMenuOpenTalent(!menuOpenTalent)}
              >
                {dioData[0]?.member.first_name}
                <svg
                  className="ml-auto h-5 w-5 text-gray-900"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
                    clipRule="evenodd"
                  />
                </svg> 
              </button>
            </div>

        {menuOpenTalent && (
              <div
                className="absolute left-0 z-10 mt-2  w-[150px] origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabIndex="-1"
              >
                <div className="py-1" role="none">
                  <button
                    className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                    tabIndex="-1"
                    onClick={() =>
                      handleTalentClick(dioData[0]?.member.first_name)
                    }
                  >
                    {dioData[0]?.member.first_name}
                  </button>
                  <button
                    className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                    tabIndex="-1"
                    onClick={() => handleTalentClick("Another Talent")}
                  >
                    Another Talent
                  </button>
                </div>
              </div>
            )}
          </div> */}
        </div>
      </div>

      {/* Achievement Description */}
      <TextAreaWithLabel
        label={
          <>
          <span className="medium:block hidden">
            {t('newAchievementsView.whyImportant')}
          </span>
                    <span className="medium:hidden block">
                    {t('newAchievementsView.whyImportant')}
                  </span>
          </>
        }
        value={positiveAspect}
        placeholder={t('newAchievementsView.provideBrief')}
        onChange={(e) => setPositiveAspect(e.target.value)}
        bg="bg-rightGreyBg"
        maxLength={250}
      />


      {/* Link Input */}
      <ResourceLink
        handleLinkClick={handleLinkClick}
        isInputVisible={isInputVisible}
        link={link}
        handleInputChange={handleInputChange}
      />

      {isDateValid && (
        <>
          <RadioQuestion
            label={t('newAchievementsView.teamValue.label')}
            options={teamValueOptions}
            selectedIndex={teamValue.selectedIndex}
            selectedValue={teamValue.selectedValue}
            selectedScore={teamValue.selectedScore}
            onChange={teamValue.handleChange}
          />

          <RadioQuestion
            label={t('newAchievementsView.complexity.label')}
            options={complexityOptions}
            selectedIndex={complexity.selectedIndex}
            selectedValue={complexity.selectedValue}
            selectedScore={complexity.selectedScore}
            onChange={complexity.handleChange}
          />

          <RadioQuestion
            label={t('newAchievementsView.frequency.label')}
            options={frequencyOptions}
            selectedIndex={frequency.selectedIndex}
            selectedValue={frequency.selectedValue}
            selectedScore={frequency.selectedScore}
            onChange={frequency.handleChange}
          />
        </>
      )}

      <form onSubmit={onSubmit} className="mt-10 mb-20">
        {/* Affichage du bouton en fonction de la date */}
        {isDateValid ? (
          <button
            type="submit"
            className="w-[14em] px-8 py-4 bg-black text-white rounded-[50px] font-silka-mono uppercase"
            // disabled={!validateForm()}
          >
            {t('newAchievementsView.makeItHappen')}
          </button>
        ) : (
          <button
            type="submit"
            className="w-[14em] px-8 py-4 bg-rightGreyBg border-2 border-black text-black rounded-[50px] font-silka-mono uppercase ml-5"
          >
            {t('newAchievementsView.saveForLater')}
          </button>
        )}
      </form>
    </div>
  );
};

export default NewAchievementsView;
