import React from "react";
import esc from "../../../images/icones/esc.png";
import plus from "../../../images/icones/PlusIcon.svg";




const PlusButton =({clicked,handleClick })=> {


return (
  <button
    onClick={handleClick}
    className={`flex items-center justify-center w-[70px] h-[70px] transform translate-y-[-10px] translate-x-[-30px] medium:translate-x-[-80px] large:translate-x-[30px] extraLarge:translate-y-[2px] medium:translate-y-[0px] extraLarge:translate-x-[30px] medium:w-[70px] medium:h-[70px] extraLarge:w-[80px] extraLarge:h-[80px] bg-black rounded-full shadow-lg hover:bg-gray-900 transition-colors m-4 duration-200 mx-auto mb-4 hover:opacity-90 ${
      clicked ? "rotate-fast" : "rotate-slow"
    }`}
  >
    {clicked ? (
      <img className="w-full h-full p-4 medium:p-5 extraLarge:p-6 select-none" alt="exit" src={esc} />
    ) : (
      <img className="text-white text-center justify-center w-full h-full font-extralight select-none  p-5 extraLarge:p-6" src={plus} alt="plus" />
    )}
  </button>
);
}

export default PlusButton
