import React, { useEffect, useState, useRef } from "react";
import useStore from "../../../API/store";
import axios from "axios";
import { StatsPanel } from "./StatsPanel";
import { ActionMenu } from "./ActionMenu";
import PlusButton from "./PlusButton";
import heart from "../../../images/heart2.png";
import coin from "../../../images/piece3.png";
import action from "../../../images/action.png";

const FixedSidebar = ({
  insideDO,
  totalThanks,
  setIsSplitView,
  setIsCreating,
  setCreationType,
  celebrate,
  timerRef
}) => {
  const {
    clicked,
    setClicked,
  } = useStore();
  const handleAddAchievementClick = (type) => () => {
    setIsSplitView(true);
    setIsCreating(true);
    setCreationType(type);
  };

  const [localTotalThanks, setLocalTotalThanks] = useState(0);
  const [animatedThanks, setAnimatedThanks] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);

  const userId = localStorage.getItem("userId");
  const IdDO = localStorage.getItem("DOId");
  const do_ids = localStorage.getItem("do_ids");
  const clickedByPlusButton = useRef(false);

  const handleClick = () => {
    if (clicked) {
      setClicked(false); 
    } else {
      clickedByPlusButton.current = true;  
      setClicked(true);  
      clearTimeout(timerRef.current);  
    }
  };
  
  useEffect(() => {
    timerRef.current = setTimeout(() => {
      if (!clickedByPlusButton.current) {
        setClicked(false);
      }
    }, 3000);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);


  useEffect(() => {
    const fetchTotalThanks = async () => {
      try {
        let response;
        if (insideDO && userId && IdDO) {
          response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/thanks/${userId}/${IdDO}`
          );
        } else if (!insideDO && userId) {
          response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/thanks/${userId}`
          );
          setLocalTotalThanks(response.data.totalThanks);
        }
        if (response) {
          setLocalTotalThanks(response.data.totalThanks);
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des thanks depuis le backend",
          error
        );
      }
    };
    fetchTotalThanks();
  }, [userId, IdDO, do_ids, insideDO]);

  const intervalRef = useRef(null);

  useEffect(() => {
    const targetThanks = Number(localTotalThanks) + Number(totalThanks);
    const increment = targetThanks > animatedThanks ? 1000 : null;

    if (increment) {
      intervalRef.current = setInterval(() => {
        setAnimatedThanks((prevThanks) => {
          const newThanks = prevThanks + increment;
          if (newThanks >= targetThanks) {
            clearInterval(intervalRef.current);
            setShowConfetti(true);
            setTimeout(() => setShowConfetti(false), 1000);
            return targetThanks;
          }
          return newThanks;
        });
      }, 10);
    }

    return () => clearInterval(intervalRef.current);
  }, [localTotalThanks, totalThanks, animatedThanks]);

  const sharedStyles =
    "bg-black m-1 pl-6 flex-1 flex justify-start items-center rounded-md border-2 border-white font-silka-mono text-xs text-white uppercase select-none cursor-pointer hover:bg-gray-900 transition-colors duration-200";

  return (
    <div className="fixed bottom-[2.3%] right-[20px] medium:right-[20px] large:right-[calc(50%-600px)] extraLarge:right-[calc(50%-688px)] z-50 transform translate-x-4">
      <div className="relative">
        {clicked && (
          <div className="absolute bottom-full transform -translate-y-[160px] right-0 mb-4">
            <ActionMenu
              onAchievementClick={handleAddAchievementClick}
              sharedStyles={sharedStyles}
            />
          </div>
        )}
        <StatsPanel
          animatedThanks={animatedThanks}
          showConfetti={showConfetti}
          celebrate={celebrate}
          heart={heart}
          coin={coin}
          action={action}
        />
        <PlusButton clicked={clicked} handleClick={handleClick} />
      </div>
    </div>
  );
};

export default FixedSidebar;
