import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import BackArrow from "../../Components/BackArrow";
import TextAreaWithLabel from "../../Components/TextAreaWithLabel";
import OrganizationType from "./Components/OrganizationType";
import ResourceLink from "./Components/ResourceLink";
import useStore from "../../API/store";
import Loader from "../../Components/Loader";

function NewTalentHub({ handleGoBack, setSelectedStatus }) {
  const { t } = useTranslation();
  const {
    setError,
    createTalentsHub,
    nameDO,
    setNameDO,
    setPitch,
    setWhatToDo,
    setWhyImportant,
    setOrganizationType,
    setLink,
    pitch,
    whatToDo,
    whyImportant,
    organizationType,
    showResourceInput,
    link,
    setShowResourceInput,
    isSubmitting,
    setIsSubmitting
  } = useStore();

  const isValidUrl = (url) => {
    const regex = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/gm;
    return regex.test(url);
  };
  
  const validateForm = () => {
    if (link.trim() && !isValidUrl(link)) {
      alert(t('validUrl'));
      return false;
    }

    if (!nameDO.trim() || !pitch.trim() || !whatToDo.trim() || !whyImportant.trim() || !organizationType.trim()) {
      alert(t('requiredFields'));
      return false;
    }

    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setIsSubmitting(true);

    const userId = localStorage.getItem("userId");
    const payload = {
      nameDO: nameDO.trim(),
      descriptionDO: pitch.trim(),
      CeoDoId: Number(userId),
      typeActivityDO: organizationType.trim(),
      linkDO: link.trim() || null,
      makeitbetterDO: whyImportant.trim(),
      keywordsDO: whatToDo.trim()
    };

    try {
      await createTalentsHub(payload);
      handleGoBack();
      setSelectedStatus("all");
      
      // Reset form
      setNameDO("");
      setPitch("");
      setOrganizationType("");
      setLink("");
      setWhyImportant("");
      setWhatToDo("");
      
    } catch (error) {
      console.error("Error creating TalentHub:", error);
      setError(error.message || t('newTalentHub.errors.creation'));
      alert(error.message.includes("Timeout") ? 
        t('newTalentHub.errors.timeout') : 
        t('newTalentHub.errors.network')
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "organizationName":
        setNameDO(value);
        break;
      case "pitch":
        setPitch(value);
        break;
      case "whatToDo":
        setWhatToDo(value);
        break;
      case "whyImportant":
        setWhyImportant(value);
        break;
      default:
        break;
    }
  };
  const handleInputLinkChange = (e) => {
    setLink(e.target.value); // Mettre à jour le store avec la nouvelle valeur
  };
  const handleTypeSelect = (type) => {
    setOrganizationType(type);
  };

  const toggleResourceInput = () => {
    setShowResourceInput(!showResourceInput)
  };

  const newTalentHubViewRef = useRef();
  useEffect(() => {
    if (newTalentHubViewRef.current) {
      newTalentHubViewRef.current.scrollIntoView({
        block: "start",
        behavior: "instant",
      });
    }
  }, []);
  return (
    <div
      ref={newTalentHubViewRef}
      className="w-full bg-rightGreyBg p-8 medium:p-32 overflow-y-auto max-h-[calc(110vh-64px)]"
    >
      <div className="max-w-2xl mx-auto mb-20">
        <BackArrow onClick={handleGoBack} />

        <div className="space-y-12">
          <div>
            <h2 className="text-sm uppercase mb-2 mt-12 font-silka-mono">
              {t('newTalentHub.title')}
            </h2>
            <h1 className="text-4xl font-bold mb-8 font-silka-mono mt-6">
              {t('newTalentHub.subtitle')}
            </h1>
          </div>

          <form onSubmit={onSubmit} className="space-y-12">
            <div>
              <input
                type="text"
                name="organizationName"
                placeholder={t('newTalentHub.form.nameDO')}
                className="w-[80%] px-4 py-3 border-2 border-black bg-transparent placeholder-black/70 rounded-xl outline-none font-silka-mono"
                value={nameDO}
                onChange={handleInputChange}
              />
            </div>

            <TextAreaWithLabel
              label={t('newTalentHub.form.pitch.label')}
              name="pitch"
              placeholder={t('newTalentHub.form.pitch.placeholder')}
              value={pitch}
              onChange={handleInputChange}
              bg="bg-rightGreyBg"
            />

            <ResourceLink
              handleLinkClick={toggleResourceInput}
              isInputVisible={showResourceInput}
              link={link}
              handleInputChange={handleInputLinkChange}
            />

            <TextAreaWithLabel
              label={t('newTalentHub.form.whatToDo.label')}
              name="whatToDo"
              placeholder={t('newTalentHub.form.whatToDo.placeholder')}
              value={whatToDo}
              onChange={handleInputChange}
              bg="bg-rightGreyBg"
            />

            <TextAreaWithLabel
              label={t('newTalentHub.form.whyImportant.label')}
              name="whyImportant"
              placeholder={t('newTalentHub.form.whyImportant.placeholder')}
              value={whyImportant}
              onChange={handleInputChange}
              bg="bg-rightGreyBg"
            />

            <OrganizationType
              selected={organizationType}
              onSelect={handleTypeSelect}
            />

            <button
              type="submit"
              disabled={isSubmitting}
              className={`px-8 py-4 bg-black text-white rounded-full hover:bg-black/90 transition-colors mt-6 font-silka-mono ${
                isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {isSubmitting ? (
                <div className="flex items-center justify-center">
                  <Loader size="small" color="white" />
                  <span className="ml-2">{t('newTalentHub.form.creating')}</span>
                </div>
              ) : (
                t('newTalentHub.form.submit')
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default NewTalentHub;
