const translations = {
  translation: {
    "login": {
      "title": "Déjà membre ?",
      "email": "VOTRE EMAIL",
      "password": "VOTRE MOT DE PASSE",
      "welcome": "BIENVENUE",
      "error": "Identifiant ou mot de passe incorrect.",
      "leftSection": {
        "mainTitle": "Un nouveau monde nécessite un nouveau modèle",
        "subtitle": "Thankward en trois phrases :",
        "point1": "Un modèle universel et systématique de création de richesse qui simplifie le parcours vers une vie réussie pour les personnes méritantes.",
        "point2": "Des valeurs de reconnaissance et de confiance qui permettent un progrès libre et équitable vers la prospérité.",
        "point3": "Une plateforme technologique de pointe et intuitive qui rend le processus réalisable, agréable et très puissant."
      }
    },
    "filterStatusCards": {
      "collabSpace": "Collab Space",
      "onGoing": {
        "subtitle": "Complétez pour gagner vos Thanks",
        "opportunities": "Mes actions en cours",
        "callToAction": "TERMINER",
        "status": "ongoing"
      },
      "open": {
        "subtitle": "Saisissez de nouveaux Thanks, Réalisez plus",
        "opportunities": "Mes opportunités de travail",
        "callToAction": "JE PRENDS",
        "status": "open"
      },
      "inReview": {
        "subtitle": "Donnez votre feedback, Gagnez de nouveaux Thanks",
        "opportunities": "Mes feedbacks à donner",
        "callToAction": "MON FEEDBACK",
        "status": "in review"
      },
      "achieved": {
        "subtitle": "Mes réalisations et gains",
        "opportunities": "Mes réalisations",
        "callToAction": "COLLECTER",
        "status": "achieved"
      }
    },
    // Ajout des nouvelles traductions pour le menu d'action
    actionMenu: {
      addMission: "Créer une mission",
      shareIdea: "Partager une idée",
      inviteTalent: "Inviter un talent",
      createCollabSpace: "Créer un Collab Space"
    },
    "newIdeaView": {
      "title": "Titre\nde votre idée",
      "chooseCollabSpace": "Choisir un Collab Space",
      "addToProject": "Ajouter à un projet",
      "describeIdea": "Décrivez votre idée",
      "whyImportant": "Pourquoi votre idée est importante ?",
      "whenToLife": "Quand voulais-vous\nvoir l'idée\nprendre vie ?",
      "provideBrief": "Donnez une brève description",
      "shareIdea": "Partager l'idée",
      "saveForLater": "Sauvegarder",
      "addResource": "Ajouter une ressource",
      "resourceLink": "Lien de la ressource",
      "fillFields": "Veuillez remplir tous les champs requis.",
      "selectCollabSpace": "Veuillez sélectionner un Collab Space"
    },
    "newAchievementsView": {
      "title": "Titre de votre mission",
      "chooseCollabSpace": "Choisir un Collab Space",
      "describeAchievement": "Décrivez votre mission",
      "whyImportant": "Que voulez-vous mettre en avant\ndans cette mission et pourquoi\nest-ce important ?",
      "whenToLife": "Quand souhaitez-vous\nvoir la mission\nprendre vie ?",
      "provideBrief": "Donnez une brève description",
      "makeItHappen": "Créer",
      "saveForLater": "Sauvegarder",
      "addResource": "Ajouter une ressource",
      "resourceLink": "Lien de la ressource",
      "fillFields": "Veuillez remplir tous les champs requis.",
      "selectCollabSpace": "Veuillez sélectionner un Collab Space",
      "teamValue": {
        "label": "Selon vos coéquipiers, quelle\nserait la valeur perçue\nde cette mission ?",
        "minimal": "Minimale",
        "moderate": "Modérée",
        "significant": "Significative",
        "exceptional": "Exceptionnelle",
        "descriptions": {
          "minimal": "Peu ou pas de valeur perçue",
          "moderate": "Quelque valeur perçue",
          "significant": "Valeur notable",
          "exceptional": "Valeur élevée"
        }
      },
      "complexity": {
        "label": "Comment évalueriez-vous\nla complexité de\ncette mission ?",
        "simple": "Simple",
        "moderate": "Modérée",
        "complex": "Complexe",
        "highlyComplex": "Très complexe",
        "descriptions": {
          "simple": "Compétences minimales requises",
          "moderate": "Quelques compétences, créativité",
          "complex": "Compétences avancées, créativité",
          "highlyComplex": "Compétences expertes, innovation"
        }
      },
      "frequency": {
        "label": "Comment évalueriez-vous\nla nouveauté de cette mission\npour vous ?",
        "routine": "Routine",
        "occasional": "Occasionnelle",
        "rare": "Rare",
        "novel": "Nouvelle",
        "descriptions": {
          "routine": "Régulier, changement minimal",
          "occasional": "Parfois, légères variations",
          "rare": "Rarement, éléments notables",
          "novel": "Première fois, nouveauté maximale"
        }
      }
    },
    "cooptView": {
      "title": "FORMULAIRE - COOPTATION",
      "subtitle": "Inviter un talent",
      "chooseCollabSpace": "Choisir un\nCollab Space",
      "sponsoring": {
        "title": "Sponsoring",
        "subtitle": "Pour le créateur\nd'un Collab Space"
      },
      "whoInvite": "Qui souhaitez-vous inviter ?",
      "form": {
        "firstName": "PRÉNOM",
        "lastName": "NOM",
        "email": "EMAIL",
        "message": "VOTRE MESSAGE",
        "send": "ENVOYER",
        "saveForLater": "SAUVEGARDER"
      },
      "validation": {
        "required": "Veuillez remplir tous les champs requis.",
        "validEmail": "Veuillez entrer une adresse email valide.",
        "selectCollabSpace": "Veuillez sélectionner un Collab Space valide"
      }
    },
    "newTalentHub": {
      "title": "INSCRIPTION",
      "subtitle": "Créer votre\nnouveau Collab Space",
      "form": {
        "nameDO": "NOM DE L'ORGANISATION",
        "pitch": {
          "label": "Présentez\nvotre Collab Space",
          "placeholder": "Donnez une brève description"
        },
        "whatToDo": {
          "label": "Que voulez-vous\nfaire",
          "placeholder": "Donnez une brève description"
        },
        "whyImportant": {
          "label": "Pourquoi est-ce\nimportant ?",
          "placeholder": "Donnez une brève description"
        },
        "organizationType": "TYPE D'ORGANISATION",
        "link": "LIEN (optionnel)",
        "terms": "J'accepte les conditions générales du modèle Thankword\npour les organisations",
        "submit": "CRÉER",
        "creating": "CRÉATION EN COURS..."
      },
      "errors": {
        "creation": "Une erreur est survenue lors de la création",
        "timeout": "La requête a pris trop de temps. Veuillez réessayer.",
        "network": "Erreur réseau, veuillez réessayer"
      },
      "organizationType": {
        "title": "Choisissez la nature de votre organisation",
        "types": {
          "forProfit": "À BUT LUCRATIF",
          "nonProfit": "À BUT NON LUCRATIF",
          "academic": "ACADÉMIQUE",
          "lifestyle": "MODE DE VIE"
        }
      }
    },
    "feedback": {
      "title": "FORMULAIRE - FEEDBACK",
      "subtitle": "Donnez votre feedback",
      "form": {
        "brief": "ÉCRIVEZ\nVOTRE FEEDBACK",
        "deliverables": "LIVRABLES",
        "highlight": {
          "label": "Quels aspects de cette réalisation souhaitez-vous mettre en avant,\net pourquoi sont-ils importants ?",
          "placeholder": "Donnez une brève description"
        },
        "improvement": {
          "label": "Que suggéreriez-vous pour l'améliorer la prochaine fois ?",
          "placeholder": "Donnez une brève description"
        },
        "teamValue": {
          "label": "Selon vos coéquipiers, quelle\nserait la valeur perçue\nde cette mission ?"
        },
        "quality": {
          "label": "Comment évalueriez-vous\nla qualité de\ncette réalisation ?",
          "descriptions": {
            "low": "Nécessite des améliorations",
            "medium": "Exigences minimales satisfaites",
            "high": "Toutes les exigences satisfaites",
            "exceptional": "Dépasse précisément les exigences"
          }
        },
        "reactivity": {
          "label": "Comment évalueriez-vous\nla réactivité globale\ndu réalisateur ?",
          "descriptions": {
            "low": "Nécessite des améliorations",
            "medium": "Besoins de base satisfaits",
            "high": "Proactif et dans les temps",
            "exceptional": "Rapide et adaptable"
          }
        },
        "submit": "PUBLIER"
      },
      "errors": {
        "submission": "Une erreur est survenue. Veuillez réessayer.",
        "required": "Veuillez remplir tous les champs requis.",
        "highlight": "Veuillez remplir le champ 'Quels aspects de cette réalisation souhaitez-vous mettre en avant, et pourquoi sont-ils importants ?'",
        "improvement": "Veuillez remplir le champ 'Que suggéreriez-vous pour l'améliorer la prochaine fois ?'",
        "teamValue": "Veuillez remplir le champ 'Selon vos coéquipiers, quelle\nserait la valeur perçue\nde cette mission ?'",
        "quality": "Veuillez remplir le champ 'Comment évalueriez-vous\nla qualité de\ncette réalisation ?'",
        "reactivity": "Veuillez remplir le champ 'Comment évalueriez-vous\nla réactivité globale\ndu réalisateur ?'",
        "submit": "Veuillez cliquer sur 'PUBLIER'"
      },
      "rating": {
        "low": "Faible",
        "medium": "Moyenne",
        "high": "Élevée",
        "exceptional": "Exceptionnelle"
      },
      "teamValue": {
        "descriptions": {
          "low": "Peu ou pas de valeur perçue",
          "medium": "Quelque valeur perçue",
          "high": "Valeur notable",
          "exceptional": "Valeur élevée"
        }
      },
      "quality": {
        "descriptions": {
          "low": "Nécessite des améliorations",
          "medium": "Exigences minimales satisfaites",
          "high": "Toutes les exigences satisfaites",
          "exceptional": "Dépasse précisément les exigences"
        },
        "label": "Comment évalueriez-vous\nla qualité de\ncette réalisation ?"
      },
      "reactivity": {
        "descriptions": {
          "low": "Nécessite des améliorations",
          "medium": "Besoins de base satisfaits",
          "high": "Proactif et dans les temps",
          "exceptional": "Rapide et adaptable"
        },
        "label": "Comment évalueriez-vous\nla réactivité globale\ndu réalisateur ?"
      },
      "ratingTeamValue": {
        "low": "Minimale",
        "medium": "Modérée",
        "high": "Significative",
        "exceptional": "Exceptionnelle"
      },
      "ratingQuality": {
        "low": "Pas encore",
        "medium": "Basique",
        "high": "Bon",
        "veryhigh": "Élevé",
        "exceptional": "Remarquable"
      },
      "ratingReactivity": {
        "low": "Insuffisant",
        "medium": "Réactif",
        "high": "Proactif",
        "veryhigh": "Agile",
        "exceptional": "Exceptionnel"
      },
      "labels": {
        "alreadyFeedback": "Vous avez déjà soumis votre feedback"
      }
    },
    "ideaFeedback": {
      "form": {
        "feeling": {
          "label": "Mon ressenti sur cette idée",
          "placeholder": "Écrire"
        },
        "contribution": {
          "label": "Comment puis-je contribuer et quand ?",
          "optional": "(optionnel)",
          "placeholder": "Écrire"
        },
        "buttons": {
          "approve": "OUI, FAISONS-LE",
          "reject": "NON MERCI !"
        }
      },
      "labels": {
        "pitch": "PITCH",
        "alreadyFeedback": "Vous avez déjà donné votre feedback sur cette idée."
      }
    },
    "ideaView": {
      "labels": {
        "pitch": "PITCH",
        "feedback": "FEED-BACK",
        "thankward": "Thankward by MKIF"
      },
      "feedback": {
        "contribution": "Contribution",
        "unknownFirstName": "Prénom inconnu",
        "unknownLastName": "Nom inconnu"
      }
    },
    "editIdea": {
      "title": {
        "placeholder": "Titre\nde votre idée"
      },
      "form": {
        "describe": {
          "label": "Décrivez votre idée",
          "placeholder": "Donnez une brève description"
        },
        "importance": {
          "label": "Pourquoi votre idée est importante ?",
          "placeholder": "Donnez une brève description"
        },
        "calendar": {
          "question": "Quand voulais-vous\nvoir l'idée\nprendre vie ?"
        },
        "resource": {
          "label": "Ajouter une ressource",
          "placeholder": "Lien de la ressource"
        },
        "buttons": {
          "share": "Partager l'idée",
          "save": "Sauvegarder"
        }
      }
    },
    "opportunityView": {
      "labels": {
        "brief": "BRIEF",
      },
      "buttons": {
        "iWantIt": "Je prends"
      },
      "errors": {
        "unknownFirstName": "Prénom inconnu",
        "unknownLastName": "Nom inconnu"
      }
    },
    "draftView": {
      "labels": {
        "brief": "BRIEF"
      },
      "errors": {
        "unknownFirstName": "Prénom inconnu",
        "unknownLastName": "Nom inconnu"
      }
    },
    "percentageViz": {
      "yes": "Oui",
      "no": "Non"
    },
    "header": {
      "logout": "Quitter"
    },
    "card": {
      "shareIdea": "PARTAGER L'IDÉE",
      "myFeedback": "MON FEEDBACK",
      "soon": "Pas de Thanks",
      "days": "J",
      "hours": "H",
      "minutes": "M",
      "seconds": "S"
    },
    "filterView": {
      "byCollabSpace": "Par Collab Space",
      "byStatus": "Par Statut",
      "allCollabSpaces": "Tous mes collab spaces",
      "onGoing": "En cours",
      "open": "Ouvert",
      "feedback": "Feedback",
      "achieved": "Réalisé"
    }
  }
}

export default translations; 