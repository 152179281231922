import { Navigate, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import useStore from '../API/store';
import { useTranslation } from 'react-i18next';

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');
  const navigate = useNavigate();
  const { isSplitView, setIsSplitView } = useStore();
  const { i18n } = useTranslation();

  useEffect(() => {
    // Remplacer l'entrée actuelle dans l'historique par Homepage
    if (token && userId) {
      const currentLang = i18n.language || 'fr';
      window.history.replaceState(null, '', `/${currentLang}/Homepage`);
    }

    // Empêcher la navigation arrière si authentifiéidea
    const handlePopState = (event) => {
      if (token && userId) {
        if (isSplitView) {
          // Si on est en vue splittée, on ferme la vue
          event.preventDefault();
          setIsSplitView(false);
        } else {
          // Sinon comportement normal : rester sur Homepage
          const currentLang = i18n.language || 'fr';
          window.history.pushState(null, '', `/${currentLang}/Homepage`);
          event.preventDefault();
        }
      }
    };

    window.addEventListener('popstate', handlePopState);
    // Ajouter une entrée dans l'historique pour Homepage
    const currentLang = i18n.language || 'fr';
    window.history.pushState(null, '', `/${currentLang}/Homepage`);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [token, userId, isSplitView, setIsSplitView, i18n]);

  if (!token || !userId) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default PrivateRoute; 