// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from './Components/PrivateRoute.js';
import Homepage from "./pages/HomePage/Homepage";
import Welcome from "./pages/Welcome/welcome";
import LoginPage from "./pages/Profile-Help-Login/login";
import { TasksProvider } from "./API/Executions.js";
import "./index.css";
import './i18n/config';
import { useTranslation } from 'react-i18next';
import i18n from './i18n/config';  // Assurez-vous que ce chemin est correct

// Initialisation de la langue au démarrage
const initializeLanguage = () => {
  // Récupérer la langue depuis l'URL actuelle
  const pathSegments = window.location.pathname.split('/');
  const langFromUrl = pathSegments[1];  // 'fr' ou 'en'

  if (langFromUrl === 'fr' || langFromUrl === 'en') {
    // Si l'URL contient une langue valide, l'utiliser
    localStorage.setItem('userLanguage', langFromUrl);
    i18n.changeLanguage(langFromUrl);
  } else {
    // Sinon, utiliser la langue stockée ou le français par défaut
    const storedLang = localStorage.getItem('userLanguage');
    if (storedLang) {
      i18n.changeLanguage(storedLang);
    } else {
      localStorage.setItem('userLanguage', 'fr');
      i18n.changeLanguage('fr');
    }
  }
};

// Exécuter l'initialisation avant le rendu
initializeLanguage();

// Composant pour gérer la redirection avec la langue
const LanguageRedirect = ({ to }) => {
  const { i18n } = useTranslation();
  // Utiliser la langue de l'URL ou la langue stockée
  const pathSegments = window.location.pathname.split('/');
  const langFromUrl = pathSegments[1];
  const currentLang = (langFromUrl === 'fr' || langFromUrl === 'en') 
    ? langFromUrl 
    : (localStorage.getItem('userLanguage') || 'fr');
  
  return <Navigate to={`/${currentLang}${to}`} replace />;
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <TasksProvider>
    <BrowserRouter>
      <Routes>
        {/* Route par défaut - utilise la langue stockée */}
        <Route path="/" element={<LanguageRedirect to="/login" />} />
        <Route path="/fr" element={<Navigate to="/fr/login" replace />} />
        <Route path="/en" element={<Navigate to="/en/login" replace />} />

        {/* Routes Homepage - utilise la langue stockée */}
        <Route path="/Homepage" element={<LanguageRedirect to="/homepage" />} />
        <Route path="/homepage" element={<LanguageRedirect to="/homepage" />} />

        {/* Routes françaises */}
        <Route path="/fr/login" element={<LoginPage />} />
        <Route path="/fr/welcome" element={<Welcome />} />
        <Route 
          path="/fr/homepage" 
          element={
            <PrivateRoute>
              <Homepage />
            </PrivateRoute>
          } 
        />

        {/* Routes anglaises */}
        <Route path="/en/login" element={<LoginPage />} />
        <Route path="/en/welcome" element={<Welcome />} />
        <Route 
          path="/en/homepage" 
          element={
            <PrivateRoute>
              <Homepage />
            </PrivateRoute>
          } 
        />
      </Routes>
    </BrowserRouter>
  </TasksProvider>
);
