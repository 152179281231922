const translations = {

  translation: {

    "login": {

      "title": "Déjà membre ?",

      "email": "VOTRE EMAIL",

      "password": "VOTRE MOT DE PASSE",

      "welcome": "BIENVENUE",

      "error": "Identifiant ou mot de passe incorrect.",

      "leftSection": {

        "mainTitle": "Ensemble, construisons l'écosystème ultime pour travailler, prospérer et faire progresser le monde",

        "subtitle": "Thankward en trois phrases :",

        "point1": "Une plateforme visionnaire et intuitive",

        "point2": "modèle de création de richesse d’une simplicité puissante",

        "point3": "L’accomplissement individuel au service de sociétés prospères"

      }

    },

    "filterStatusCards": {

      "collabSpace": "Mes Collab Space",

      "onGoing": {

        "subtitle": "Missions en attente de finalisation ",

        "opportunities": "Consulter mes missions en cours",

        "callToAction": "JE FINALISE",

        "status": "ongoing"

      },

      "open": {

        "subtitle": "Missions disponibles pour vous",

        "opportunities": " Explorer les opportunités de travail ",

        "callToAction": "JE M’EN OCCUPE",

        "status": "open"

      },

      "inReview": {

        "subtitle": " Missions à valoriser par votre feedback ",

        "opportunities": " Feedback à donner",

        "callToAction": "JE M’EXPRIME",

        "status": "in review"

      },

      "achieved": {

        "subtitle": " Mes accomplissements et tous mes Thanks ",

        "opportunities": "Mes réalisations",

        "callToAction": "J’APPRECIE",

        "status": "achieved"

      }

    },

    // Ajout des nouvelles traductions pour le menu d'action

    actionMenu: {

      addMission: "Créer une mission",

      shareIdea: "Partager une idée",

      inviteTalent: "Inviter un talent",

      createCollabSpace: "Créer un Collab Space"

    },

    "newIdeaView": {

      "title": "Titre\nde votre idée",

      "chooseCollabSpace": "Choisir un Collab Space",

      "addToProject": "Ajouter à un projet",

      "describeIdea": "Parlez-nous de votre idée \et quand voulez-vous la voir prendre vie",

      "whyImportant": " Pourquoi est-il important de la réaliser ? ",

      "whenToLife": "Quand voulais-vous\nvoir l'idée\nprendre vie ?",

      "provideBrief": " Présentez votre idée de manière concise ",

      "shareIdea": "Partager l'idée",

      "saveForLater": "Sauvegarder",

      "addResource": "Illustrer à l'aide d'un lien externe",

      "resourceLink": "Lien vers votre document",

      "fillFields": "Merci de remplir tous les champs requis",

      "selectCollabSpace": "Merci de sélectionner un Collab Space"

    },

    "newAchievementsView": {

      "title": "Titre de votre mission",

      "chooseCollabSpace": "Choisir un Collab Space",

      "describeAchievement": " Présentez-nous votre mission ",

      "whyImportant": "Que voulez-vous mettre en avant\ndans cette mission et pourquoi\nest-ce important ?",

      "whenToLife": " Date de fin?",

      "provideBrief": "Partagez une description claire et concise",

      "makeItHappen": "Créer",

      "saveForLater": "Sauvegarder",

      "addResource": "Illustrer à l'aide d'un lien externe",

      "resourceLink": "Lien vers votre document",

      "fillFields": "Merci de remplir tous les champs requis",

      "selectCollabSpace": "Merci de sélectionner un Collab Space",

      "teamValue": {

        "label": "D'après vos coéquipiers, \nquelle valeur cette mission \npourrait-elle créer ?",

        "minimal": "Minimale",

        "moderate": "Modérée",

        "significant": "Significative",

        "exceptional": "Exceptionnelle",

        "descriptions": {

          "minimal": "Peu ou pas de valeur perçue",

          "moderate": "Une certaine valeur perçue",

          "significant": "Une valeur notable",

          "exceptional": "Une valeur élevée"

        }

      },

      "complexity": {

        "label": "Comment évalueriez-vous\nla complexité de\ncette mission ?",

        "simple": "Simple",

        "moderate": "Modérée",

        "complex": "Complexe",

        "highlyComplex": "Très complexe",

        "descriptions": {

          "simple": "Peu d'étapes, effort cognitif minimal",

          "moderate": "Étapes multiples, réflexion modérée ",

          "complex": "Étapes interdépendantes, analyse approfondie",

          "highlyComplex": "Étapes dynamiques, forte mobilisation cognitive"

        }

      },

      "frequency": {

        "label": "Comment évalueriez-vous \nle degré de nouveauté de cette mission\npour vous ?",

        "routine": "Routine",

        "occasional": "Occasionnelle",

        "rare": "Rare",

        "novel": "Inédite",

        "descriptions": {

          "routine": "Aucune nouveauté, apprentissage et créativité absents",

          "occasional": "Faible nouveauté, apprentissage et créativité limités",

          "rare": "Moyenne nouveauté, apprentissage et innovation requis",

          "novel": "Forte nouveauté, apprentissage et créativité indispensables"

        }

      }

    },

    "cooptView": {

      "title": "RITUEL DE COOPTATION",

      "subtitle": "Inviter un talent",

      "chooseCollabSpace": "Choisir un\nCollab Space",

      "sponsoring": {

        "title": "Invitation spéciale",

        "subtitle": "Pour créer\nun Collab Space"

      },

      "whoInvite": "Qui souhaitez-vous inviter ?",

      "form": {

        "firstName": "PRÉNOM",

        "lastName": "NOM",

        "email": "EMAIL",

        "message": "VOTRE MESSAGE PERSONNEL",

        "send": "ENVOYER",

        "saveForLater": "SAUVEGARDER"

      },

      "validation": {

        "required": "merci de remplir tous les champs requis.",

        "validEmail": "Merci de renseigner une adresse email valide.",

        "selectCollabSpace": "Merci de sélectionner un Collab Space pour votre invité"

      }

    },

    "newTalentHub": {

      "title": "INSCRIPTION",

      "subtitle": "Créer votre\nnouveau Collab Space",

      "form": {

        "nameDO": "NOM DU COLLB SPACE",

        "pitch": {

          "label": "Présentez\nvotre Collab Space",

          "placeholder": "Partagez une description claire et concise"

        },

        "whatToDo": {

          "label": "Que voulez-vous\nfaire",

          "placeholder": "Partagez une description claire et concise"

        },

        "whyImportant": {

          "label": "Pourquoi est-ce\nimportant ?",

          "placeholder": "Partagez une description claire et concise"

        },

        "organizationType": "TYPE DE COLLAB SPACE",

        "link": "LIEN (optionnel)",

        "terms": "J'accepte les conditions générales du modèle Thankword\npour les organisations",

        "submit": "CRÉER",

        "creating": "CRÉATION EN COURS..."

      },

      "errors": {

        "creation": "Une erreur est survenue lors de la création",

        "timeout": "La requête a pris trop de temps. Merci de réessayer.",

        "network": "Erreur réseau, merci de réessayer"

      },

      "organizationType": {

        "title": "Choisissez la nature de votre Collab Space",

        "types": {

          "forProfit": "À BUT LUCRATIF",

          "nonProfit": "À BUT NON LUCRATIF",

          "academic": "ACADÉMIQUE",

          "lifestyle": "USAGE PERSONNELLE"

        }

      }

    },

    "feedback": {

      "title": "VALORISATION - FEEDBACK",

      "subtitle": "Donnez votre feedback",

      "form": {

        "brief": "EXPRIMER\nVOTRE FEEDBACK",

        "deliverables": "LIVRABLES",

        "highlight": {

          "label": "Quels aspects de cette réalisation souhaitez-vous mettre en avant,\net pourquoi sont-ils importants ?",

          "placeholder": "Partagez une description claire et concise "

        },

        "improvement": {

          "label": "Que suggéreriez-vous d'améliorer pour la prochaine fois?",

          "placeholder": "Partagez une description claire et concise"

        },

        "teamValue": {

          "label": " D'après vos coéquipiers, \nquelle valeur cette mission \npourrait-elle créer?"

        },

        "quality": {

          "label": "Comment appréciez-vous\nla qualité de\ncette réalisation ?",

          "descriptions": {

            "low": "En dessous des attentes minimales",

            "medium": "Correcte mais peut être améliorée",

            "high": "Répond adéquatement aux attentes",

            " veryhigh": "Dépasse clairement les attentes",

            "exceptional": " Largement au-delà des attentes"

          }

        },

        "reactivity": {

          "label": "Comment percevez-vous\nla réactivité globale\nde l’auteur de la mission?",

          "descriptions": {

            "low": "Réponses tardives ou incohérentes",

            "medium": "Réponses lentes, amélioration possible",

            "high": "Réponses adaptées dans les délais",

            " veryhigh": "Rapide, clair et pertinent",

            "exceptional": "Immédiat, précis et efficace"

          }

        },

        "submit": "PUBLIER"

      },

      "errors": {

        "submission": "Une erreur est survenue. Merci de réessayer.",

        "required": "Merci de remplir tous les champs requis.",

        "highlight": "Merci de remplir le champ 'Quels aspects de cette réalisation souhaitez-vous mettre en avant, et pourquoi sont-ils importants ?'",

        "improvement": "Merci de remplir le champ 'Que suggéreriez-vous d'améliorer pour la prochaine fois ?'",

        "teamValue": "Merci de remplir le champ 'D'après vos coéquipiers, quelle valeur cette mission pourrait-elle créer ?'",

        "quality": "Merci de remplir le champ 'Comment appréciez-vous la qualité de cette réalisation ?'",

        "reactivity": "Merci de remplir le champ 'Comment percevez-vous la réactivité globale de l’auteur de la mission?'",

        "submit": "Merci de cliquer sur 'PUBLIER'"

      },

      "rating": {

        "low": "Faible",

        "medium": "Moyenne",

        "high": "Élevée",

        "exceptional": "Exceptionnelle"

      },

      "teamValue": {

        "descriptions": {

          "minimal": "Peu ou pas de valeur perçue",

          "moderate": "Une certaine valeur perçue",

          "significant": "Une valeur notable",

          "exceptional": "Une valeur élevée"

        }

      },

      "quality": {

        "descriptions": {

          "low": "En dessous des attentes minimales",

          "medium": "Correcte mais peut être améliorée",

          "high": "Répond adéquatement aux attentes",

          " veryhigh": "Dépasse clairement les attentes",

          "exceptional": "Largement au-delà des attentes"

        },

        "label": "Comment appréciez-vous\nla qualité de\ncette réalisation ?"

      },

      "reactivity": {

        "descriptions": {

          "low": "Réponses tardives ou incohérentes",

          "medium": "Réponses lentes, amélioration possible",

          "high": "Réponses adaptées dans les délais",

          " veryhigh": "Rapide, clair et pertinent",

          "exceptional": "Immédiat, précis et efficace"

        },

        "label": "Comment percevez-vous\nla réactivité globale\nde l’auteur de la mission ?"

      },

      "ratingTeamValue": {

        "low": "Minimale",

        "medium": "Modérée",

        "high": "Significative",

        "exceptional": "Exceptionnelle"

      },

      "ratingQuality": {

        "low": "Insuffisante",

        "medium": "Moyenne",

        "high": " Satisfaisante",

        "veryhigh": "Très Bonne",

        "exceptional": "Exceptionnelle"

      },

      "ratingReactivity": {

        "low": "Insuffisante",

        "medium": "Moyenne",

        "high": " Satisfaisante",

        "veryhigh": "Très Bonne",

        "exceptional": "Exceptionnelle"

      }

    },

    "ideaFeedback": {

      "form": {

        "feeling": {

          "label": "Mon ressenti sur cette idée",

          "placeholder": "Partagez une description claire et concise"

        },

        "contribution": {

          "label": " De quelle manière et à quel moment\nje prévois de contribuer?",

          "optional": "(optionnel)",

          "placeholder": "Partagez une description claire et concise"

        },

        "buttons": {

          "approve": "OUI, POUR",

          "reject": "NON MERCI !"

        }

      },

      "labels": {

        "pitch": "IDEE",

        "alreadyFeedback": " Merci, votre feedback a bien été pris en compte pour cette idée"

      }

    },

    "ideaView": {

      "labels": {

        "pitch": "IDEE",

        "feedback": "FEEDBACK",

        "thankward": "Thankward by MKIF"

      },

      "feedback": {

        "contribution": "Contribution",

        "unknownFirstName": "Prénom inconnu",

        "unknownLastName": "Nom inconnu"

      }

    },

    "editIdea": {

      "title": {

        "placeholder": "Titre\nde l’idée"

      },

      "form": {

        "describe": {

          "label": "Décrivez votre idée",

          "placeholder": "Partagez une description claire et concise"

        },

        "importance": {

          "label": "Pourquoi votre idée est importante ?",

          "placeholder": "Partagez une description claire et concise"

        },

        "calendar": {

          "question": "Quand voulais-vous\nvoir l'idée\nprendre vie ?"

        },

        "resource": {

          "label": "Illustrer à l'aide d'un lien externe",

          "placeholder": "Lien vers votre document"

        },

        "buttons": {

          "share": "Partager l'idée",

          "save": "Sauvegarder"

        }

      }

    },

    "opportunityView": {

      "labels": {

        "brief": "BRIEF",

      },

      "buttons": {

        "iWantIt": "Je m’en occupe"

      },

      "errors": {

        "unknownFirstName": "Prénom inconnu",

        "unknownLastName": "Nom inconnu"

      }

    },

    "draftView": {

      "labels": {

        "brief": "BRIEF"

      },

      "errors": {

        "unknownFirstName": "Prénom inconnu",

        "unknownLastName": "Nom inconnu"

      }

    },

    "percentageViz": {

      "yes": "Oui",

      "no": "Non"

    },

    "header": {

      "logout": "Quitter"

    },
    "card": {
      "shareIdea": "PARTAGER L'IDÉE",
      "myFeedback": "MON FEEDBACK",
      "soon": "Pas de Thanks",
      "days": "J",
      "hours": "H",
      "minutes": "M",
      "seconds": "S"
    },
    "filterView": {
      "byCollabSpace": "Par Collab Space",
      "byStatus": "Par Statut",
      "allCollabSpaces": "Tous mes collab spaces",
      "onGoing": "En cours",
      "open": "Ouvert",
      "feedback": "Feedback",
      "achieved": "Réalisé"
    }
  }
}

export default translations; 