import React, { useState, useEffect, forwardRef } from "react";
import { useTranslation } from 'react-i18next';
import mourad from "../../../images/MK.png";
import guillaume from "../../../images/GH.png";
import oussman from "../../../images/OD.png";
import romain from "../../../images/RL.png";

import heart from "../../../images/heart2.png";

const FilterStatusCards = forwardRef(
  ({ handleCardClick, isSplitView, setStatus }, ref) => {
    const { t } = useTranslation();
    const [shouldScroll, setShouldScroll] = useState(false);

    const STATUSES = {
      ONGOING: "On going",
      OPEN: "Open",
      IN_REVIEW: "In review",
      ACHIEVED: "Achieved",
      DRAFT_IDEA: "Draft idea",
      ONGOING_IDEA: "On going idea",
      ACHIEVED_IDEA: "Achieved idea"
    };

    const cardsData = [
      {
        id: 1,
        title: t('filterStatusCards.collabSpace'),
        subtitle: t('filterStatusCards.onGoing.subtitle'),
        opportunities: t('filterStatusCards.onGoing.opportunities'),
        heartCount: 2,
        date: "3 J",
        profilePics: [mourad],
        profilNames: ["Mourad"],
        bgColors: ["bg-orange", "bg-orange1", "bg-orange2", "bg-orange3"],
        callToAction: t('filterStatusCards.onGoing.callToAction'),
        totalThanks: "1260",
        status: "On going",
        displayStatus: "On going"
      },
      {
        id: 2,
        title: t('filterStatusCards.collabSpace'),
        subtitle: t('filterStatusCards.open.subtitle'),
        opportunities: t('filterStatusCards.open.opportunities'),
        heartCount: 150,
        date: "6 J",
        profilePics: [oussman],
        profilNames: ["Oussman"],
        bgColors: ["bg-softGreen", "bg-softGreen1", "bg-softGreen2", "bg-softGreen3"],
        callToAction: t('filterStatusCards.open.callToAction'),
        totalThanks: "34260",
        status: "Open",
        displayStatus: "Open"
      },
      {
        id: 3,
        title: t('filterStatusCards.collabSpace'),
        subtitle: t('filterStatusCards.inReview.subtitle'),
        opportunities: t('filterStatusCards.inReview.opportunities'),
        heartCount: 6,
        date: "2 J",
        profilePics: [mourad, romain],
        profilNames: ["Mourad", "Romain"],
        bgColors: ["bg-aqua", "bg-aqua1", "bg-aqua2", "bg-aqua3"],
        callToAction: t('filterStatusCards.inReview.callToAction'),
        totalThanks: "8400",
        status: "In review",
        displayStatus: "In review"
      },
      {
        id: 4,
        title: t('filterStatusCards.collabSpace'),
        subtitle: t('filterStatusCards.achieved.subtitle'),
        opportunities: t('filterStatusCards.achieved.opportunities'),
        heartCount: "860",
        date: "28 10",
        profilePics: [guillaume, mourad],
        profilNames: ["Guillaume", "Mourad"],
        bgColors: ["bg-gains", "bg-gains1", "bg-gains2", "bg-gains3"],
        callToAction: t('filterStatusCards.achieved.callToAction'),
        totalThanks: "24000",
        status: "Achieved",
        displayStatus: "Achieved"
      }
    ];

    useEffect(() => {
      if (shouldScroll && ref.current) {
        ref.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        setShouldScroll(false);
      }
    }, [shouldScroll, ref]);

    return (
      <div
        ref={ref}
        className={`
          hidden large:flex large:flex-wrap 
          ${isSplitView 
            ? "w-[94%] max-w-[1076px] mx-auto px-4 extraLarge:px-0 extraLarge:mr-2 large:px-0" 
            : "extraLarge:w-[100%] w-[84%] ml-20 extraLarge:ml-0"
          } 
          ${isSplitView
            ? "justify-center gap-14 large:justify-between large:gap-4"
            : "justify-around large:justify-around extraLarge:justify-between"
          }
          cursor-pointer
          mt-20
        `}
      >
        {cardsData.map((card) => (
          <div
            key={card.id}
            onClick={() => {
              handleCardClick(card.status);
              setStatus(card.status);
              setShouldScroll(true);
            }}
            className={`relative mb-32 ${
              isSplitView
                ? "w-[106px] large:w-[calc(25%-1rem)] "
                : "small:ml-28 large:m-0 w-1/2 medium:w-1/3 large:w-1/6 justify-center"
            }`}
          >
            {card.bgColors.map((bgColor, index) => (
              <div
                key={index}
                className={`absolute rounded-[1rem] extraLarge:rounded-[1.3rem] ${isSplitView ? "w-[106px] h-[150px] extraLarge:w-[116px] extraLarge:h-[160px]" : "extraLarge:w-[172px] extraLarge:h-[230px] w-[140px] h-[180px]"} border-2 border-white ${bgColor} ${
                  isSplitView
                    ? index === 0
                      ? "left-[30px] extraLarge:left-[40px]"
                      : index === 1
                        ? "left-[15px] extraLarge:left-[20px]"
                        : index === 2
                          ? "left-[0px] extraLarge:left-[0px]"
                          : "-left-[15px] extraLarge:-left-[20px]"
                    : index === 0
                      ? "left-[48px] extraLarge:left-[60px]"
                      : index === 1
                        ? "left-[24px] extraLarge:left-[30px]"
                        : index === 2
                          ? "left-[0px] extraLarge:left-[0px]"
                          : "-left-[24px] extraLarge:left-[-30px]"
                }`}
              />
            ))}
            {card.id === 4 ? (
              <div className="relative">
                {card.bgColors.map((bgColor, index) => (
                  <div
                    key={index}
                    className={`absolute rounded-[1rem] extraLarge:rounded-[1.3rem]  border-2 border-white ${bgColor} ${isSplitView ? "w-[106px] h-[150px] extraLarge:w-[116px] extraLarge:h-[160px]" : "w-[140px] h-[180px] extraLarge:w-[172px] extraLarge:h-[230px]"} border-2`}
                  />
                ))}
                <div
                  className={`relative bg-gains3 border-r-2 border-white rounded-[1rem] extraLarge:rounded-[1.3rem] ${isSplitView ? "p-4 w-[106px] h-[150px] -left-[15px] extraLarge:-left-[20px] extraLarge:w-[116px] extraLarge:h-[160px]" : "extraLarge:w-[172px] extraLarge:h-[230px] extraLarge:p-6 p-4 w-[140px] h-[180px] extraLarge:left-[-30px] -left-[24px]"}`}
                >
                  <div
                    className={`${isSplitView ? "w-[60px] h-[28px] rounded-[10px] extraLarge:w-[65px] extraLarge:h-[31px]" : "w-[82px] h-[40px] extraLarge:w-[100px] extraLarge:h-[50px] rounded-[12px]"} flex items-center justify-center bg-stone-950   mx-auto my-2`}
                  >
                    <p
                      className={`${isSplitView ? "text-lg extraLarge:text-xl" : "text-3xl extraLarge:text-4xl"} text-gains3  text-center font-silka-mono`}
                    >
                      {card.heartCount}
                    </p>
                    <img
                      className={`${isSplitView ? "w-4 h-4 extraLarge:w-5 extraLarge:h-5" : "w-4 h-4 extraLarge:w-5 extraLarge:h-5"} 'p-1 extraLarge:p-2'`}
                      alt="Thanks"
                      src={heart}
                    />
                  </div>
                  <h3
                    className={`${isSplitView ? "text-[6px] extraLarge:text-[8px]" : "text-[8px] extraLarge:text-[10px]"} font-silka-mono pt-1 mt-2 extraLarge:pt-2 extraLarge:mt-3`}
                  >
                    {card.title}
                  </h3>
                  <p
                    className={`
                      font-silka-mono 
                      font-semibold 
                      overflow-hidden
                      ${isSplitView 
                        ? "text-[8px] extraLarge:text-[10px]" 
                        : "text-[10px] extraLarge:text-[12px]"
                      }
                    `}
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: "vertical",
                      height: isSplitView ? "2.2rem" : "3rem",
                      lineHeight: isSplitView ? "0.7rem" : "1rem",
                    }}
                  >
                    {card.subtitle}
                  </p>
                  {/* <div className="flex items-center space-x-2">
                    {card.profilNames.map((name, index) => (
                      <div key={index} className="flex items-center mt-1">
                        <img
                          src={card.profilePics[index]}
                          alt={`Profile ${index}`}
                          className={`rounded-full ${isSplitView ? "w-3 h-3" : "w-4 h-4"}`}
                        />
                        <span
                          className={`${isSplitView ? "text-[5px]" : "text-[6px]"} font-silka-mono pt-1 pl-1`}
                        >
                          {name}
                        </span>
                        {index < card.profilNames.length - 1 && (
                          <span
                            className={`${isSplitView ? "text-[8px]" : "text-[10px]"} font-silka-mono`}
                          >
                            {" "}
                            &gt;{" "}
                          </span>
                        )}
                      </div>
                    ))}
                  </div> */}
                </div>
                <div className={`${isSplitView ? "w-[120px]" : "extraLarge:w-[172px] w-[140px]"}`}>
                  <p
                    className={`${isSplitView ? "text-[10px] mt-[10px] extraLarge:text-[12px] " : "text-xs mt-4 extraLarge:text-sm extraLarge:mt-8 transform -translate-x-2 -translate-y-2"}  font-silka-mono `}
                  >
                    {card.opportunities}
                  </p>
                </div>
                <div className="flex justify-start mt-4">
                  {/* <img className={`${isSplitView ? 'w-4 h-4': 'w-6 h-6'} 'p-1' mr-2`} alt="Thanks" src={heart} /> 
                            <p className="text-sm font-silka-mono">{card.totalThanks}</p> */}
                </div>
              </div>
            ) : (
              <div
                className={`relative ${card.bgColors[3]} border-r-2 extraLarge:border-r-3 border-white rounded-[1rem] extraLarge:rounded-[1.3rem] ${isSplitView ? "p-4 w-[106px] h-[150px]  extraLarge:w-[116px] extraLarge:h-[160px] -left-[15px] extraLarge:-left-[20px]" : "p-4 extraLarge:p-6 extraLarge:w-[172px] extraLarge:h-[230px] w-[140px] h-[180px] extraLarge:left-[-30px] -left-[24px]"}`}
              >
                <div className="flex justify-between">
                  <div
                    className={`${isSplitView ? "h-[25px] w-[8px] text-[6px]" : "h-[28px] w-[10px] text-[6.5px] extraLarge:h-[34px] extraLarge:w-[20px] extraLarge:text-[8px]"} font-silka-mono flex items-center justify-center  text-white bg-stone-950 rounded-[5px] pl-2 pr-2 `}
                  >
                    {card.date.split(" ")[0]} <br /> {card.date.split(" ")[1]}
                  </div>
                  <div className="relative">
                    <img
                      alt="thanks"
                      className={`${isSplitView ? "w-2 h-2" : "w-3 h-3 extraLarge:w-4 extraLarge:h-4"} mx-auto block`}
                      src={heart}
                    />
                    {/* <p className="font-silka-mono text-[7px] text-gray-500 text-center mt-1">{card.heartCount}</p> */}
                  </div>
                </div>
                <h3 className={`${isSplitView ? "extraLarge:text-[8px]" : "extraLarge:text-[10px]" } font-silka-mono text-[8px] pt-1 mt-4  extraLarge:pt-2 extraLarge:mt-5`}>
                  {card.title}
                </h3>
                <p
                  className={`
                    font-silka-mono 
                    font-semibold 
                    overflow-hidden
                    ${isSplitView 
                      ? "text-[8px] extraLarge:text-[10px]" 
                      : "text-[10px] extraLarge:text-[12px]"
                    }
                  `}
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                    height: isSplitView ? "2.2rem" : "3rem",
                    lineHeight: isSplitView ? "0.7rem" : "1rem",
                  }}
                >
                  {card.subtitle}
                </p>
                {/* <div className="flex items-center space-x-2">
                  {card.profilNames.map((name, index) => (
                    <div key={index} className="flex items-center mt-1">
                      <img
                        src={card.profilePics[index]}
                        alt={`Profile ${index}`}
                        className={`rounded-full ${isSplitView ? "w-3 h-3" : "w-4 h-4"}`}
                      />
                      <span
                        className={`${isSplitView ? "text-[5px]" : "text-[6px]"} font-silka-mono pt-1 pl-1`}
                      >
                        {name}
                      </span>
                      {index < card.profilNames.length - 1 && (
                        <span
                          className={`${isSplitView ? "text-[8px]" : "text-[10px]"} font-silka-mono`}
                        >
                          &gt;
                        </span>
                      )}
                    </div>
                  ))}
                </div> */}
                <p
                  className={`${isSplitView ? "text-[6.8px] mt-2 font-semibold" : "text-[8px] mt-3 font-bold extraLarge:text-xs extraLarge:mt-5"} font-silka-mono  underline`}
                >
                  {card.callToAction}
                </p>
                <div className="extraLarge:w-[172px] w-[140px]">
                  <p
                    className={`${isSplitView ? "text-[10px] mt-9 extraLarge:text-[12px]" : "text-xs mt-10 extraLarge:text-sm extraLarge:mt-[70px]"} font-silka-mono`}
                  >
                    {card.opportunities}
                  </p>
                </div>
                <div className="flex justify-start mt-4">
                  {/* <img className={`${isSplitView ? 'w-4 h-4': 'w-6 h-6'} 'p-1' mr-2`} alt="Thanks" src={heart} /> 
                            <p className="text-sm font-silka-mono">{card.totalThanks}</p> */}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  },
);

export default FilterStatusCards;
