import React from "react";
import heart from "../../../images/heart2.png";
import ideaIcon from "../../../images/icones/ideaLamp.svg"
import IdeaCountdown from '../../../Components/IdeaCountdown';
import { useTranslation } from 'react-i18next';

const Card = ({
  date,
  title,
  users,
  thanks,
  actionText,
  organisation,
  bgColor,
  isSplitView,
  profilePics,
  id,
  status,
  creation_date,
}) => {
  
  const usersList = users?.split(" > ");
  const { t } = useTranslation();

  const getDateByStatus = (status, date) => {
      if (status === "In review") {
        return "3 J";
    } else if (status === "On going idea") {
        return "21 J";
    } else {
        return date
    }
  }
  console.log(organisation, "organisation")
  return id !== 3 ? (
    <div
      className={`
        ${isSplitView 
          ? "w-[200px] h-[300px] extraLarge:w-full extraLarge:h-[360px] pt-[30px] pl-[25px] pr-[25px] extraLarge:pt-[40px] extraLarge:pl-[35px] extraLarge:pr-[35px] rounded-[2.5rem] extraLarge:rounded-[3rem]" 
          : "w-[300px] h-[410px] extraLarge:w-full extraLarge:h-[490px] pt-[42px] pl-[34px] pr-[34px] extraLarge:pt-[50px] extraLarge:pl-[46px] extraLarge:pr-[46px] rounded-[3.2rem] extraLarge:rounded-[3.5rem]"
        } 
        ${status === "Draft idea" ? "bg-orange3" : bgColor}  
         cursor-pointer
      `}
    >
      <div className="flex justify-between">
        {(status === "On going idea" || status === "Achieved idea") ? (
          <>
            {status === "Achieved idea" ? (
              <>
                <IdeaCountdown creationDate={creation_date} isSplitView={isSplitView} displayMode="compact" />
                <div className={`
                  ${isSplitView 
                    ? "text-[10px] h-[38px] w-[24px] p-1 pt-2 pb-2 extraLarge:w-[28px] extraLarge:h-[44px] extraLarge:p-1 extraLarge:pt-2 extraLarge:pb-2" 
                    : " h-[50px] extraLarge:h-[60px] extraLarge:w-[36px] w-[32px] p-1"
                  } 
                  bg-black rounded-lg flex items-center justify-center 
                `}>
                  <img
                    alt="ideaIcon"
                    className={`${isSplitView ? "w-6 h-6 extraLarge:w-8 extraLarge:h-8" : "w-full h-full extraLarge:w-[40px] extraLarge:h-[40px]"}`}
                    src={ideaIcon}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="flex items-start space-x-2">
                  <IdeaCountdown creationDate={creation_date} isSplitView={isSplitView} displayMode="compact" />
                  <div className={`
                    ${isSplitView 
                      ? "text-[10px] h-[38px] w-[22px] p-1 pt-2 pb-2 extraLarge:text-[14px] extraLarge:w-[28px] extraLarge:h-[44px] extraLarge:p-1 extraLarge:pt-2 extraLarge:pb-2" 
                      : " h-[50px] extraLarge:h-[60px] extraLarge:w-[36px] w-[32px] p-1"
                    } 
                    bg-black rounded-lg flex items-center justify-center
                  `}>
                    <img
                      alt="ideaIcon"
                      className={`${isSplitView ? "w-6 h-6 extraLarge:w-8 extraLarge:h-8" : "w-full h-full extraLarge:w-[60px] extraLarge:h-[60px]"}`}
                      src={ideaIcon}
                    />
                  </div>
                </div>
                <img
                  alt="thanks"
                  className={`${isSplitView ? "w-4 h-4 extraLarge:w-6 extraLarge:h-6" : "w-6 h-6 extraLarge:w-8 extraLarge:h-8"}`}
                  src={heart}
                />
              </>
            )}
          </>
        ) : (
          <>
            {status !== "Achieved" && date && (
              <div
                className={`${isSplitView ? "text-[10px] w-[26px] h-[42px] pl-1 pr-1 mb-2 large:pl-3 large:pr-3 extraLarge:text-[14px] extraLarge:w-[28px] extraLarge:h-[44px] extraLarge:pl-3 extraLarge:pr-3 extraLarge:mb-4" : "text-xs extraLarge:text-sm w-[32px] h-[50px] extraLarge:h-[60px] extraLarge:w-[36px] p-1 pl-3 pr-3 extraLarge:p-2 extraLarge:pl-4 extraLarge:pr-4 mb-6 extraLarge:mb-10"} flex items-center justify-center text-white bg-stone-950 rounded-lg`}
              >
                {getDateByStatus(status, date)}
              </div>
            )}
            {status !== "Achieved" && !date && (
              <div
                className={`${isSplitView ? "text-[10px] w-[26px] h-[42px] pl-1 pr-1 mb-2 extraLarge:text-[14px] extraLarge:w-[28px] extraLarge:h-[44px] large:pl-3 large:pr-3 extraLarge:mb-4" : "text-xs extraLarge:text-lg w-[32px] h-[50px] extraLarge:h-[60px] extraLarge:w-[36px] p-1 pl-3 pr-3 extraLarge:p-2 extraLarge:pl-4 extraLarge:pr-4 mb-6 extraLarge:mb-10"} flex items-center justify-center  text-white  bg-${bgColor} rounded-lg`}
              ></div>
            )}
            {status !== "Achieved" && status !== "Open idea" && status !== "On going idea" && (
              <div>
                {status === "Achieved idea" || status === "Draft idea" ? (
                  <div className={`
                    ${isSplitView 
                      ? "text-[10px] h-[38px] w-[22px] p-1 pt-2 pb-2 extraLarge:text-[14px] extraLarge:w-[28px] extraLarge:h-[44px] extraLarge:p-1 extraLarge:pt-2 extraLarge:pb-2" 
                      : " h-[50px] extraLarge:h-[60px] extraLarge:w-[36px] w-[32px] p-1"
                    } 
                    bg-black rounded-lg flex items-center justify-center
                  `}>
                    <img
                      alt="ideaIcon"
                      className={`${isSplitView ? "w-4 h-4 extraLarge:w-6 extraLarge:h-6" : "w-8 h-8 extraLarge:w-full extraLarge:h-full"}`}
                      src={ideaIcon}
                    />
                  </div>
                ) : (
                  <img
                    alt="thanks"
                    className={`${isSplitView ? "w-4 h-4 extraLarge:w-6 extraLarge:h-6" : "w-6 h-6 extraLarge:w-8 extraLarge:h-8"}`}
                    src={heart}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>
      <div
        className={`${
          isSplitView 
            ? "w-[102px] h-[56px] extraLarge:w-[114px] extraLarge:h-[60px] extraLarge:mt-[10px]" 
            : "w-[140px] h-[70px] extraLarge:w-[150px] extraLarge:h-[75px] extraLarge:mt-[10px]"
        } flex items-center justify-center rounded-[12px] mx-auto my-2 ${
          status === "Achieved" || status === "Achieved idea" ? "bg-stone-950" : "bg-transparent"
        }`}
      >
        {(status === "Achieved" || status === "Achieved idea") && (
          <>
            <p className={`
              ${thanks === t('card.soon') || thanks === "Soon" || thanks === "Bientôt"
                ? "text-xs extraLarge:text-base"
                : isSplitView 
                  ? "text-2xl extraLarge:text-2xl" 
                  : "text-3xl extraLarge:text-3xl"
              }
              text-gains3 
              text-center 
              font-silka-mono
            `}>
              {thanks || t('card.soon')}
            </p>
            <img
              className={`${
                isSplitView 
                  ? "w-8 h-8 p-1 extraLarge:w-10 extraLarge:h-10 extraLarge:p-2" 
                  : "w-9 h-9 p-1 extraLarge:w-12 extraLarge:h-12 extraLarge:p-2"
              }`}
              alt="Thanks"
              src={heart}
            />
          </>
        )}
      </div>
      
      <h3
        className={`${isSplitView ? "text-[8px] pt-4 extraLarge:text-[10px] extraLarge:pt-5" : "text-xs pt-6 extraLarge:text-sm extraLarge:pt-8"} font-silka-mono`}
      >
        {organisation}
      </h3>
      <p
        className={`
          ${isSplitView ? "text-xs extraLarge:text-base large:min-h-[3rem]  extraLarge:min-h-[4rem]" : "text-lg extraLarge:text-xl   extraLarge:min-h-[4.5rem]"}
          font-silka-mono
          font-semibold
          pt-2
          overflow-hidden
          line-clamp-2
          min-h-[3rem]
          leading-normal
        `}
      >
        {title}
        {title?.length > 50 && "..."}
      </p>

      <div className={`${isSplitView ? "mt-0 extraLarge:mt-2" : "mt-2 extraLarge:mt-2" } flex items-center space-x-2`}>
        {usersList?.map((user, index) => (
          <React.Fragment key={index}>
            {profilePics[index] && (
              <img
                src={profilePics[index]}
                alt={user}
                className={`${isSplitView ? "w-6 h-6 extraLarge:w-8 extraLarge:h-8" : "w-8 h-8 extraLarge:w-10 extraLarge:h-10"} rounded-full`}
              />
            )}
            <p
              className={`${isSplitView ? "text-[8px] extraLarge:text-[10px]" : "text-[10px] extraLarge:text-sm"} font-silka-mono`}
            >
              {user}
            </p>
            {index < usersList.length - 1 && <span> &gt; </span>}
          </React.Fragment>
        ))}
      </div>

      <p
        className={`${isSplitView ? "text-xs mt-2" : "text-sm mt-4 extraLarge:text-base"} font-silka-mono underline`}
      >
        {status === "Draft idea" ? "SHARE YOUR IDEA" : status === "On going idea" ? "My Feedback" : actionText}
      </p>

    </div>
  ) : (null);
};

export default React.memo(Card, (prevProps, nextProps) => {
  return (
    prevProps.organisation === nextProps.organisation &&
    prevProps.isSplitView === nextProps.isSplitView &&
    prevProps.status === nextProps.status
  );
});

