import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import logo from "../../../images/logo.png";
import invite from "../../../images/icones/invite.svg";
import useStore from "./../../../API/store";
import BackArrow from '../../../Components/BackArrow';

const CooptationForm = ({ handleGoBack, dioData, setSelectedStatus, setPasswordModal }) => {
  const { t } = useTranslation();
  const {
    setLoading,
    fetchIdeas,
    setError,
    cooptTalent
  } = useStore();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const [selectedOrg, setSelectedOrg] = useState(null);
  const [creatOrg, setCreatOrg] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      alert(t('cooptView.validation.required'));
      return;
    }

    try {
      const selectedDio = dioData.find(dio => dio.name === selectedOrg);
      if (!selectedDio && selectedOrg !== "Sponsoring") {
        throw new Error(t('cooptView.validation.selectCollabSpace'));
      }

      const dioId = selectedOrg === "Sponsoring" ? "sponsoring" : selectedDio.id;

      await cooptTalent(
        formData.firstName,
        formData.lastName,
        formData.email,
        formData.message,
        dioId
      );

      console.log("Cooptation successful!");
      setPasswordModal(true);
      handleGoBack();
      setSelectedStatus("all");
    } catch (error) {
      setError(error.message);
      console.error("Cooptation error:", error);
    }
  };
  

  const handleSaveForLater = () => {
    console.log("Saved for later:", { organization: selectedOrg, ...formData });
  };

  console.log(selectedOrg, "<======= selectedOrg");
  // Fonction pour vérifier si l'email est valide
const validateEmail = (email) => {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
};
  const validateForm = () => {
    // Vérifier que le prénom, nom, email, et message ne sont pas vides
    const isFormValid =
      formData.firstName.trim() !== "" && // Le prénom ne doit pas être vide
      formData.lastName.trim() !== "" && // Le nom ne doit pas être vide
      formData.email.trim() !== "" && // L'email ne doit pas être vide
      formData.message.trim() !== "" && // Le message ne doit pas être vide
      selectedOrg !== null && // Une organisation doit être sélectionnée
      validateEmail(formData.email); // Vérifier que l'email est valide
  
    return isFormValid;
  };

  return (
    <div className="w-full bg-rightGreyBg p-8 medium:p-32 pb-32 overflow-y-auto max-h-[calc(110vh-64px)]">
      <div className="mb-20">
        <BackArrow onClick={handleGoBack} />
        <div className="text-xs font-silka-mono uppercase tracking-wide mb-5 mt-10">
          {t('cooptView.title')}
        </div>
        <h1 className="text-4xl font-silka-mono font-bold mb-6">
          {t('cooptView.subtitle')}
        </h1>
      </div>

      <div className="mb-12">
        <h2 className="text-lg mb-4 font-silka-mono font-semibold whitespace-pre-line">
          {t('cooptView.chooseCollabSpace')}
        </h2>
        <div className="grid grid-cols-2 medium:grid-cols-3 gap-4">
          <button
            className={`p-4 border-2 rounded-lg flex flex-col items-center justify-center aspect-square ${
              selectedOrg === "Sponsoring" ? "border-black font-semibold" : "border-gray-400"
            }`}
            onClick={() => setSelectedOrg("Sponsoring")}
          >
            <img src={invite} alt="invite" className="max-w-10 max-h-10 cursor-pointer mb-4" />
            <div className="text-center">
              <span className="font-silka-mono text-xs whitespace-pre-line">
                {t('cooptView.sponsoring.title')}
                <p className="leading-tight whitespace-pre-line">{t('cooptView.sponsoring.subtitle')}</p>
              </span>
            </div>
          </button>
          {dioData.map((item, index) => (
            <button
              key={item.id || index}
              className={`p-4 border-2 rounded-lg flex flex-col items-center justify-center aspect-square ${
                selectedOrg === item.name
                  ? "border-black font-semibold"
                  : "border-gray-400"
              }`}
              onClick={() => setSelectedOrg(item.name)}
            >
              <img
                src={logo}
                alt="retour"
                className="max-w-10 max-h-10 cursor-pointer mb-4"
              />
              <span className="font-silka-mono text-xs">{item.name}</span>
            </button>
          ))}
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-lg mb-4 font-silka-mono font-semibold whitespace-pre-line">
          {t('cooptView.whoInvite')}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4 max-w-[80%]">
          <input
            type="text"
            placeholder={t('cooptView.form.firstName')}
            className="w-full p-4 border-2 border-gray-700 rounded-lg focus:border-black focus:ring-0 focus:outline-none bg-rightGreyBg font-silka-mono"
            value={formData.firstName}
            onChange={(e) =>
              setFormData({ ...formData, firstName: e.target.value })
            }
          />

          <input
            type="text"
            placeholder={t('cooptView.form.lastName')}
            className="w-full p-4 border-2 border-gray-700 rounded-lg focus:border-black focus:ring-0 focus:outline-none bg-rightGreyBg font-silka-mono"
            value={formData.lastName}
            onChange={(e) =>
              setFormData({ ...formData, lastName: e.target.value })
            }
          />

          <input
            type="email"
            placeholder={t('cooptView.form.email')}
            className="w-full p-4 border-2 border-gray-700 rounded-lg focus:border-black focus:ring-0 focus:outline-none bg-rightGreyBg font-silka-mono"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />

          <textarea
            placeholder={t('cooptView.form.message')}
            className="w-full p-4 border-2 border-gray-700 rounded-lg h-40 focus:border-black focus:ring-0 focus:outline-none bg-rightGreyBg font-silka-mono"
            value={formData.message}
            onChange={(e) =>
              setFormData({ ...formData, message: e.target.value })
            }
          />

          <div className="flex gap-4">
            <button
              type="submit"
              className="flex-1 bg-black text-white text-sm py-3 px-6 rounded-full hover:bg-gray-800 transition-colors font-silka-mono max-w-[40%] h-[50px] mb-20"
            >
              {t('cooptView.form.send')}
            </button>

            {/* <button
              type="button"
              onClick={handleSaveForLater}
              className="flex-1 border-2 border-black text-sm py-3 px-6 rounded-full hover:bg-gray-100 transition-colors font-silka-mono outline-none h-[50px] text-center"
            >
              SAVE FOR LATER
            </button> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CooptationForm;
