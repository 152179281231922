import React, { useState , useEffect} from 'react';
import axios from "axios";


import { Link } from "react-router-dom";
import { useContext } from "react";
import { TasksContext } from "../../API/Executions.js";


const Welcome = () => {


  const [currentQuestion, setCurrentQuestion] = useState(0);
  const Name = localStorage.getItem("userName");
  const firstName =localStorage.getItem("firstName");
  const email =localStorage.getItem("email");
  const userId = localStorage.getItem("userId")
  const do_id = localStorage.getItem("do_ids")

  const [Email, setEmail] = useState(`${email}`);
  const [FirstName, setFirstName] = useState(`${firstName}`);
  const [LastName, setLastName] = useState(`${Name}`);
  const [Password, setPassword] = useState("");
  const [newPassword, setnewPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [date, setdate] = useState("");
  const [country, setcountry] = useState("");
  const [idDO, setIdDO] = useState("");
  const [Docreator, setDocreator] = useState("");
  const [sponsored, setSponsored] = useState("");


const UserName = localStorage.getItem("userName");
const CeoDoId = localStorage.getItem("userId")


console.log(FirstName, "<=========== FirstName")
console.log(LastName, "<=========== LastName")
console.log(UserName, "<=========== UserName")

const [descriptionDO, setDescriptionDO] = useState("");
const [nameDO, setNameDO] = useState("");
const [typeActivityDO, setTypeActivityDO] = useState("");
const [linkDO,setLinkDO] = useState("");
const [keywordsDO,setKeywordsDO] = useState("");
const [makeitbetterDO,setMakeitbetterDO] = useState("");

const [idDOcreated, setIdDOcreated] = useState("");

const [subjectMail ,setsubjectMail] =useState(`${UserName} invites you to Thankward`);
const [textMail , settextMail] =useState(`Hi ${FirstName},
Join me on Thankward to explore an amazing experience of collaboration.
You'll see, it's easy, and we earn lots of Thanks, the future of appreciation-based rewards.
You'll receive your password in a separate message.
Follow the link below to sign up with your email address: ${Email}
www.thanksandshare.com
See you soon!`);

const [subjectMailexist ,setsubjectMailexist] =useState(`${UserName} invites you to ${nameDO}`);
const [textMailexist , settextMailexist] =useState(`Hi ${FirstName},
Join me at "${nameDO}," an exceptional Digital Organization where you can thrive in a great
team and contribute to an exciting project.
See you soon!"
Achraf
www.thanksandshare.com`);


useEffect(() => {
  // Update subjectMail whenever FirstName or LastName changes
  setsubjectMail(`${UserName} invites you to Thankward`);
  setsubjectMailexist(`${UserName} invites you to ${nameDO}`);

  // Update textMail whenever FirstName or LastName changes
  settextMail(`Hi ${FirstName} ${LastName},
  Join me on Thankward to explore an amazing experience of collaboration.
  You'll see, it's easy, and we earn lots of Thanks, the future of appreciation-based rewards.
  You'll receive your password in a separate message.
  Follow the link below to sign up with your email address: ${Email}
  www.thanksandshare.com
  See you soon!`);
  settextMailexist(`Hi ${FirstName},
  Join me at "${nameDO}," an exceptional Digital Organization where you can thrive in a great
  team and contribute to an exciting project.
  See you soon!"
  www.thanksandshare.com`)
}, [FirstName, LastName, Email, nameDO , UserName]);

  const { selectDioId } = useContext(TasksContext);
  const handleDIOClick = (dioId) => {
    selectDioId(dioId);
  };


  const Next = () => {
    // Check if givenName is empty
    if (FirstName.trim() === '' || LastName.trim() === '') {
      alert('Please enter a valid First Name and Last Name .');
      return;
    }
    setCurrentQuestion(1);
  };

  const VerifyOldPassword = () => {
    // Check if givenName is empty
    if (Password.trim() !== '123456') {
      alert('Invalid password.');
      return;
    }
    setCurrentQuestion(2);
  };

  const VerifyPassword = () => {
    // Check if givenName is empty
    if (newPassword.trim() !== confirmPassword.trim()) {
      alert('Invalid password.');
      return;
    }
    setCurrentQuestion(4);
  };
  const Nextbirthday = () => {
    // Check if givenName is empty
    if (date.trim() === '') {
      alert('Please kindly enter your birthday.');
      return;
    }
    setCurrentQuestion(5);
  };
  const NextCountry = () => {
    // Check if givenName is empty
    if (country.trim() === '') {
      alert('Please kindly enter your adresse.');
      return;
    }
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/cooptTalent/infoCoopt?userId=${userId}`)
    .then(response => {
      const data = response.data;
      setIdDO(data.idDO);
      setDocreator(data[0].Docreator);
      setSponsored(data[0].sponsored);
      console.log(data);
      console.log(data[0].sponsored)
      console.log(data.idDO)

    })
    .catch(error => console.error('Error fetching data:', error));
    setCurrentQuestion(6);
  };


console.log(sponsored)

  const handleconfirmeCoopt = () => {

    const first_connection = '0'
    const userData = {userId, FirstName,LastName, Email, newPassword,first_connection,date,country};

    fetch(process.env.REACT_APP_BACKEND_URL + "/cooptTalent/api/updatecoopt", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    })
      .then((response) => {
        console.log("here");
        console.log(response);
      })
      .catch((error) => {
        console.error("Erreur lors de l'inscription :", error);
      });
      localStorage.setItem("DOId", idDO);
      handleDIOClick(do_id)
  };
const startCreationDO = () => {

  const first_connection = '0'
  const userData = {userId, FirstName,LastName, Email, newPassword,first_connection,date,country};

  fetch(process.env.REACT_APP_BACKEND_URL + "/cooptTalent/api/updatecoopt", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  })
    .then((response) => {
      console.log("here");
      console.log(response);
    })
    .catch((error) => {
      console.error("Erreur lors de l'inscription :", error);
    });
}

  const DescribeDO = () => {
    // Check if givenName is empty
    if (descriptionDO.trim() === '') {
      alert('Please describe your D.O.’s activity.');
      return;
    }
    setCurrentQuestion(currentQuestion + 1);
    window.history.pushState({ question: currentQuestion + 1 }, '');
  };
  
  const NameDO = () => {
    // Check if givenName is empty
    if (nameDO.trim() === '') {
      alert('Please give a name for your D.O.');
      return;
    }
    setCurrentQuestion(currentQuestion + 1);
    window.history.pushState({ question: currentQuestion + 1 }, '');
  }
  
  const NonProfit = () =>{
    setTypeActivityDO('NonProfit');
    setCurrentQuestion(currentQuestion + 1);
    window.history.pushState({ question: currentQuestion + 1 }, '');
  }
  const Profit = () =>{
    setTypeActivityDO('Profit');
    setCurrentQuestion(currentQuestion + 1);
    window.history.pushState({ question: currentQuestion + 1 }, '');
  }
  const Academic = () =>{
    setTypeActivityDO('Academic');
    setCurrentQuestion(currentQuestion + 1);
    window.history.pushState({ question: currentQuestion + 1 }, '');
  }
  
  const LinkDO = () =>{
    setCurrentQuestion(currentQuestion + 1);
    window.history.pushState({ question: currentQuestion + 1 }, '');
  }
  
  const KeywordsDO = () =>{
    setCurrentQuestion(currentQuestion + 1);
    window.history.pushState({ question: currentQuestion + 1 }, '');
  }
  
  const Makeitbetter = () =>{
    setCurrentQuestion(currentQuestion + 1);
    window.history.pushState({ question: currentQuestion + 1 }, '');
  }
  
  useEffect(() => {
    // Update subjectMail whenever FirstName or LastName changes
    setsubjectMail(`${UserName} invites you to Thankward`);
  
    // Update textMail whenever FirstName or LastName changes
    settextMail(`Hi ${FirstName} ${LastName},
    Join me on Thankward to explore an amazing experience of collaboration.
    You'll see, it's easy, and we earn lots of Thanks, the future of appreciation-based rewards.
    You'll receive your password in a separate message.
    Follow the link below to sign up with your email address: ${Email}
    www.thanksandshare.com
    See you soon!`);
  }, [FirstName, LastName , Email , UserName]);
  
  

  const verifyEmail = () => {
      // Basic email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(Email)) {
        alert('Please enter a valid email address.');
        return;
      }
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/CreateSponsorizeDO/infoDOcreated?userId=${CeoDoId}&nameDo=${nameDO}`)
      .then(response => {
        const data = response.data;
        console.log(data);
        // Update idDO and Docreator based on the fetched data
        if (data && data.length > 0) {
          // Update idDO and Docreator based on the fetched data
          setIdDOcreated(data[0].id);
          console.log(2);
        } else {
          console.error('No data received from the server');
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  
      const doId = idDOcreated;
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/cooptTalent/verifyMail?email=${Email}&doId=${doId}`)
      .then(response => {
        // Access data directly from the response object
        const data = response.data;
        console.log(data);
        
        if (data.emailExists) {
          // If email exists
          if (data.talentExists) {
            // If talent also exists in the DO
            alert('This talent is already in the DO.');
          } else {
            // If talent does not exist in the DO, proceed with the current flow
            setFirstName(data.firstName);
            setCurrentQuestion(20);
            window.history.pushState({ question: 20 }, '');
            // Optionally, you can set different state variables or perform other actions here
          }
        } else {
          // If email doesn't exist, proceed with the current flow
          setCurrentQuestion(currentQuestion + 1);
          window.history.pushState({ question: currentQuestion + 1 }, '');
        }
      })
      .catch(error => {
        console.error('Error verifying email:', error);
        // Handle error here, e.g., display an error message to the user
      });
  
  
  
      // If email is valid, proceed to the next question
      setCurrentQuestion(currentQuestion + 1);
      window.history.pushState({ question: currentQuestion + 1 }, '');
    };
  
    const NextFirstname = () => {
      // Check if givenName is empty
      if (FirstName.trim() === '') {
        alert('Please enter the Talent’s First Name.');
        return;
      }
      setCurrentQuestion(currentQuestion + 1);
      window.history.pushState({ question: currentQuestion + 1 }, '');
    };
    const NextLastname = () => {
      // Check if givenName is empty
      if (LastName.trim() === '') {
        alert('Please enter the Talent’s Last Name.');
        return;
      }
  
      setCurrentQuestion(currentQuestion + 1);
      window.history.pushState({ question: currentQuestion + 1 }, '');
    };
    const NextNotification = () => {
      setCurrentQuestion(currentQuestion + 1);
      window.history.pushState({ question: currentQuestion + 1 }, '');
    }
  
  
    useEffect(() => {
      const userId = localStorage.getItem('userId');
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/DIO/dioData/${userId}`)
        .then((res) => {
          const formattedData = res.data.map(item => ({
            id: item.id,
            name: item.nom_do,
            description: item.do_description,
            type: item.type,
            id_ceo: item.id_ceo,
            do_creator: {
              name: item.do_creator_name,
              first_name: item.do_creator_first_name
            },
            member: {
              name: item.member_name,
              email: item.member_email,
              first_name: item.member_first_name,
              phone: item.member_phone,
              address: item.member_adresse
            }
          }));
          console.log(formattedData);
        })
        .catch(error => {
          console.error("Error fetching DIO data:", error);
        });
    }, []);
  
  useEffect(() => {
    const handlePopstate = () => {
      const newState = window.history.state;
      console.log(newState.question)
      if (newState && newState.question !== undefined) {
        if (newState.question === 5) {
          setCurrentQuestion(0);
          console.log('yes') // Reset to question 0 if returning from question 5
        } else {
          setCurrentQuestion(currentQuestion - 1);
        }
      }
    };
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [currentQuestion]);
  
  console.log(idDOcreated)
  
  const handleCreateDO = () => {
    const userData = {nameDO,descriptionDO, CeoDoId , typeActivityDO, linkDO,keywordsDO,makeitbetterDO};
    fetch(process.env.REACT_APP_BACKEND_URL + "/CreateSponsorizeDO/createDO", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    })
      .then((response) => {
        console.log("here");
        console.log(response);
      })
      .catch((error) => {
        console.error("Erreur lors de la creation de DO :", error);
      });
  
  
      setCurrentQuestion(currentQuestion + 1);
      window.history.pushState({ question: currentQuestion + 1 }, '');
  };
  
  const handleCoopt = () => {
    const UserNameId = localStorage.getItem("userId")
    const password = '123456'
    const id_do = idDOcreated
    const first_connection = '1'
    const userData = { FirstName,LastName, Email, password, id_do,first_connection,UserNameId,subjectMail,textMail};
  
    fetch(process.env.REACT_APP_BACKEND_URL + "/cooptTalent/api/coopt", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    })
      .then((response) => {
        console.log("here");
        console.log(response);
      })
      .catch((error) => {
        console.error("Erreur lors de l'inscription :", error);
      });
      setCurrentQuestion(21);
      window.history.pushState({ question: 21}, '');
  };

  const CooptExsistedMember = () => {
    const id_do = idDOcreated;
    const UserNameId = localStorage.getItem("userId");
    const userData = { Email, id_do,UserNameId,subjectMailexist,textMailexist};
  
    fetch(process.env.REACT_APP_BACKEND_URL + "/cooptTalent/api/cooptExsistedMember", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    })
      .then((response) => {
        console.log("here");
        console.log(response);
      })
      .catch((error) => {
        console.error("Erreur lors de l'inscription :", error);
      });
  
      setCurrentQuestion(currentQuestion + 1);
      window.history.pushState({ question: currentQuestion + 1 }, '');
  }
  const Cooptagain = () => { 
    const id_membre =  CeoDoId;
    const id_do = idDOcreated;
    const userData_ = {id_membre,id_do};
    fetch(process.env.REACT_APP_BACKEND_URL + "/CreateSponsorizeDO/addDOtoCreator", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData_),
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
        localStorage.setItem("do_ids",data.do_ids);
  
      })
    }
    })
      .catch((error) => {
        console.error("Erreur lors de la creation de DO :", error);
      });
    setCurrentQuestion(15);
    window.history.pushState({ question: 15 }, '');
  
  }


  const FirstQuestion = ()=> (
    <>
      <div className="mt-20">
        <p className="text-center text-2xl md:text-4xl text-gray-800 pb-20">
          Welcome to Thankward.<br />To join{Docreator}, complete your profile
          <br /> and you’ll receive your first Thanks
        </p>
      </div>
      <div className="grid gap-6 m-20">
        <div className="flex items-center">
          <label className="w-1/3 text-lg text-gray-700">Given Name</label>
          <input
            className="ml-auto w-2/3 p-3 border border-gray-300 rounded-lg"
            onChange={(e) => setFirstName(e.target.value)}
            value={FirstName}
            type="text"
          />
        </div>
        <div className="flex items-center">
          <label className="w-1/3 text-lg text-gray-700">Family Name</label>
          <input
            className="ml-auto w-2/3 p-3 border border-gray-300 rounded-lg"
            onChange={(e) => setLastName(e.target.value)}
            value={LastName}
            type="text"
          />
        </div>
        <div className="flex items-center">
          <label className="w-1/3 text-lg text-gray-700">Email</label>
          <input
            className="ml-auto w-2/3 p-3 border border-gray-300 rounded-lg bg-gray-200 cursor-not-allowed"
            readOnly
            value={Email}
          />
        </div>
        <div className="flex flex-row justify-center m-20">
          <button className="bg-red-500 text-white rounded-sm shadow-sm hover:bg-red-700 max-w-xs transition-all w-full flex-1 mr-2">
            Delete and exit
          </button>
          <button className="bg-green-500 text-white rounded-sm shadow-sm hover:bg-green-70 max-w-xs 0 transition-all w-full flex-1 ml-2" onClick={Next}>
            Create My Profile
          </button>
        </div>
      </div>
    </>
  )

  const SecondeQuestion = () => (
    <>
      <p className="m-32 text-center text-2xl md:text-4xl text-gray-800">Great! <br /> Choose your password</p>
      <div className="flex justify-center mt-32">
        <label className="w-1/3 text-lg text-gray-700 pl-2">Password you received</label>
        <input
          className="w-2/3 p-3 border border-gray-300 rounded-lg max-w-md"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={Password}
        />
      </div>
      <button className="block mx-auto mt-10 text-xl bg-blue-500 text-white py-3 px-8 rounded-lg shadow-lg hover:bg-blue-700 transition-all" onClick={VerifyOldPassword}>
        Next
      </button>
    </>
  )

  const ThirdQuestion = () =>(
    <>
      <p className="mt-32 text-center text-2xl md:text-4xl text-gray-800">Great! <br /> Choose your password</p>
      <div className="flex justify-center mt-32">
        <label className="w-1/3 text-lg text-gray-700 pl-2">My new Password</label>
        <input
          className="w-2/3 p-3 border border-gray-300 rounded-lg max-w-md"
          type="password"
          onChange={(e) => setnewPassword(e.target.value)}
          value={newPassword}
        />
      </div>
      <button className="block mx-auto mt-10 text-xl bg-blue-500 text-white py-3 px-8 rounded-lg shadow-lg hover:bg-blue-700 transition-all" onClick={() => setCurrentQuestion(3)}>
        Next
      </button>
    </>
  )

  const FourthQuestion = () => (
    <>
      <p className="mt-32 text-center text-2xl md:text-4xl text-gray-800">Great! <br /> Confirm your password</p>
      <div className="flex justify-center mt-32">
        <label className="w-1/3 text-lg text-gray-700">I confirm my new Password</label>
        <input
          className="w-2/3 p-3 border border-gray-300 rounded-lg max-w-md"
          type="password"
          onChange={(e) => setconfirmPassword(e.target.value)}
          value={confirmPassword}
        />
      </div>
      <button className="block mx-auto mt-10 text-xl bg-blue-500 text-white py-3 px-8 rounded-lg shadow-lg hover:bg-blue-700 transition-all" onClick={VerifyPassword}>
        Next
      </button>
    </>
  )

  const FifthQuestion = () =>(
    <>
      <p className="mt-32 text-center text-2xl md:text-4xl text-gray-800">Excellent! <br /> Almost finished</p>
      <div className="flex justify-center mt-32">
        <label className="w-1/3 text-lg text-gray-700">Your birthday:</label>
        <input
          type="date"
          className="w-2/3 p-3 border border-gray-300 rounded-lg max-w-md"
          onChange={(e) => setdate(e.target.value)}
          value={date}
          max={new Date().toISOString().split("T")[0]}
        />
      </div>
      <button className="block mx-auto mt-10 text-xl bg-blue-500 text-white py-3 px-8 rounded-lg shadow-lg hover:bg-blue-700 transition-all" onClick={Nextbirthday}>
        Next
      </button>
    </>
  )

  const SixthQuestion = () =>(
    <>
      <p className="mt-32 text-center text-2xl md:text-4xl text-gray-800">
        Thank you for your perseverance!<br />Finally
      </p>
      <div className="flex justify-center mt-32">
        <label className="w-1/3 text-lg text-gray-700">Your living country</label>
        <input
          className="w-2/3 p-3 border border-gray-300 rounded-lg max-w-md"
          placeholder="Country"
          onChange={(e) => setcountry(e.target.value)}
          value={country}
        />
      </div>
      <button className="block mx-auto mt-10 text-xl bg-green-500 text-white py-3 px-8 rounded-lg shadow-lg hover:bg-green-700 transition-all" onClick={NextCountry}>
        I want my thanks
      </button>
    </>
  )
  return (
    <div className=" bg-[#333] ">
      <div className="main-content mx-auto p-2 rounded-sm">
        
          {currentQuestion === 0 ? FirstQuestion() : currentQuestion === 1 ? SecondeQuestion() : currentQuestion === 2 ? ThirdQuestion() : currentQuestion === 3 ? FourthQuestion() : currentQuestion === 4 ? FifthQuestion() : currentQuestion === 5 ? SixthQuestion() : currentQuestion === 6 && sponsored === 0 ? (
  <>
    <div className="mt-20 text-center">
      <p className="text-5xl mb-8 coopt-text">
        Congratulations, You get your First Thanks!<br /><br />
        Now, you can join Achraf and gain plenty of Thanks!
      </p>
      <Link to="/Homepage">
        <button onClick={handleconfirmeCoopt} className="bg-blue-500 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-blue-600 transition-all">
          Come in
        </button>
      </Link>
    </div>
  </>
  ) : currentQuestion === 6 ? (
    <>
      <div className="mt-20 text-center">
        <p className="text-4xl mb-8 coopt-text">
          Congratulations, You get your First Thanks!<br /><br />
          Now you can create your Digital Organization to achieve with your community!<br />
          what matters to you, together.<br />
          And yes, there are Rewards for your initiative!
        </p>
        <div className="explain-sponsorization text-left">
          <div className="font-bold text-3xl ml-2 mt-4">In the D.O. your friend is building:</div>
          <div className="ml-2">
            For every 100 Thanks your friend earns, you receive 5 Thanks.<br />
            Each time your friend’s team accumulates 10k verified Thanks, you earn 10 Thanks.<br /><br /><br />
          </div>
          <div className="font-bold text-3xl ml-2">From MKIF, the company behind Thankward:</div>
          <div className="ml-2">
            When your friend’s D.O. is active, you earn 1 Thanks.<br />
            Each time your friend’s team accumulates 10k verified Thanks, you earn 1 Thanks.<br />
            You receive a special bonus of 100 Thanks whenever your friend’s team reaches 100k verified Thanks.
          </div>
        </div>
        <Link to="/CreateDO">
          <button onClick={startCreationDO} className="bg-blue-500 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-blue-600 transition-all mt-8">
            Come in
          </button>
        </Link>
      </div>
    </>
  ) : currentQuestion === 7 ? (
    <>
      <p className="create-do mt-20 text-center">First, describe your D.O.’s activity and purpose</p>
      <textarea className="what-will-you-do w-11/12 h-40 mx-auto block" placeholder="What will you do and why ?"
        onChange={(e) => setDescriptionDO(e.target.value)}
        value={descriptionDO}>
      </textarea>
      <button className="bg-blue-500 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-blue-600 transition-all mt-4 mx-auto block" onClick={DescribeDO}>Next</button>
    </>
  ) : currentQuestion === 8 ? (
    <>
      <p className="create-do mt-20 text-center">What Is the name of your Digital Organization?</p>
      <input className="what-will-you-do w-11/12 h-40 mx-auto block" 
        onChange={(e) => setNameDO(e.target.value)}
        value={nameDO}
        placeholder="Choose your D.O.’s Title" />
      <button className="bg-blue-500 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-blue-600 transition-all mt-4 mx-auto block" onClick={NameDO}>Next</button>
    </>
  ) : currentQuestion === 9 ? (
    <>
      <p className="create-do mt-20 text-center">Who will Lead your D.O.?</p>
      <div className="flex justify-center space-x-10 mt-8">
        <button className="bg-blue-300 text-white h-40 w-40 rounded-lg" onClick={() => setCurrentQuestion(10)}>I will do</button>
        <button className="bg-gray-300 text-white h-40 w-40 rounded-lg">Someone else<span className="text-red-500 text-sm ml-1">Soon</span></button>
      </div>
      <p className="create-do mt-10 text-center">"You can soon make adjustments and refine your governance."</p>
    </>
  ) : currentQuestion === 10 ? (
    <>
      <p className="create-do mt-20 text-center">For what type of activity are you creating this DO?</p>
      <div className="flex justify-center space-x-10 mt-8">
        <button className="bg-blue-300 text-white h-40 w-40 rounded-lg" onClick={NonProfit}>Non-Profit</button>
        <button className="bg-blue-300 text-white h-40 w-40 rounded-lg" onClick={Profit}>Profit</button>
        <button className="bg-blue-300 text-white h-40 w-40 rounded-lg" onClick={Academic}>Academic</button>
      </div>
    </>
  ) : currentQuestion === 11 ? (
    <>
      <p className="create-do mt-20 text-center">Add a link to learn more about your D.O.</p>
      <input className="what-will-you-do w-11/12 h-40 mx-auto block" 
        onChange={(e) => setLinkDO(e.target.value)}
        value={linkDO} 
        placeholder="Website, Pitch Deck on your cloud..." />
      <button className="bg-blue-500 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-blue-600 transition-all mt-4 mx-auto block" onClick={LinkDO}>Next</button>
    </>
  ) : currentQuestion === 12 ? (
    <>
      <p className="create-do mt-20 text-center">Keywords about your activity and needs</p>
      <input className="what-will-you-do w-11/12 h-40 mx-auto block" 
        onChange={(e) => setKeywordsDO(e.target.value)}
        value={keywordsDO}
        placeholder="Values, sector, activities..." />
      <button className="bg-blue-500 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-blue-600 transition-all mt-4 mx-auto block" onClick={KeywordsDO}>Next</button>
    </>
  ) : currentQuestion === 13 ? (
    <>
      <p className="create-do mt-20 text-center">What would it take to make it successful?</p>
      <input className="what-will-you-do w-11/12 h-40 mx-auto block" 
        onChange={(e) => setMakeitbetterDO(e.target.value)}
        value={makeitbetterDO}
        placeholder="Talents, money, luck ☺..." />
      <button className="bg-blue-500 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-blue-600 transition-all mt-4 mx-auto block" onClick={Makeitbetter}>Next</button>
    </>
  ) : currentQuestion === 14 ? (
    <>
      <p className="create-do mt-20 text-center">Congratulations on creating your D.O.! {idDOcreated} Well done!<br />
        To make it active, recruit Talent to help achieve your goals.<br />
        And yes, there are Rewards for Co-opting Talent!</p>
      <div className="explain-do text-left">
        <div className="font-bold text-3xl ml-2 mt-4">In the D.O. for which you are co-opting:</div>
        <div className="ml-2">
          For every 100 Thanks your Co-opted Talent earns, you receive 5 Thanks.<br /><br />
        </div>
        <div className="font-bold text-3xl ml-2">From MKIF, the company behind Thankward:</div>
        <div className="ml-2">
          When your Co-opted Talent joins the first time, you earn 1 Thanks.<br />
          Each time your Co-opted Talent accumulates 1k verified Thanks; you earn 1 Thanks.<br />
          You receive a special bonus of 20 Thanks whenever your Co-opted Talent reaches 10k verified Thanks.
        </div>
      </div>
      <button className="bg-blue-500 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-blue-600 transition-all mt-4 mx-auto block" onClick={handleCreateDO}>Start</button>
    </>
  ) : currentQuestion === 15 ? (
    <>
      <p className="coopt-text mt-20 text-center">Co-opting Talents is an excellent idea; it boosts your Team and accelerates your success.<br /><br /></p>
      <div className="flex justify-center items-center mt-15">
        <div className="flex">
          <div className="mr-2 text-3xl">Email</div>
          <input
            placeholder="Talent’s email please"
            onChange={(e) => setEmail(e.target.value)}
            value={Email}
            className="ml-2 w-60 h-10 border border-gray-300 rounded-lg p-2"
          />
        </div>
        <button className="bg-blue-500 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-blue-600 transition-all ml-4" onClick={verifyEmail}>Next</button>
      </div>
    </>
  ) : currentQuestion === 16 ? (
    <>
      <div className="flex justify-center items-center mt-40">
        <div className="flex">
          <div className="mr-2 text-3xl">Given Name</div>
          <input
            placeholder="Talent’s First name please"
            onChange={(e) => setFirstName(e.target.value)}
            value={FirstName}
            className="ml-2 w-60 h-10 border border-gray-300 rounded-lg p-2"
          />
        </div>
        <button className="bg-blue-500 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-blue-600 transition-all ml-4" onClick={NextFirstname}>Next</button>
      </div>
    </>
  ) : currentQuestion === 17 ? (
    <>
      <div className="flex justify-center items-center mt-20">
        <div className="flex">
          <div className="mr-2 text-3xl">Family Name</div>
          <input
            placeholder="Talent’s Last Name"
            onChange={(e) => setLastName(e.target.value)}
            value={LastName}
            className="ml-2 w-60 h-10 border border-gray-300 rounded-lg p-2"
          />
        </div>
        <button className="bg-blue-500 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-blue-600 transition-all ml-4" onClick={NextLastname}>Next</button>
      </div>
    </>
  ) : currentQuestion === 18 ? (
    <>
      <div className='notif-coopt grid'>
        <div className='email-create-do mr-2'>Object:</div>
        <textarea className="w-11/12 h-28 mx-auto block"  
          onChange={(e) => setsubjectMail(e.target.value)}
          value={subjectMail}
        ></textarea>
        <div className='email-create-do mr-2'>Message:</div>
        <textarea className="w-11/12 h-60 mx-auto block" 
          onChange={(e) => settextMail(e.target.value)}
          value={textMail}
        ></textarea>
        <button className="bg-blue-500 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-blue-600 transition-all mt-4 mx-auto block" onClick={NextNotification}>Send</button>
      </div>
    </>
  ) : currentQuestion === 19 ? (
    <>
      <div className="text-center">
        <p className="coopt-text mt-10">To conclude, communicate to {FirstName} his temporary password using the<br /> method you usually do and remind him to check his emails:</p>
        <p className="coopt-text">Temporary password:<br /> 123456</p>
        <button className="bg-blue-500 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-blue-600 transition-all mt-10" onClick={handleCoopt}>Done</button>
      </div>
    </>
  ) : currentQuestion === 20 ? (
    <>
      <p className="create-do">Oups, you can’t earn Thanks from MKIF, but...<br /><br /></p>
      <div className="explain-coopt">
        <div className="font-bold text-3xl ml-2 mt-4">The email you entered is already in use, meaning {FirstName} is already part of the<br /> Thankward family<br /><br /></div>
        <div className="ml-2">However, you can still earn Thanks from your D.O. if he agrees to join. Don't forget to send<br /> him a personal message.<br /></div>
      </div>
      <div className="flex justify-center mt-4">
        <button className="bg-blue-500 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-blue-600 transition-all" onClick={CooptExsistedMember}>Close</button>
      </div>
    </>
  ) : (
    <>
      <p className="create-do">Do you want to co-opt other people you want to collaborate with or<br /> start Execution?</p>
      <button className="bg-blue-500 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-blue-600 transition-all mt-4" onClick={Cooptagain}>Co-opt</button>
      <Link to="/DIO">
        <button className="bg-blue-500 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-blue-600 transition-all mt-4" onClick={() => handleDIOClick(idDOcreated, nameDO)}>Execution</button>
      </Link>
    </>
        )}

      </div>
    </div>
  );
  
}

export default Welcome;