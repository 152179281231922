import { create } from "zustand";
import axios from "axios";
import Cookies from "js-cookie";
import { useTranslation } from 'react-i18next';

// Créer un hook personnalisé pour accéder au store avec les traductions
export const useStoreWithTranslation = () => {
  const store = useStore();
  const { t } = useTranslation();
  
  // Si selectedOrganization est vide, retourner la traduction par défaut
  const selectedOrganization = store.selectedOrganization || t('newIdeaView.chooseCollabSpace');
  
  return {
    ...store,
    selectedOrganization
  };
};

const useStore = create((set, get) => ({
  dioData: [],
  dioTasks: [],
  ideas: [],
  combinedTasksAndIdeas: [],
  loading: false,
  error: null,
  title: "",
  positiveAspect: "",
  selectedTalent: "",
  selectedOrganization: "",
  selectedOrganizationId: 1,
  link: "",
  teamValue: { selectedScore: 0 }, // Initialisation avec un score par défaut
  complexity: { selectedScore: 0 },
  frequency: { selectedScore: 0 },
  isInputVisible: false,
  isEditingTitle: false,
  isCalendarOpen: false,
  isSplitView:false,
  isSplitWelcomeView: true,
  isSubmitting:false,
  date: new Date().toISOString().split("T")[0],
  menuOpenTalent: false, // Menu Talent par défaut
  menuOpenHub: false, // Menu Hub par défaut
  departHours: 24,
  improvementSuggestion: "",
  feelingIdea:"",
  vote:0,
  contribution:"",
  idDOcreated:"",
  talentsHubData: null,
  nameDO:"",
  pitch: "",
  whatToDo: "",
  whyImportant: "",
  organizationType: "",
  showResourceInput: false,
  clicked:true,
  filter:false,
  onGoingCount:0,
  openCount:0,
  inReviewCount:0,
  achievedCount:0,
  selectedCollabSpace: null,

  token: Cookies.get("token"), // Default value for token
  
  
  axiosInstance: axios.create({
    timeout: 60000, // Timeout of 60 seconds
  }),

  // Setters for the new achievement state
  setTitle: (title) => set({ title }),
  setPositiveAspect: (positiveAspect) => set({ positiveAspect }),
  setSelectedTalent: (selectedTalent) => set({ selectedTalent }),
  setSelectedOrganization: (selectedOrganization) =>
    set({ selectedOrganization }),
  setSelectedOrganizationId: (selectedOrganizationId) =>
    set({ selectedOrganizationId }),
  setLink: (link) => set({ link }),
  setTeamValue: (score) => set({ teamValue: { selectedScore: score } }),
  setComplexity: (score) => set({ complexity: { selectedScore: score } }),
  setFrequency: (score) => set({ frequency: { selectedScore: score } }),
  setIsInputVisible: (isInputVisible) => set({ isInputVisible }),
  setIsEditingTitle: (isEditingTitle) => set({ isEditingTitle }),
  setIsCalendarOpen: (isCalendarOpen) => set({ isCalendarOpen }),
  setDate: (date) => set({ date }),
  setMenuOpenTalent: (isOpen) => set({ menuOpenTalent: isOpen }),
  setMenuOpenHub: (isOpen) => set({ menuOpenHub: isOpen }),
  setDepartHours: (n) => set({ departHours: n }),
  setImprovementSuggestion : (improvementSuggestion) => set({improvementSuggestion}),
  setIdeas: (ideas) => set({ ideas }),
  setFeelingIdea:(feelingIdea)=> set({feelingIdea}),
  setVote:(vote)=> set({vote}),
  setContribution: (value) => set({ contribution: value }),
  setIdDOcreated: (idDOcreated)=> set({idDOcreated}),
  setNameDO:(nameDO)=> set({nameDO}),
  setPitch: (pitch)=> set({pitch}),
  setWhatToDo: (whatToDo)=> set({whatToDo}),
  setWhyImportant: (whyImportant)=> set({whyImportant}),
  setOrganizationType: (organizationType)=> set({organizationType}),
  setShowResourceInput:(showResourceInput)=> set({showResourceInput}),
  setClicked:(clicked)=>set({clicked}),
  setFilter:(filter)=>set({filter}),
  setOnGoingCount:(onGoingCount) =>set({onGoingCount}),
  setOpenCount:(openCount) =>set ({openCount}),
  setInReviewCount:(inReviewCount)=>set({inReviewCount}),
  setAchievedCount:(achievedCount)=>set({achievedCount}),
  setIsSplitView:(isSplitView)=>set({isSplitView}),
  setIsSplitWelcomeView:(isSplitWelcomeView)=>set({isSplitWelcomeView}),
  setIsSubmitting:(isSubmitting)=>set({isSubmitting}),
  // Setter for Dio Data
  setDioData: (data) => set({ dioData: data }),
  setSelectedCollabSpace: (selectedCollabSpace) => set({ selectedCollabSpace }),
  // Setter for Dio Tasks
  setDIOTasks: (tasks) => set({ dioTasks: tasks }),

  // Setter for loading state
  setLoading: (loading) => set({ loading }),

  // Setter for error state
  setError: (error) => set({ error }),

  fetchDioData: async () => {
    console.log("Début fetchDioData");
    set({ loading: true, error: null });
    
    try {
      const token = Cookies.get("token");
      console.log("Token utilisé:", token);

      const userId = localStorage.getItem('userId');
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/DIO/data`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      
      console.log("Réponse DIO data:", response.data);

      if (!response.data) {
        throw new Error("Pas de données reçues");
      }

      set({ dioData: response.data });
      const user = response.data[0]?.member.first_name || "";
      set({ selectedTalent: user });
      
      console.log("DioData mis à jour:", get().dioData);
      
    } catch (error) {
      console.error("Erreur détaillée fetchDioData:", error);
      if (error.response) {
        console.error("Réponse d'erreur:", error.response.data);
      }
    } finally {
      set({ loading: false });
    }
  },

  setCombinedTasksAndIdeas: (tasksAndIdeas) => set({ combinedTasksAndIdeas: tasksAndIdeas }),

  fetchExecutions: async (dioData) => {
    const { token } = get();
    console.log(token, "<============ token)");
    if (!dioData || dioData.length === 0) {
      set({ error: "No data available for executions." });
      return;
    }
  
    set({ loading: true, error: null });
    try {
      const tasks = await Promise.all(
        dioData.map((dio) =>
          axios
            .get(`${process.env.REACT_APP_BACKEND_URL}/DIO/execution?dioId=${dio.id}`, {
              headers: { Authorization: `Bearer ${token}` }, // Inclure le token si nécessaire
            })
            .then((response) => ({
              dioId: dio.id, // Ajouter dioId explicitement
              data: response.data.combinedData,
            })),
        ),
      );
  
      const combinedTasks = tasks.flatMap(({ dioId, data }) =>
        data.map((item) => ({ ...item, dioId })), // Attache dioId à chaque tâche
      );
  
      set({ dioTasks: combinedTasks });
    } catch (error) {
      console.error("Error in fetchExecutions:", error);
      set({ error: "Error fetching executions." });
    } finally {
      set({ loading: false });
    }
  },
  

  addAchievement: async (achievement) => {
    console.log("Selected Organization:", achievement.selectedOrganizationId);
    const { setLoading, setDepartHours, selectedTalent, token, axiosInstance } = get();
    setLoading(true);
    try {
      console.log("1-Données envoyées : ", {
        userId: localStorage.getItem("userId"),
        executionDescription: achievement.title,
        dioId: achievement.selectedOrganizationId,
        status: "In review",
        input_link: achievement.link,
        Expected_date: achievement.date,
        instructions: achievement.positiveAspect,
      });
  
      const start = Date.now();
      const workDoneRes = await axiosInstance.post(
        process.env.REACT_APP_BACKEND_URL + "/execution/workDone",
        
        {
          userId: localStorage.getItem("userId"),
          executionDescription: achievement.title,
          dioId: achievement.selectedOrganizationId,
          status: "In review",
          link: achievement.link,
          Expected_date: achievement.date,
          instructions: achievement.positiveAspect,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      console.log("2-Données envoyées : ", {
        userId: localStorage.getItem("userId"),
        executionDescription: achievement.title,
        dioId: achievement.selectedOrganizationId,
        status: "In review",
        link: achievement.link,
        Expected_date: achievement.date,
        instructions: achievement.positiveAspect,
      });
      console.log("Réponse de WorkDone API :", workDoneRes.data);
  
      const workDoneTime = Date.now() - start;
      console.log(`WorkDone request took ${workDoneTime} ms`);
      const executionID = workDoneRes.data.insertId;
      const dataReview = {
        userId: localStorage.getItem("userId"),
        executionId: executionID,
        comment_highlight: achievement.positiveAspect,
        comment_improvement: null,
        bts: achievement.teamValue,
        userName: localStorage.getItem("userName"),
        dioId: achievement.selectedOrganizationId,
        complexity: achievement.complexity,
        repetition: achievement.frequency,
        link: achievement.link,
      };
  
      const reviewRes = await axiosInstance.post(
        process.env.REACT_APP_BACKEND_URL + "/review/selfReview",
        dataReview,
        { headers: { Authorization: `Bearer ${token}` } },
      );
  
      let newDepartHours = reviewRes.data.data.responseValue;
      console.log("newDepartHours", newDepartHours);
      let updatedStatut = "In review";
  
      // Logique pour changer le statut si la date est dans le futur

      // Logique pour changer le statut
      const currentDate = new Date();
      const expectedDate = new Date(achievement.date);
      
      // Ajout des scores pour vérifier leur valeur
      const reactivityScore = achievement.reactivityScore || 0;
      const resultQualityScore = achievement.resultQualityScore || 0;
      const teamValueScore = achievement.teamValueScore || 0;
      
      if (
        reactivityScore === 0 &&
        resultQualityScore === 0 &&
        teamValueScore === 0 &&
        expectedDate > currentDate
      ) {
        updatedStatut = "On going"; // Mettre à jour le statut en "On going" si les scores sont 0 et la date dans le futur
        console.log(
          "Status set to 'On going' because scores are 0 and expected date is in the future."
        );
      } else {
        if (selectedTalent === "Another Talent") updatedStatut = "Open";
        if (newDepartHours <= 20) updatedStatut = "Achieved";
      }
      
      console.log("Updated status after checking scores and date:", updatedStatut);
  
      const DEADLINES = { DEAD3: 72 };
      let remaining_time = null;
      if (newDepartHours > 20) {
        newDepartHours = DEADLINES.DEAD3;
        remaining_time = 60 * 60 * newDepartHours;
      }
  
      setDepartHours(newDepartHours);
  
      // Check if the status is "On going", and if so, update status_ to "Achieved"
      if (   reactivityScore !== 0 &&
        resultQualityScore !== 0 &&
        teamValueScore !== 0 && (updatedStatut === "On going")) {
        updatedStatut = "Achieved"; // Set the status to "Achieved"
        console.log("Status changed to 'Achieved' as it was 'On going'.");
      }

      console.log("Final status to be sent:", updatedStatut);

      // Send updated status_ to the back-end
      const updateStatusRes = await axiosInstance.post(
        process.env.REACT_APP_BACKEND_URL + "/execution/updateStatus",
        {
          executionId: executionID,
          status: updatedStatut,
          remaining_time: remaining_time,
          executionLink: achievement.link,
          Expected_date: achievement.date,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      console.log("Response from /updateStatus:", updateStatusRes.data);
    } catch (error) {
      console.error("There was an error with the request:", error);
      alert("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  },



  
    // Nouvelle fonction handleReactivity
    submitFeedback: async (feedback) => {
      const { token } = get(); // Récupère le token
    
      // Utilisation directe des données passées dans feedback
      const { positiveAspect, improvementSuggestion, executionId, teamValueScore, resultQualityScore, reactivityScore } = feedback;
      if (!executionId) {
        console.error("Execution ID is missing!");
        return; // Empêche l'envoi des données executionId est absent
      }
      const data = {
        executionId,
        userId: localStorage.getItem("userId"),
        dioId: localStorage.getItem("DOId"),
        comment_highlight: positiveAspect,
        comment_improvement: improvementSuggestion,
        bts: teamValueScore,
        result_quality: resultQualityScore,
        reactivity: reactivityScore,
      };
      // Affichage des données envoyées pour le débogage
      console.log("Données envoyées à l'API:", data);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/review/peerReview`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("Peer review success:", response.data);
      } catch (error) {
        console.error("Error during peer review:", error);
        if (error.response) {
          console.error("Erreur côté serveur:", error.response.data);
        }
      }
    },
    // Nouvelle méthode pour récupérer les idées
    fetchIdeas: async (dioData) => {
      const freshToken = localStorage.getItem('token');
      
      // Mémoriser le dernier timestamp de fetch pour éviter les appels trop fréquents
      const now = Date.now();
      if (now - get().lastFetchTimestamp < 5000) { // 5 secondes minimum entre les fetches
        return get().ideas;
      }

      try {
        // Créer un tableau de promesses avec timeout
        const allIdeasPromises = dioData.map(dio => {
          const url = `${process.env.REACT_APP_BACKEND_URL}/idea/on_going_idea/${dio.id}`;
          
          // Ajouter un timeout pour chaque requête
          const timeoutPromise = new Promise((_, reject) => {
            setTimeout(() => reject(new Error('Timeout')), 5000);
          });

          const fetchPromise = axios.get(url, {
            headers: {
              Authorization: `Bearer ${freshToken}`,
              "Content-Type": "application/json",
            },
            // Ajouter du cache pour les requêtes identiques
            cache: 'default',
          })
          .then(response => response.data)
          .catch(error => {
            console.warn(`Erreur pour dio ${dio.id}:`, error);
            return [];
          });

          // Race entre le timeout et la requête
          return Promise.race([fetchPromise, timeoutPromise]);
        });

        // Exécuter les promesses avec un abort controller
        const abortController = new AbortController();
        const timeoutId = setTimeout(() => abortController.abort(), 10000); // 10s timeout global

        const responses = await Promise.all(allIdeasPromises);
        clearTimeout(timeoutId);

        // Traiter et dédupliquer les résultats
        const newIdeas = Array.from(new Set(responses.flat().map(JSON.stringify)))
          .map(JSON.parse)
          .filter(Boolean);

        // Mettre à jour le state avec batch update
        set(state => {
          const oldIdeasJSON = JSON.stringify(state.ideas);
          const newIdeasJSON = JSON.stringify(newIdeas);
          
          if (oldIdeasJSON === newIdeasJSON) {
            return state;
          }

          // Mettre à jour le timestamp du dernier fetch
          const combinedTasksAndIdeas = [...state.dioTasks, ...newIdeas]
            .sort((a, b) => new Date(b.creation_date) - new Date(a.creation_date))
            // Limiter le nombre d'items si nécessaire
            .slice(0, 100); // Par exemple, limiter à 100 items

          return {
            ideas: newIdeas,
            combinedTasksAndIdeas,
            lastFetchTimestamp: now,
            error: null
          };
        });

        return newIdeas;
      } catch (error) {
        console.error("Erreur globale:", error);
        set(state => ({
          ...state,
          error: "Erreur lors du chargement des idées"
        }));
        return [];
      } finally {
        // Nettoyer les ressources si nécessaire
      }
    },
  
    
  
    // Add sendIdea to your store
    sendIdea: async (
      title,
      describeIdea,
      importantIdea,
      date,
      selectedOrganizationId,
      status,
      isDraft = false
    ) => {
      const { token, axiosInstance } = get();
      set({ loading: true, error: null });

      const data = {
        title: title || "Untitled",
        description: describeIdea || "",
        importance: importantIdea || "",
        expectedDate: date || null,
        id_do: Number(selectedOrganizationId),
        id_creator: localStorage.getItem("userId"),
        status_: status,
        isDraft: isDraft
      };

      try {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/idea/on_going_idea`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error("Error creating idea:", error);
        throw error;
      } finally {
        set({ loading: false });
      }
    },
    sendFeedbackIdea: async (
        idIdea, 
        feelingIdea, 
        contribution, 
        idContributor, 
        vote
    ) => {
        const { token, axiosInstance } = get();
        set({ loading: true, error: null });

        // Formater les données exactement comme le backend les attend
        const data = {
            id_idea: Number(idIdea),
            feeling_idea: feelingIdea ? feelingIdea.trim() : "",
            contribution: contribution === "" || contribution === undefined || contribution === null ? null : contribution.trim(),
            id_contributor: Number(idContributor),
            vote: Number(vote)
        };

        // Vérification des champs obligatoires comme dans le backend
        if (!data.id_idea || !data.feeling_idea || !data.id_contributor || data.vote === undefined) {
            console.log("Validation failed:", data);
            throw new Error("Missing required fields");
        }

        try {
            const response = await axiosInstance.post(
                `${process.env.REACT_APP_BACKEND_URL}/idea/feeling/idea`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            return response.data;
        } catch (error) {
            // Si l'erreur vient du backend, elle contiendra les détails
            if (error.response?.data?.error) {
                console.error("Backend error details:", error.response.data);
                throw new Error(error.response.data.error);
            }
            throw error;
        } finally {
            set({ loading: false });
        }
    },
    createTalentsHub: async (payload) => {
      set({ isLoading: true, error: null });

      if (!payload || !payload.CeoDoId || !payload.nameDO) {
        console.error("Payload invalide:", payload);
        throw new Error("Données de création invalides");
      }

      try {
        // 1. Première requête - création du DO
        const createResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/CreateSponsorizeDO/createDO`,
          payload,
          {
            headers: { "Content-Type": "application/json" }
          }
        );

        console.log("Réponse création:", createResponse.data);

        // Réduit le délai d'attente à 500ms
        await new Promise(resolve => setTimeout(resolve, 500));

        // 2. Deuxième requête - récupération de l'ID
        const infoResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/CreateSponsorizeDO/infoDOcreated`,
          {
            params: {
              userId: payload.CeoDoId,
              nameDo: payload.nameDO
            }
          }
        );

        if (createResponse.data?.message === "Do creation réussie" && 
            infoResponse.data?.length > 0) {
          
          const doId = infoResponse.data[0].id;
          console.log("ID DO récupéré:", doId);

          try {
            // 3. Troisième requête - ajout au créateur
            const addToCreatorResponse = await axios.post(
              `${process.env.REACT_APP_BACKEND_URL}/CreateSponsorizeDO/addDOtoCreator`,
              {
                id_membre: payload.CeoDoId,
                id_do: doId,
                sponsored: '0'
              },
              {
                headers: { "Content-Type": "application/json" }
              }
            );
          } catch (addError) {
            // Si erreur CORS ou réseau sur l'ajout au créateur, on continue quand même
            console.warn("Note: L'ajout au créateur sera fait à la prochaine connexion");
          }

          // On continue même si l'ajout au créateur a échoué
          set({ 
            talentsHubData: { id_do: doId },
            isLoading: false,
            error: null
          });

          // Attendre que la création soit bien enregistrée
          await new Promise(resolve => setTimeout(resolve, 2000));

          // Forcer un rafraîchissement des données avec la bonne URL
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_BACKEND_URL}/api/DIO/data`, // Ajout de /api/
              {
                headers: {
                  Authorization: `Bearer ${Cookies.get("token")}`
                }
              }
            );
            
            if (response.data) {
              console.log("Nouvelles données reçues:", response.data);
              set(state => ({ 
                ...state,
                dioData: response.data,
                selectedTalent: response.data[0]?.member.first_name || ""
              }));
            }
          } catch (fetchError) {
            console.error("Erreur lors du rafraîchissement:", fetchError);
            // En cas d'erreur, on recharge simplement la page
            window.location.reload();
          }

          return { success: true, doId };

        }

        throw new Error("Création incomplète");

      } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || "Erreur inconnue";
        console.error("Erreur complète:", error);
        console.error("Message d'erreur:", errorMessage);
        set({ error: errorMessage, isLoading: false });
        throw new Error(errorMessage);
      } finally {
        set({ isLoading: false });
      }
    },
    handleCreateDO : () => {
      // Récupération des données du DO
     const {setIdDOcreated, nameDO} = get()
      const CeoDoId=localStorage.getItem("userId")
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/CreateSponsorizeDO/infoDOcreated?userId=${CeoDoId}&nameDo=${nameDO}`)
        .then(response => {
          const data = response.data;
          console.log("Données du DO récupérées :", data);
    
          // Vérification si les données sont valides et mise à jour de l'id du DO
          if (data && data.id) {
            setIdDOcreated(data.id); // On met à jour l'ID du DO avec la donnée récupérée
            console.log("idDO créé :", data.id);
    
            // Préparation des données pour l'ajout du DO à un créateur
            const id_membre = localStorage.getItem("userId");
            const id_do = data.id;
            const sponsored = '0'; // Le membre est par défaut non sponsorisé
    
            // Envoi des données à la route /addDOtoCreator
            const userData_ = { id_membre, id_do, sponsored };
    
            fetch(process.env.REACT_APP_BACKEND_URL + "/CreateSponsorizeDO/addDOtoCreator", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(userData_),
            })
            .then((response) => {
              if (response.status === 200) {
                response.json().then((data) => {
                  console.log("Réponse de l'ajout du DO à un créateur :", data);
                  localStorage.setItem("do_ids", JSON.stringify(data.do_ids)); // Sauvegarde des IDs du DO
                });
              }
            })
            .catch((error) => {
              console.error("Erreur lors de la création du DO pour le créateur :", error);
            });
    
          } else {
            console.error('Aucune donnée valide reçue du serveur');
          }
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération des infos du DO :", error);
        });
    },
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    

    // Ajouter ces deux nouvelles fonctions dans le store
    createNewIdea: async (title, describeIdea, importantIdea, date, selectedOrganizationId) => {
      const { token, axiosInstance } = get();
      set({ loading: true, error: null });

      const data = {
        title: title || "Untitled",
        description: describeIdea || "",
        importance: importantIdea || "",
        expectedDate: date || null,
        id_do: Number(selectedOrganizationId),
        id_creator: localStorage.getItem("userId"),
        status_: "On going idea"
      };

      try {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/idea/on_going_idea`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error("Error creating idea:", error);
        throw error;
      } finally {
        set({ loading: false });
      }
    },

    createDraftIdea: async (title, describeIdea, importantIdea, date, selectedOrganizationId) => {
      const { token, axiosInstance } = get();
      set({ loading: true, error: null });

      const data = {
        title: title || "Untitled Draft",
        description: describeIdea || "",
        importance: importantIdea || "",
        expectedDate: date || null,
        id_do: Number(selectedOrganizationId),
        id_creator: localStorage.getItem("userId"),
        status_: "Draft idea"
      };

      try {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/idea/on_going_idea`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error("Error creating draft:", error);
        throw error;
    } finally {
        set({ loading: false });
    }
    },

    updateIdea: async (ideaId, title, describeIdea, importantIdea, date, selectedOrganizationId, status = "On going idea") => {
      const { token, axiosInstance, ideas } = get();
    
      // Récupérer l'idée originale
      const originalIdea = ideas.find(idea => idea.id === ideaId);
      if (!originalIdea) {
        throw new Error('Idea not found');
      }

      const formattedDate = date ? date.split('T')[0] : null;
      const data = {
        id_do: originalIdea.id_do,
        title: title || "",
        id_creator: localStorage.getItem("userId"),
        description: describeIdea || "",
        importance: importantIdea || "",
        expectedDate: formattedDate,
        status_: status
      };

      // Mise à jour optimiste immédiate du state
      set(state => ({
        ideas: state.ideas.map(idea => 
          idea.id === ideaId 
            ? {
                ...idea,
                ...data,
                exec_description: title,
                comment_highlight: describeIdea,
                comment_improvement: importantIdea,
                expected_date: formattedDate,
                performor_name: idea.performor_name || idea.membre_name,
                id_talent_creator: idea.id_creator
              }
            : idea
        )
      }));
    
      // Envoyer la mise à jour au serveur en arrière-plan sans attendre
      axiosInstance.put(
        `${process.env.REACT_APP_BACKEND_URL}/idea/update_idea/${ideaId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          }
        }
      ).catch(error => {
        console.warn("Background update failed:", error);
      });

      // Retourner immédiatement les données mises à jour
      return {
        ...data,
        id: ideaId,
        performor_name: originalIdea.performor_name || originalIdea.membre_name
      };
    },


    cooptTalent: async (firstName, lastName, email, message, dioId) => {
      const { setLoading } = get();
      setLoading(true);

      try {
        // Vérifier l'email avec l'URL complète
        const verifyResponse = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/cooptTalent/verifyMail?email=${email}&doId=${dioId === "sponsoring" ? "0" : dioId}`
        ).then(res => res.json());

        const { emailExists, talentExists, firstName: existingFirstName } = verifyResponse;

        if (talentExists) {
          throw new Error("This talent is already in the DO.");
        }

        const userName = localStorage.getItem("userName");
        const coopteurFirstName = localStorage.getItem("firstName");

        if (dioId === "sponsoring") {
          // Pour le sponsoring, utiliser la route spécifique
          const userData = {
            FirstName: emailExists ? existingFirstName : firstName,
            LastName: lastName,
            Email: email,
            password: "123456",
            first_connection: "1", // Laissé à 1 pour que Welcome.js puisse le gérer
            sponsored: "1",
            UserNameId: localStorage.getItem("userId"),
            subjectMail: `${userName} invites you to Thankward`,
            textMail: `Hi ${emailExists ? existingFirstName : firstName},
Join me on Thankward to explore an amazing experience of collaboration.
You'll see, it's easy, and we earn lots of Thanks, the future of appreciation-based rewards.
You'll receive your password in a separate message.
Follow the link below to sign up with your email address: ${email}
www.thanksandshare.com
See you soon!`
          };

          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/CreateSponsorizeDO/sponsorize`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(userData)
            }
          );

          if (!response.ok) {
            const errorData = await response.text();
            console.error("Server response:", errorData);
            throw new Error("Failed to sponsorize talent");
          }

          // Retourner les données nécessaires pour Welcome.js
          return {
            success: true,
            data: {
              userId: localStorage.getItem("userId"),
              firstName: firstName,
              lastName: lastName,
              email: email,
              sponsored: "1"
            }
          };
        } else {
          // Pour les autres cas (non-sponsoring)
          if (emailExists) {
            const defaultMessage = `
Bonjour ${existingFirstName},

Je t'invite à découvrir Thankward, une plateforme innovante qui transforme la collaboration en une expérience fluide et gratifiante.

C'est super simple de commencer, et ensemble, nous allons accumuler des Thanks : la nouvelle façon de valoriser la reconnaissance et de gagner des récompenses !

Pour nous rejoindre, il te suffit de suivre le lien ci-dessous et de te connecter avec ton adresse email :
${email}
www.thankward.com 

Ton mot de passe te sera envoyé dans un message séparé.

J'ai hâte de t'accueillir sur Thankward et de partager cette belle aventure avec toi !

À très vite,
${coopteurFirstName}`;

            const userData = {
              Email: email,
              id_do: dioId,
              UserNameId: localStorage.getItem("userId"),
              subjectMailexist: `${userName} t'invite à rejoindre ${localStorage.getItem("DOName") ? localStorage.getItem("DOName") : "Thankward"}`,
              textMailexist: message ? `${message}\n\n* * * * *\n\n${defaultMessage}` : defaultMessage
            };

            const response = await fetch(
              `${process.env.REACT_APP_BACKEND_URL}/cooptTalent/api/cooptExsistedMember`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(userData)
              }
            );

            if (!response.ok) {
              const errorData = await response.text();
              console.error("Server response:", errorData);
            }

            return { success: true };
          } else {
            const userData = {
              FirstName: firstName,
              LastName: lastName,
              Email: email,
              password: "123456",
              id_do: dioId,
              first_connection: "1",
              UserNameId: localStorage.getItem("userId"),
              subjectMail: `${userName} t'invite à rejoindre Thankward`,
              textMail: message || `Bonjour ${firstName},

Je t'invite à découvrir Thankward, une plateforme innovante qui transforme la collaboration en une expérience fluide et gratifiante.

C'est super simple de commencer, et ensemble, nous allons accumuler des Thanks : la nouvelle façon de valoriser la reconnaissance et de gagner des récompenses !

Pour nous rejoindre, il te suffit de suivre le lien ci-dessous et de te connecter avec ton adresse email :
${email}
www.thankward.com 

Ton mot de passe te sera envoyé dans un message séparé.

J'ai hâte de t'accueillir sur Thankward et de partager cette belle aventure avec toi !

À très vite,
${coopteurFirstName}`
            };

            const response = await fetch(
              `${process.env.REACT_APP_BACKEND_URL}/cooptTalent/api/coopt`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(userData)
              }
            );

            if (!response.ok) {
              const errorData = await response.text();
              console.error("Server response:", errorData);
            }

            return { success: true };
          }
        }
      } catch (error) {
        console.error("Error coopt talent:", error);
        throw error.response?.data?.message || error.message || "Error inviting talent";
      } finally {
        setLoading(false);
      }
    },

    registrationStep: 0,
    setRegistrationStep: (step) => set({ registrationStep: step }),
    completeOnboarding: () => {
      set({ registrationStep: 0 });
      window.location.href = '/homepage';
    },
}));

// Ajouter une fonction pour vérifier et mettre à jour le statut des idées
const checkAndUpdateIdeasStatus = (ideas) => {
  const now = new Date();
  
  return ideas.map(idea => {
    if (idea.status_ === "On going idea") {
      const creationDate = new Date(idea.creation_date);
      const daysSinceCreation = Math.floor((now - creationDate) / (1000 * 60 * 60 * 24));
      
      if (daysSinceCreation >= 7) {
        return {
          ...idea,
          status_: "Achieved idea"
        };
      }
    }
    return idea;
  });
};

export default useStore;
