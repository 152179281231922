import React from 'react';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from '../i18n/config';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  return (
    <div className="flex gap-2">
      <button
        onClick={() => changeLanguage('fr')}
        className={`px-2 py-1 rounded ${currentLang === 'fr' ? 'bg-black text-white' : 'bg-gray-200'}`}
      >
        FR
      </button>
      <button
        onClick={() => changeLanguage('en')}
        className={`px-2 py-1 rounded ${currentLang === 'en' ? 'bg-black text-white' : 'bg-gray-200'}`}
      >
        EN
      </button>
    </div>
  );
};

export default LanguageSwitcher; 