import React from "react";
import { useTranslation } from "react-i18next";

export const ActionMenu = ({ onAchievementClick, sharedStyles }) => {
  const { t } = useTranslation();
  
  return (
    <div className="absolute right-[70px] transform large:translate-x-[20px] medium:right-full translate-x-[-40px] medium:translate-x-[-80px] mr-4 medium:mr-[100px] extraLarge:right-[100px] extraLarge:mr-[30px] top-20 medium:-top-0 extraLarge:top-2 w-[260px] medium:w-[352px] extraLarge:w-[380px] h-[300px] medium:h-[389px] extraLarge:h-[424px] bg-black shadow-lg rounded-2xl overflow-hidden p-4">
      <ul className="h-full flex flex-col justify-between p-3 medium:p-6">
        <li
          className={`${sharedStyles} h-[80px] medium:h-[110px] flex items-center text-[0.62rem] medium:text-sm font-silka-mono`}
          onClick={onAchievementClick("achievements")}
        >
          {t('actionMenu.addMission')}
        </li>
        <li
          className={`${sharedStyles} h-[80px] medium:h-[110px] flex items-center text-[0.62rem] medium:text-sm font-silka-mono`}
          onClick={onAchievementClick("idea")}
        >
          {t('actionMenu.shareIdea')}
        </li>
        <li
          className={`${sharedStyles} h-[80px] medium:h-[110px] flex items-center text-[0.62rem] medium:text-sm font-silka-mono`}
          onClick={onAchievementClick("cooptation")}
        >
          {t('actionMenu.inviteTalent')}
        </li>
        <li
          className={`${sharedStyles} h-[80px] medium:h-[110px] flex items-center text-[0.62rem] medium:text-sm font-silka-mono`}
          onClick={onAchievementClick("talentHub")}
        >
          {t('actionMenu.createCollabSpace')}
        </li>
      </ul>
    </div>
  );
};
