import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translations/en';
import fr from './translations/fr';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en,
      fr,
    },
    lng: window.location.pathname.includes('/en') ? 'en' : 'fr',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export const changeLanguage = (language) => {
  const currentPath = window.location.pathname;
  const basePath = currentPath.split('/').slice(2).join('/');
  const newPath = `/${language}${basePath ? '/' + basePath : ''}`;
  
  window.history.pushState({}, '', newPath);
  i18n.changeLanguage(language);
};

export default i18n; 