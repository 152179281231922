import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import PercentageViz from "./PercentageViz.js";
import BackArrow from '../../../Components/BackArrow.js';
import { ViewContainer, IconContainer } from './shared/ViewContainer';

import idea from "../../../images/icones/Idea.png";
import profil from "../../../images/icones/personna.png";
import IdeaCountdown from '../../../Components/IdeaCountdown.js';

const IdeaView = ({ handleGoBack, cardDetails, dioData }) => {
  const { t } = useTranslation();
  const [isTimeUp, setIsTimeUp] = useState(false);
  const userId = localStorage.getItem("userId");
  const isCreator = Number(cardDetails.id_talent_creator) === Number(userId);

  const completName = `${cardDetails.membre_first_name || t('ideaView.feedback.unknownFirstName')} ${cardDetails.performor_name || t('ideaView.feedback.unknownLastName')}`;
  const usersList = [completName];
  const profilePics = [profil];

  const ideaViewRef = useRef();

  // Trouver le nom de la collab space correspondant à l'idée
  const collabSpaceName = dioData?.find(dio => dio.id === cardDetails.id_do)?.name || "Collab Space";

  // Fonction pour vérifier si le temps est écoulé
  const checkTimeUp = () => {
    const now = new Date();
    const creation = new Date(cardDetails.creation_date);
    const deadline = new Date(creation.getTime() + (3 * 24 * 60 * 60 * 1000));
    return now >= deadline;
  };

  useEffect(() => {
    setIsTimeUp(checkTimeUp());
    const timer = setInterval(() => {
      setIsTimeUp(checkTimeUp());
    }, 1000);
    
    return () => clearInterval(timer);
  }, [cardDetails.creation_date]);

  useEffect(() => {
    if (ideaViewRef.current) {
      ideaViewRef.current.scrollIntoView({
        block: "start",
        behavior: "instant",
      });
    }
  }, []);

  return (
    <ViewContainer bgColor="bg-gains3">
      <div className="flex justify-between mb-20">
        <BackArrow onClick={handleGoBack} />
        <IconContainer>
          <img alt="idea" src={idea} className="w-full h-auto" />
        </IconContainer>
      </div>
      <p className="font-silka-mono mt-10">{collabSpaceName}</p>
      <p className="font-silka-mono font-semibold text-4xl mt-6">
        {cardDetails?.exec_description}
      </p>

      <div className="flex items-center space-x-2 mt-10">
        {usersList.map((user, index) => (
          <React.Fragment key={index}>
            {profilePics[index] && (
              <img
                src={profilePics[index]}
                alt={user}
                className="w-8 h-8 rounded-full"
              />
            )}
            <p className="text-sm font-silka-mono">{user}</p>
            {index < usersList.length - 1 && <span> &gt; </span>}
          </React.Fragment>
        ))}
      </div>

      <div className="flex justify-center items-center w-[100px] h-12 border-2 border-black mt-20 rounded-2xl transform -translate-x-4">
        <p className="font-silka-mono font-bold uppercase text-center p-2">
          {t('ideaView.labels.pitch')}
        </p>
      </div>

      <p className="font-silka-mono mt-10">
        {cardDetails.comment_highlight} <br/>
        {cardDetails.comment_improvement}
      </p>
      <>
        <div className="flex justify-center items-center w-[140px] h-12 border-2 border-black mt-20 rounded-2xl transform -translate-x-4">
          <p className="font-silka-mono font-bold uppercase text-center p-2">
            {t('ideaView.labels.feedback')}
          </p>
        </div>

        {cardDetails.feedbacks && cardDetails.feedbacks.map((feedback, index) => (
          <>
            <div key={index} className="relative font-silka-mono rounded-3xl max-w-xs border-2 border-black mt-10 bg-gains3">
              <p className="p-4">
                {feedback.feeling_idea}
              </p>
              <div className="my-2 border-t border-black w-full"></div>
              <p className="p-4">
                {feedback.contribution}
              </p>

              <div className="absolute -bottom-2 left-10 w-0 h-0 border-t-8 border-t-black border-r-8 border-r-transparent border-l-8 border-l-transparent"></div>
            </div>

            <div className="flex items-center space-x-2 mt-10">
              <img src={profil} alt="user" className="w-8 h-8 rounded-full" />
              <p className="text-sm font-silka-mono">{feedback.first_name + " " + feedback.name || null}</p>
            </div>
          </>
        ))}
      </>

      <PercentageViz yesCount={cardDetails.yesCount} noCount={cardDetails.noCount} />
      <IdeaCountdown creationDate={cardDetails.creation_date} />
    </ViewContainer>
  );
};

export default IdeaView;
