import React, { useEffect, useRef } from "react";
import TextAreaWithLabel from "../../../Components/TextAreaWithLabel.js";
import { useTranslation } from 'react-i18next';

import BackArrow from "../../../Components/BackArrow.js";
import idea from "../../../images/icones/Idea.png";
import profil from "../../../images/icones/personna.png";
import IdeaCountdown from '../../../Components/IdeaCountdown.js';
import useStore from "../../../API/store";
import { ViewContainer, IconContainer } from './shared/ViewContainer';


const IdeaFeedbackView = ({ handleGoBack, selectedCardDetails, dioData, setSelectedStatus }) => {
    const { t } = useTranslation();
    const {
        feelingIdea, 
        setFeelingIdea,
        contribution, 
        setContribution,
        vote,
        setVote,
        sendFeedbackIdea,
        setLoading,
        setError,
        fetchIdeas
      } = useStore();
      const completName = `${selectedCardDetails.membre_first_name || 'Prénom inconnu'} ${selectedCardDetails.performor_name || 'Nom inconnu'}`;
      const usersList = [completName];
  const profilePics = [profil];
  const ideaViewRef = useRef();

  useEffect(() => {
    if (ideaViewRef.current) {
      ideaViewRef.current.scrollIntoView({
        block: "start",
        behavior: "instant",
      });
    }
  }, []);
  const validateForm = () => {
    return feelingIdea.trim() !== "";
  };
  
  const userId = parseInt(localStorage.getItem("userId"));
  const idContributor = localStorage.getItem("userId")
  const idIdea = selectedCardDetails.id
  const handleVoteAndSubmit = (voteValue) => async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      alert("Please fill all required fields.");
      return;
    }
    
    try {
      setLoading(true);
      
      await sendFeedbackIdea(
        Number(idIdea),
        feelingIdea.trim(),
        contribution === "" ? null : contribution,
        Number(idContributor),
        Number(voteValue)
      );
      
      await fetchIdeas(dioData);
      setFeelingIdea("");
      setContribution("");
      handleGoBack();
      setSelectedStatus("all");
    } catch (error) {
      console.error("Error in handleVoteAndSubmit:", error);
      setLoading(false);
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Trouver le nom de la collab space correspondant à l'idée
  const collabSpaceName = dioData?.find(dio => dio.id === selectedCardDetails.id_do)?.name || "Collab Space";

  // Vérifier si l'utilisateur a déjà donné son feedback
  const hasUserFeedback = selectedCardDetails.feedbacks?.some(
    feedback => feedback.id_contributor === userId
  );

  console.log(hasUserFeedback, "<========== hasUserFeedback");
  console.log(userId, "<========== userId");
  return (
    <ViewContainer bgColor="bg-aqua3">
      <div className="flex justify-between mb-20">
        <BackArrow onClick={handleGoBack} />
        <div className="flex items-center space-x-2">
          <IconContainer>
            <img src={idea} alt="idée" className="w-full h-auto" />
          </IconContainer>
          <IconContainer>
            <IdeaCountdown 
              creationDate={selectedCardDetails.creation_date} 
              isSplitView={false} 
              displayMode="compact" 
            />
          </IconContainer>
        </div>
      </div>
      <p className="font-silka-mono mt-10">{collabSpaceName}</p>
      <p className="font-silka-mono font-semibold text-4xl mt-6">
        {selectedCardDetails?.exec_description}
      </p>

      <div className="flex items-center space-x-2 mt-10">
        {usersList.map((user, index) => (
          <React.Fragment key={index}>
            {profilePics[index] && (
              <img
                src={profilePics[index]}
                alt={user}
                className="w-8 h-8 rounded-full"
              />
            )}
            <p className="text-sm font-silka-mono">{user}</p>
            {index < usersList.length - 1 && <span> &gt; </span>}
          </React.Fragment>
        ))}
      </div>

      <div className="flex justify-center items-center w-[100px] h-12 border-2 border-black mt-20 rounded-2xl transform -translate-x-4">
        <p className="font-silka-mono font-bold uppercase text-center p-2">
          {t('ideaFeedback.labels.pitch')}
        </p>
      </div>

      <p className="font-silka-mono mt-10">
  {selectedCardDetails.comment_highlight}
      </p>
      <p className="font-silka-mono mt-5">
  {selectedCardDetails.comment_improvement}
      </p>

      {!hasUserFeedback && Number(selectedCardDetails.id_talent_creator) !== Number(userId) && (
        <form className="mt-10">
          <TextAreaWithLabel
            label={t('ideaFeedback.form.feeling.label')}
            value={feelingIdea}
            placeholder={t('ideaFeedback.form.feeling.placeholder')}
            onChange={(e) => setFeelingIdea(e.target.value)}
            bg="bg-aqua3"
          />
          <TextAreaWithLabel
            label={
              <>
                {t('ideaFeedback.form.contribution.label')} <span style={{ fontStyle: 'italic' }}>{t('ideaFeedback.form.contribution.optional')}</span>
              </>
            }
            value={contribution || ""}
            placeholder={t('ideaFeedback.form.contribution.placeholder')}
            onChange={(e) => setContribution(e.target.value)}
            bg="bg-aqua3"
          />
          <IdeaCountdown creationDate={selectedCardDetails.creation_date} />
          <div className="flex flex-row space-x-4 justify-start mt-20">
            <button
              type="button"
              className="w-[16em] h-10 px-6 py-2 bg-black text-white rounded-[50px] font-silka-mono uppercase text-xs"
              onClick={handleVoteAndSubmit(1)}
            >
              {t('ideaFeedback.form.buttons.approve')}
            </button>
            <button
              type="button"
              className="w-[18em] h-10 px-6 py-2 bg-aqua3 border-2 border-black text-black rounded-[50px] font-silka-mono uppercase text-xs"
              onClick={handleVoteAndSubmit(0)}
            >
              {t('ideaFeedback.form.buttons.reject')}
            </button>
          </div>
        </form>
      )}

      {hasUserFeedback && (
        <div className="mt-10 bg-orange3 opacity-50 h-15 w-auto mb-20">
          <p className="font-silka-mono text-lg">
            {t('ideaFeedback.labels.alreadyFeedback')}
          </p>
        </div>
      )}

    </ViewContainer>
  );
};

export default IdeaFeedbackView