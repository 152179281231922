import React, { useState } from "react";
import Cookies from "js-cookie";
import { useTranslation } from 'react-i18next';
import logo from "../../images/thankward-logo-removebg.png";
import LanguageSwitcher from '../../Components/LanguageSwitcher';

const LoginPage = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/login/api/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        
        localStorage.setItem("userId", data.userId);
        localStorage.setItem("userName", data.userName);
        localStorage.setItem("firstName", data.firstName);
        localStorage.setItem("email", data.email);
        localStorage.setItem("do_ids", data.do_ids);
        localStorage.setItem("sponsored", data.sponsored);
        localStorage.setItem("token", data.token);
        Cookies.set("token", data.token, { secure: true });

        const currentLang = window.location.pathname.includes('/en') ? 'en' : 'fr';
        
        window.location.href = data.first_connection === 0 
          ? `/${currentLang}/homepage`
          : `/${currentLang}/welcome`;
      } else {
        alert(t('login.error'));
      }
    } catch (error) {
      console.error("Erreur lors de la connexion :", error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col medium:flex-row">
      <div className="absolute top-4 right-4">
        <LanguageSwitcher />
      </div>

      {/* Left Section */}
      <div className="w-full medium:w-1/2 bg-aqua3 p-8 medium:p-12 flex flex-col justify-center">
        <h1 className="text-3xl medium:text-5xl font-silka-mono mb-8">
          {t('login.leftSection.mainTitle')}
        </h1>

        <div className="mt-8 medium:mt-12">
          <h2 className="font-silka-mono mb-6">
            <span className="font-bold">Thankward</span> {t('login.leftSection.subtitle')}
          </h2>

          <div className="space-y-6 medium:space-y-8">
            <div className="flex items-start">
              <span className="font-silka-mono font-bold mr-2">1-</span>
              <p className="font-silka-mono">{t('login.leftSection.point1')}</p>
            </div>

            <div className="flex items-start">
              <span className="font-silka-mono font-bold mr-2">2-</span>
              <p className="font-silka-mono">{t('login.leftSection.point2')}</p>
            </div>

            <div className="flex items-start">
              <span className="font-silka-mono font-bold mr-2">3-</span>
              <p className="font-silka-mono">{t('login.leftSection.point3')}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Right Section */}
      <div className="w-full medium:w-1/2 bg-gray-50 p-8 medium:p-12 flex flex-col justify-center">
        <div className="max-w-md mx-auto w-full">
          <h2 className="text-2xl medium:text-3xl font-silka-mono mb-8">
            {t('login.title')}
          </h2>

          <form onSubmit={handleLogin} className="space-y-6">
            <div>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t('login.email')}
                className="w-full p-3 border-2 border-black rounded-lg font-silka-mono focus:outline-none"
                required
              />
            </div>

            <div>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={t('login.password')}
                className="w-full p-3 border-2 border-black rounded-lg font-silka-mono focus:outline-none"
                required
              />
            </div>

            <button
              type="submit"
              className="w-[30%] bg-black text-white py-3 font-silka-mono hover:bg-gray-800 transition-colors rounded-full"
            >
              {t('login.welcome')}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
