import React from 'react';

const ProjectCard = ({ collabSpace, isSelected, onSelect, cardCount }) => {
  return (
    <button 
      className={`rounded-lg p-6 border-2 border-black flex flex-col gap-2 transition-colors duration-200
        ${isSelected ? 'bg-black text-white' : 'bg-rightGreyBg text-black'}`}
      onClick={onSelect}
    >
      <div className={`w-8 h-8 rounded-full flex items-center justify-center text-sm
        ${isSelected ? 'bg-rightGreyBg text-black' : 'bg-black text-white'}`}
      >
        {cardCount}
      </div>
      <div className={`text-sm uppercase
        ${isSelected ? 'text-white' : 'text-black'}`}
      >
        {collabSpace.do_creator.first_name}
      </div>
      <div className={`font-mono font-bold
        ${isSelected ? 'text-white' : 'text-black'}`}
      >
        {collabSpace.name}
      </div>
    </button>
  );
};

export default ProjectCard;