import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import TextAreaWithLabel from "../../../Components/TextAreaWithLabel";
import useStore from "../../../API/store";
import BackArrow from "../../../Components/BackArrow";
import ResourceLink from "../Components/ResourceLink";

const EditIdeaView = ({ handleGoBack, ideaData, dioData }) => {
  const { t } = useTranslation();
  const {
    link,
    setLink,
    isInputVisible,
    setIsInputVisible,
    updateIdea,
    setLoading,
    setError
  } = useStore();
  console.log(ideaData, "<====================== ideaData");
  
  const [title, setTitle] = useState(ideaData.exec_description || ideaData.title || "");
  const [describeIdea, setDescribeIdea] = useState(ideaData.comment_highlight || ideaData.description || "");
  const [importantIdea, setImportantIdea] = useState(ideaData.comment_improvement || ideaData.importance || "");
  const [date, setDate] = useState(() => {
    if (ideaData.expected_date) {
      const d = new Date(ideaData.expected_date);
      return d.toISOString().split('T')[0];
    }
    return null;
  });
  const [isCalendarOpen, setIsCalendarOpen] = useState(!!ideaData.expected_date);
  const [isEditingTitle, setIsEditingTitle] = useState(false);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const handleLinkClick = () => setIsInputVisible(!isInputVisible);
  const handleInputChange = (event) => setLink(event.target.value);

  const handleCalendar = () => {
    setIsCalendarOpen(true);
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!title?.trim()) {
        throw new Error("Title is required");
      }

      console.log('Submitting update with:', {
        id: ideaData.id,
        title,
        describeIdea,
        importantIdea,
        date,
        dioId: ideaData.id_do

      });

      await updateIdea(
        ideaData.id,
        title,
        describeIdea,
        importantIdea,
        date,
        dioData[0].id,
        "On going idea"
      );

      handleGoBack();
    } catch (error) {
      console.error("Submit error:", error);
      setError(error.message);
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const newIdeaViewRef = useRef();

  useEffect(() => {
    if (newIdeaViewRef.current) {
      newIdeaViewRef.current.scrollIntoView({
        block: "start",
        behavior: "instant",
      });
    }
  }, []);

  return (
    <div
      ref={newIdeaViewRef}
      className="w-full  bg-orange3 p-8 medium:p-32 overflow-y-auto max-h-[calc(110vh-64px)] overflow-x-hidden"
    >
      <div className="flex justify-between mb-20">
        <BackArrow onClick={handleGoBack} />
        {date ? (
          <input
            type="date"
            className="text-xs w-[132px] h-[60px] p-4 pl-3 pr-3 mb-6 flex items-center justify-center text-white bg-stone-950 rounded-lg font-silka-mono cursor-pointer"
            onChange={handleDateChange}
            value={date}
            min={new Date().toISOString().split("T")[0]}
          />
        ) : !isCalendarOpen ? (
          <div
            onClick={handleCalendar}
            className="text-xs w-[92px] h-[180px] p-4 pl-3 pr-3 mb-6 flex flex-col items-start justify-center text-white bg-stone-950 rounded-lg font-silka-mono cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6 mb-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 3v1.5m7.5-1.5v1.5M3.75 9h16.5M4.5 5.25h15a2.25 2.25 0 012.25 2.25v11.25a2.25 2.25 0 01-2.25 2.25H4.5a2.25 2.25 0 01-2.25-2.25V7.5a2.25 2.25 0 012.25-2.25z"
              />
            </svg>
            <p>{t('editIdea.form.calendar.question')}</p>
          </div>
        ) : (
          <input
            type="date"
            className="text-xs w-[132px] h-[60px] p-4 pl-3 pr-3 mb-6 flex items-center justify-center text-white bg-stone-950 rounded-lg font-silka-mono cursor-pointer"
            onChange={handleDateChange}
            value={date}
            min={new Date().toISOString().split("T")[0]}
          />
        )}
      </div>
      <form onSubmit={handleSubmit} className="mt-10">
      {!isEditingTitle ? (
        <textarea
          value={title}
          onChange={handleTitleChange}
          onBlur={() => setIsEditingTitle(false)}
          placeholder={t('editIdea.title.placeholder')}
          maxLength={40}
          autoFocus
          className="font-silka-mono font-semibold text-4xl underline italic mt-6 bg-transparent border-none outline-none resize-none overflow-hidden h-18 placeholder-black"
          rows={2}
        />
      ) : (
        <div className="font-silka-mono font-semibold text-4xl mt-6 underline italic cursor-pointer">
          {title.split("\n").map((line, index) => (
            <span key={index}>
              {line}
              {index < title.split("\n").length - 1 && <br />}
            </span>
          ))}
        </div>
      )}


        <TextAreaWithLabel
          label={t('editIdea.form.describe.label')}
          value={describeIdea}
          placeholder={t('editIdea.form.describe.placeholder')}
          onChange={(e) => setDescribeIdea(e.target.value)}
          bg="bg-orange3"
        />
        <TextAreaWithLabel
          label={t('editIdea.form.importance.label')}
          value={importantIdea}
          placeholder={t('editIdea.form.importance.placeholder')}
          onChange={(e) => setImportantIdea(e.target.value)}
          bg="bg-orange3"
        />

        <ResourceLink
          handleLinkClick={handleLinkClick}
          isInputVisible={isInputVisible}
          link={link}
          handleInputChange={handleInputChange}
        />

        <div className="flex flex-col space-y-2">
          <div className="flex flex-row items-center space-x-4 mt-10 mb-20">
            <button
              type="submit"
              className="w-[15em] px-8 py-4 bg-black text-white rounded-[50px] font-silka-mono uppercase"
            >
              {t('editIdea.form.buttons.share')}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditIdeaView; 