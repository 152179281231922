import React, { useState, useEffect } from "react";

const IdeaCountdown = ({ creationDate, isSplitView, displayMode = "full" }) => {
  const [timeLeft, setTimeLeft] = useState("");
  const [isExpired, setIsExpired] = useState(false);
  
  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const creation = new Date(creationDate);
      const deadline = new Date(creation.getTime() + (5 * 60 * 1000));
      const difference = deadline - now;
      
      if (difference <= 0) {
        setIsExpired(true);
        if (displayMode === "compact") {
          const day = creation.getDate().toString().padStart(2, '0');
          const month = (creation.getMonth() + 1).toString().padStart(2, '0');
          return (
            <>
              <span className="block font-silka-mono">{day}</span>
              <span className="block font-silka-mono">{month}</span>
            </>
          );
        }
        return null;
      }
      
      setIsExpired(false);
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);
      
      const formatNumber = (num) => num < 10 ? `0${num}` : num;
      
      if (displayMode === "compact") {
        if (days > 0) {
          return (
            <>
              <span className="block font-silka-mono">{days}</span>
              <span className="block font-silka-mono">D</span>
            </>
          );
        } else if (hours > 0) {
          return (
            <>
              <span className="block font-silka-mono">{formatNumber(hours)}</span>
              <span className="block font-silka-mono">H</span>
            </>
          );
        } else if (minutes > 0) {
          return (
            <>
              <span className="block font-silka-mono">{formatNumber(minutes)}</span>
              <span className="block font-silka-mono">M</span>
            </>
          );
        } else {
          return (
            <>
              <span className="block font-silka-mono">{formatNumber(seconds)}</span>
              <span className="block font-silka-mono">S</span>
            </>
          );
        }
      } else {
        return `${days}D ${formatNumber(hours)}:${formatNumber(minutes)}:${formatNumber(seconds)}`;
      }
    };
    
    setTimeLeft(calculateTimeLeft());
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    
    return () => clearInterval(timer);
  }, [creationDate, displayMode, isSplitView]);
  
  if (isExpired && displayMode === "full") {
    return null;
  }

  return (
    <div className={`
      ${isSplitView && displayMode === "compact" 
        ? "text-[10px] h-[38px] w-[22px] p-1 pt-2 pb-2 extraLarge:text-xs extraLarge:w-[28px] extraLarge:h-[44px] extraLarge:p-1 extraLarge:pl-3 extraLarge:pr-3  "
        : displayMode === "compact"
        ? "text-sm h-[50px] w-[32px] p-1 pl-3 pr-3 extraLarge:text-base extraLarge:h-[60px] extraLarge:w-[36px] extraLarge:p-2 extraLarge:pl-4 extraLarge:pr-4" 
        : "text-sm h-[52px] w-auto opacity-50 mt-20 mb-20 extraLarge:text-xl extraLarge:h-[80px] extraLarge:w-auto extraLarge:opacity-50 extraLarge:mt-24 extraLarge:mb-24"
      }
      font-silka-mono bg-black rounded-lg text-white
      flex flex-col items-center justify-center
    `}>
      {timeLeft}
    </div>
  );
};

export default IdeaCountdown; 