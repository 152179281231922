import React from 'react';

const Loader = ({ size = 'medium', color = 'black', className = '' }) => {
  const sizeClasses = {
    small: 'w-3 h-3 border-2 sm:w-4 sm:h-4 md:w-5 md:h-5',
    medium: 'w-6 h-6 border-2 sm:w-8 sm:h-8 md:w-10 md:h-10 sm:border-3',
    large: 'w-8 h-8 border-3 sm:w-12 sm:h-12 md:w-16 md:h-16 sm:border-4'
  };

  const colorClasses = {
    black: 'border-black/20 border-t-black',
    white: 'border-white/20 border-t-white',
    gray: 'border-gray-200 border-t-gray-400',
    primary: 'border-blue-200 border-t-blue-500'
  };

  return (
    <div className={`flex justify-center items-center ${className}`}>
      <div 
        className={`
          animate-spin
          rounded-full
          ${sizeClasses[size] || sizeClasses.medium}
          ${colorClasses[color] || colorClasses.black}
        `}
      />
    </div>
  );
};

export default Loader;
