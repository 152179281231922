import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import TextAreaWithLabel from "../../Components/TextAreaWithLabel";

import ResourceLink from "./Components/ResourceLink";

import BackArrow from "../../Components/BackArrow";

//import t100h from '../../images/t100h.png';
import profile from "../../images/icones/personna.png";

import useStore, { useStoreWithTranslation } from "../../API/store";
import { ViewContainer, IconContainer, CommonStyles } from './Components/shared/ViewContainer';

const NewIdeaView = ({ handleGoBack, dioData, setSelectedStatus }) => {
  const { t } = useTranslation();
  const {
    link,
    setLink,
    isInputVisible,
    setIsInputVisible,
    createNewIdea,
    createDraftIdea,
    setLoading,
    fetchIdeas,
    setError
  } = useStore();
  const { selectedOrganization, setSelectedOrganization } = useStoreWithTranslation();
  const [describeIdea, setDescribeIdea] = useState("");
  const [importantIdea, setImportantIdea] = useState("");
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(1);
  const [title, setTitle] = useState("");
  const [date, setDate] = useState(new Date().toISOString().split("T")[0])

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const [menuOpenHub, setMenuOpenHub] = useState(false);

  const handleLinkClick = () => setIsInputVisible(!isInputVisible);
  const handleInputChange = (event) => setLink(event.target.value);

  const validateForm = () => {
    if (!(
      title.trim() !== "" &&
      describeIdea.trim() !== "" &&
      importantIdea.trim() !== "" &&
      date &&
      selectedOrganizationId !== "" &&
      selectedOrganization !== t('newIdeaView.chooseCollabSpace')
    )) {
      alert(t('newIdeaView.fillFields'));
      return false;
    }
    return true;
  };
  
  const onSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      alert("Please fill all required fields.");
      return;
    }
    setLoading(true);
    
    createNewIdea(
      title,
      describeIdea,
      importantIdea,
      date,
      Number(selectedOrganizationId)
    )
    .then(() => {
      console.log("IDEA successfully submitted!");
      fetchIdeas(dioData);
      setTitle("");
      setDescribeIdea("");
      setDate("");
      setLink("");
      handleGoBack();
      setSelectedStatus("all");
    })
    .catch((error) => {
      const errorMessage = error.response?.data?.message || error.message;
      setError(errorMessage);
    })
    .finally(() => {
      setLoading(false);
    });
  };

  
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleCalendar = () => {
    setIsCalendarOpen(true);
  };
  const handleDateChange = (e) => {
    setDate(e.target.value);
  };
  const handleOrganizationClick = (organization, id) => {
    setSelectedOrganization(organization);
    setSelectedOrganizationId(id);
    setMenuOpenHub(false);
  };

  const newIdeaViewRef = useRef();

  useEffect(() => {
    if (newIdeaViewRef.current) {
      newIdeaViewRef.current.scrollIntoView({
        block: "start",
        behavior: "instant",
      });
    }
  }, []);

  useEffect(() => {
    setSelectedOrganization(t('newIdeaView.chooseCollabSpace'));
  }, [t]);

  const handleSaveAsDraft = async (e) => {
    e.preventDefault();
    if (!selectedOrganizationId) {
      alert(t('newIdeaView.selectCollabSpace'));
      return;
    }
    setLoading(true);
    
    try {
      await createDraftIdea(
        title || "Untitled Draft",
        describeIdea,
        importantIdea,
        date,
        Number(selectedOrganizationId)
      );
      
      await fetchIdeas(dioData);
      setTitle("");
      setDescribeIdea("");
      setImportantIdea("");
      setDate("");
      setLink("");
      handleGoBack();
      setSelectedStatus("all");
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      ref={newIdeaViewRef}
      className="w-full bg-rightGreyBg p-8 medium:p-32 pb-32 overflow-y-auto max-h-[calc(110vh-64px)] overflow-x-hidden"
    >
         <div className="flex justify-between mb-20">
         <BackArrow onClick={handleGoBack} />
          <input
            type="date"
            className="text-xs w-[132px] h-[60px] p-4 pl-3 pr-3 mb-6 flex items-center justify-center text-white bg-stone-950 rounded-lg font-silka-mono cursor-pointer"
            onChange={handleDateChange}
            value={date}
            min={new Date().toISOString().split("T")[0]}
          />
      </div>

      {!isEditingTitle ? (
        <textarea
          value={title}
          onChange={handleTitleChange}
          onBlur={() => setIsEditingTitle(false)}
          placeholder={t('newIdeaView.title')}
          maxLength={40}
          autoFocus
          className="font-silka-mono font-semibold text-2xl medium:text-4xl extraLarge:text-5xl underline italic mt-6 bg-transparent border-none outline-none resize-none overflow-hidden h-18 placeholder-black mb-10"
          rows={2}
        />
      ) : (
        <div className="font-silka-mono font-semibold text-4xl mt-6 underline italic cursor-pointer">
          {title.split("\n").map((line, index) => (
            <span key={index}>
              {line}
              {index < title.split("\n").length - 1 && <br />}
            </span>
          ))}
        </div>
      )}

      <div className="flex space-x-4">
        <div className="flex justify-center items-center w-[240px] h-8 border border-1 border-black rounded-xl relative">
          <button
            type="button"
            className="flex justify-center items-center w-full h-full text-xs font-silka-mono uppercase p-2"
            onClick={() => setMenuOpenHub(!menuOpenHub)}
          >
                {selectedOrganization}
            <svg
              className="ml-auto h-5 w-5 text-gray-900"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {menuOpenHub && (
            <div className="absolute right-0 z-10 w-[240px] origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none transform translate-y-20">
              <div className="py-1 max-h-dropdown overflow-y-auto">
                {dioData.map((item, index) => (
                  <button
                    key={index}
                    className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                    onClick={() => handleOrganizationClick(item.name, item.id)}
                  >
                    {item.name}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
        {/* <div className="flex justify-center items-center w-[160px] h-8 border border-1 border-black rounded-xl">
                    <p className="font-silka-mono uppercase text-xs text-center p-2">
                        add to a project
                    </p>
                </div> */}
      </div>


      <div className="flex space-x-4 mt-10">
        <div className="flex justify-start items-center w-[120px] h-8 rounded-xl">
          <img className="w-8 h-8 rounded-full" alt="profil" src={profile} />
          <p className="font-silka-mono text-xs text-center p-2">
            {dioData[0]?.member.first_name}
          </p>
        </div>
      </div>

      <form onSubmit={onSubmit} className="mt-10">
        <TextAreaWithLabel
          label={t('newIdeaView.describeIdea')}
          value={describeIdea}
          placeholder={t('newIdeaView.provideBrief')}
          onChange={(e) => setDescribeIdea(e.target.value)}
          bg="bg-rightGreyBg"
        />
        <TextAreaWithLabel
          label={t('newIdeaView.whyImportant')}
          value={importantIdea}
          placeholder={t('newIdeaView.provideBrief')}
          onChange={(e) => setImportantIdea(e.target.value)}
          bg="bg-rightGreyBg"
        />

{/* <ResourceLink
        handleLinkClick={handleLinkClick}
        isInputVisible={isInputVisible}
        link={link}
        handleInputChange={handleInputChange}
      /> */}

        <div className="flex flex-col space-y-2 mb-20">
          <div className="flex flex-row items-center space-x-4 mt-10">
            <button
              type="submit"
              className={CommonStyles.btnPrimary}
            >
              {t('newIdeaView.shareIdea')}
            </button>
            {/* <img src={t100h} alt="thanks" className="w-22 h-10" /> */}
          </div>

          <button
            type="button"
            onClick={handleSaveAsDraft}
            className={CommonStyles.btnSecondary}
          >
            {t('newIdeaView.saveForLater')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewIdeaView;
