import React from 'react';

const RadioQuestion = ({ label, options, selectedIndex, selectedValue, onChange }) => (
    <div className="mb-16 mt-4 transform translate-x-4">
        <label className="block font-silka-mono font-semibold text-sm transform translate-x-2 whitespace-pre-line">{label}</label>
        <div className="flex justify-between border-2 border-black rounded-3xl h-22 mt-2 p-4 w-[380px]">
            <span className="font-silka-mono font-semibold pl-2">
                {selectedValue}
                <p className="font-silka-mono text-[10px] text-gray-800 w-60">{options[selectedIndex]?.description}</p>
            </span>
            <div className="flex space-x-4">
                {options.map((item, index) => (
                    <label key={index} className="flex items-center -space-x-3">
                        <input
                            type="radio"
                            checked={selectedIndex === index}
                            onChange={() => onChange(index)}
                            className="hidden" 
                        />
                        <div className={`w-3 h-3 rounded-full border-2 border-black flex items-center justify-center cursor-pointer ${
                            selectedIndex === index ? 'bg-black' : 'bg-transparent'
                        }`}>
                            {selectedIndex === index && (
                                <div className="w-1 h-1 bg-black rounded-full"></div>
                            )}
                        </div>
                    </label>
                ))}
            </div>
        </div>
    </div>
);

export default RadioQuestion;
