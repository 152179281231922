const translations = {
  translation: {
    "makeItHappen": "MAKE IT HAPPEN",
    "requiredFields": "Please fill all required fields.",
    "validUrl": "Please enter a valid URL (e.g., https://example.com)",
    
    "login": {
      "title": "Already member?",
      "email": "YOUR EMAIL",
      "password": "YOUR PASSWORD",
      "welcome": "WELCOME",
      "error": "Invalid email or password.",
      "leftSection": {
        "mainTitle": "A new world requires a new model",
        "subtitle": "Thankward in three sentences:",
        "point1": "A universal and systematic wealth-creation model that simplifies the journey toward a successful life for deserving individuals.",
        "point2": "Values of recognition and trust that enable free and fair progress toward prosperity.",
        "point3": "A cutting-edge, intuitive tech platform that makes the process actionable, enjoyable, and highly powerful."
      }
    },
    "filterStatusCards": {
      "collabSpace": "Collab Space",
      "onGoing": {
        "subtitle": "Complete to earn your Thanks",
        "opportunities": "My ongoing actions",
        "callToAction": "ACHIEVE IT",
        "status": "ongoing"
      },
      "open": {
        "subtitle": "Seize new Thanks, Achieve more",
        "opportunities": "My work opportunities",
        "callToAction": "I WANT IT",
        "status": "open"
      },
      "inReview": {
        "subtitle": "Give Feedback, Earn new Thanks",
        "opportunities": "My feedback-to-provide",
        "callToAction": "MY FEEDBACK",
        "status": "in review"
      },
      "achieved": {
        "subtitle": "My achievements and gains",
        "opportunities": "My achievements",
        "callToAction": "COLLECT",
        "status": "achieved"
      }
    },
    // Ajout des nouvelles traductions pour le menu d'action
    actionMenu: {
      addMission: "Add a mission",
      shareIdea: "Share an idea",
      inviteTalent: "Invite a talent",
      createCollabSpace: "Create a collab space"
    },
    "newIdeaView": {
      "title": "Title\nof your idea",
      "chooseCollabSpace": "Choose a Collab Space",
      "addToProject": "Add to a project",
      "describeIdea": "Describe your idea",
      "whyImportant": "Why your idea is important?",
      "whenToLife": "When would you like\nto see the idea\ncome to life?",
      "provideBrief": "Provide a brief description",
      "shareIdea": "Share your idea",
      "saveForLater": "Save for later",
      "addResource": "Add a resource",
      "resourceLink": "Resource link",
      "fillFields": "Please fill all required fields.",
      "selectCollabSpace": "Please select a Collab Space"
    },
    "newAchievementsView": {
      "title": "Create a title for\nyour new misson",
      "chooseCollabSpace": "Choose a Collab Space",
      "addToProject": "Add to a project",
      "describeAchievement": "Describe your mission",
      "whyImportant": "What do you want to highlight\nabout this achievement and why\nis it important?",
      "whenToLife": "When do\nyou expect to complete?",
      "provideBrief": "Provide a brief description",
      "makeItHappen": "Make it happen",
      "saveForLater": "Save for later",
      "addResource": "Add a resource",
      "resourceLink": "Resource link",
      "fillFields": "Please fill all required fields.",
      "selectCollabSpace": "Please select a Collab Space",
      "teamValue": {
        "label": "In your teammate's opinion,\nhow would he value of this mission be perceived?",
        "minimal": "Minimal",
        "moderate": "Moderate",
        "significant": "Significant",
        "exceptional": "Exceptional",
        "descriptions": {
          "minimal": "Little to no perceived value",
          "moderate": "Some perceived value",
          "significant": "Noticeable value",
          "exceptional": "High perceived value"
        }
      },
      "complexity": {
        "label": "How would you rate\nthe complexity of\nthis achievement?",
        "simple": "Simple",
        "moderate": "Moderate",
        "complex": "Complex",
        "highlyComplex": "Highly Complex",
        "descriptions": {
          "simple": "Minimal skills needed",
          "moderate": "Some skill, creativity",
          "complex": "Advanced skills, creativity",
          "highlyComplex": "Expert skills, innovation"
        }
      },
      "frequency": {
        "label": "How would you rate\nthe novelty of this mission\nfor yourself?",
        "routine": "Routine",
        "occasional": "Occasionally repeated",
        "rare": "Rare",
        "novel": "Novel",
        "descriptions": {
          "routine": "Regular, minimal change",
          "occasional": "Sometimes, slight variations",
          "rare": "Seldom, notable elements",
          "novel": "First time, highest newness"
        }
      }
    },
    "cooptView": {
      "title": "FORM - COOPTATION",
      "subtitle": "Invite a Talent",
      "chooseCollabSpace": "Choose a\nCollab Space",
      "sponsoring": {
        "title": "Sponsoring",
        "subtitle": "for the creator of\na Collab Space"
      },
      "whoInvite": "Who do you want\nto invite?",
      "form": {
        "firstName": "FIRST NAME",
        "lastName": "LAST NAME",
        "email": "EMAIL",
        "message": "YOUR MESSAGE",
        "send": "SEND",
        "saveForLater": "SAVE FOR LATER"
      },
      "validation": {
        "required": "Please fill all required fields.",
        "validEmail": "Please enter a valid email address.",
        "selectCollabSpace": "Please select a valid Collab Space"
      }
    },
    "newTalentHub": {
      "title": "REGISTRATION",
      "subtitle": "Create your\nnew Collab Space",
      "form": {
        "nameDO": "ORGANIZATION NAME",
        "pitch": {
          "label": "Pitch\nyour Collab Space",
          "placeholder": "Provide a brief description"
        },
        "whatToDo": {
          "label": "What do you want\nto do",
          "placeholder": "Provide a brief description"
        },
        "whyImportant": {
          "label": "Why is it\nimportant?",
          "placeholder": "Provide a brief description"
        },
        "organizationType": "ORGANIZATION TYPE",
        "link": "LINK (optional)",
        "terms": "I accept the general terms and conditions of the Thankword\nmodel for organizations",
        "submit": "MAKE IT HAPPEN",
        "creating": "CREATING..."
      },
      "errors": {
        "creation": "An error occurred during creation",
        "timeout": "Request timed out. Please try again.",
        "network": "Network error, please try again"
      },
      "organizationType": {
        "title": "Choose the nature of your organization",
        "types": {
          "forProfit": "FOR PROFIT",
          "nonProfit": "NON PROFIT",
          "academic": "ACADEMIC",
          "lifestyle": "LIFESTYLE"
        }
      }
    },
    "feedback": {
      "title": "FORM - FEEDBACK",
      "subtitle": "Give your feedback",
      "form": {
        "brief": "Write\nyour feedback",
        "deliverables": "DELIVERABLES",
        "highlight": {
          "label": "What aspects of this achievement do you want to highlight,\nand why are they important?",
          "placeholder": "Provide a brief description"
        },
        "improvement": {
          "label": "What could you suggest to improve it next time?",
          "placeholder": "Provide a brief description"
        },
        "teamValue": {
          "label": "In your teammate's opinion,\nhow would he value\nof this mission be perceived?"
        },
        "quality": {
          "label": "How would you\nrate the quality of\nthis achievement?"
        },
        "reactivity": {
          "label": "How would you\nrate the achiever's\noverall reactivity?"
        },
        "submit": "PUBLISH"
      },
      "errors": {
        "submission": "An error occurred. Please try again.",
        "required": "Please fill all required fields."
      },
      "rating": {
        "low": "Minimal",
        "medium": "Moderate",
        "high": "Significant",
        "exceptional": "Exceptional"
      },
      "teamValue": {
        "descriptions": {
          "low": "Little to no perceived value",
          "medium": "Some perceived value",
          "high": "Noticeable value",
          "exceptional": "High perceived value"
        }
      },
      "quality": {
        "descriptions": {
          "low": "Needs improvement",
          "medium": "Minimum requirements met",
          "high": "All requirements met",
          "veryhigh": "Exceeds requirements precisely",
          "exceptional": "Exceeds requirements precisely"
        }
      },
      "reactivity": {
        "descriptions": {
          "low": "Needs improvement",
          "medium": "Basic needs met",
          "high": "Prompt and timely",
          "veryhigh": "Quick and adaptive",
          "exceptional": "Quick and adaptive"
        }
      },
      "ratingTeamValue": {
        "low": "Minimal",
        "medium": "Moderate",
        "high": "Significant",
        "exceptional": "Exceptional"
      },
      "ratingQuality": {
        "low": "Not Yet",
        "medium": "Basic",
        "high": "Good",
        "veryhigh": "High",
        "exceptional": "Outstanding"
      },
      "ratingReactivity": {
        "low": "Lacking",
        "medium": "Responsive",
        "high": "Proactive",
        "veryhigh": "Agile",
        "exceptional": "Exceptional"
      },
      "labels": {
        "alreadyFeedback": "You have already submitted your feedback"
      }
    },
    "ideaFeedback": {
      "form": {
        "feeling": {
          "label": "My feeling about this idea",
          "placeholder": "Write"
        },
        "contribution": {
          "label": "How can I contribute and when?",
          "optional": "(optional)",
          "placeholder": "Write"
        },
        "buttons": {
          "approve": "YES LET'S DO IT",
          "reject": "NO THANKS!"
        }
      },
      "labels": {
        "pitch": "PITCH",
        "alreadyFeedback": "You have already given your feedback on this idea."
      }
    },
    "ideaView": {
      "labels": {
        "pitch": "PITCH",
        "feedback": "FEED-BACK",
        "thankward": "Thankward by MKIF"
      },
      "feedback": {
        "contribution": "Contribution",
        "unknownFirstName": "Unknown first name",
        "unknownLastName": "Unknown last name"
      }
    },
    "editIdea": {
      "title": {
        "placeholder": "Title\nof your idea"
      },
      "form": {
        "describe": {
          "label": "Describe your idea",
          "placeholder": "Provide a brief description"
        },
        "importance": {
          "label": "Why your idea is important?",
          "placeholder": "Provide a brief description"
        },
        "calendar": {
          "question": "When would you like\nto see the idea\ncome to life?"
        },
        "resource": {
          "label": "Add a resource",
          "placeholder": "Resource link"
        },
        "buttons": {
          "share": "Share your idea",
          "save": "Save for later"
        }
      }
    },
    "opportunityView": {
      "labels": {
        "brief": "BRIEF",
      },
      "buttons": {
        "iWantIt": "I want it"
      },
      "errors": {
        "unknownFirstName": "Unknown first name",
        "unknownLastName": "Unknown last name"
      }
    },
    "draftView": {
      "labels": {
        "brief": "BRIEF"
      },
      "errors": {
        "unknownFirstName": "Unknown first name",
        "unknownLastName": "Unknown last name"
      }
    },
    "percentageViz": {
      "yes": "Yes",
      "no": "No"
    },
    "header": {
      "logout": "Logout"
    },
    "card": {
      "shareIdea": "SHARE IDEA",
      "myFeedback": "MY FEEDBACK",
      "soon": "No Thanks",
      "days": "D",
      "hours": "H",
      "minutes": "M",
      "seconds": "S"
    },
    "filterView": {
      "byCollabSpace": "By Collab Space",
      "byStatus": "By Status",
      "allCollabSpaces": "All my collab spaces",
      "onGoing": "On going",
      "open": "Open",
      "feedback": "Feedback",
      "achieved": "Achieved"
    }
  }
}

export default translations; 